 <template>
    <v-text-field
      class="rounded-lg"
      v-model="patrimonio"
      hide-details="auto"
      label="Valor do Patrimônio Financeiro"
      outlined
      :disabled="!editing"
      dense
    />
  </template>
  
  <script>
  import formatMixin from '@/mixins/formatMixin.js';
  
  export default {
    mixins: [formatMixin],
    props: {
      value: {
        type: [String, Number],
      },
      editing: Boolean
    },
    computed: {
      patrimonio: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
    watch: {
      "patrimonio"(newValue) {
        let valor = newValue.replace(/\D/g, "");
        valor = (valor / 100).toFixed(2).replace(".", ",");
        valor = "R$ " + valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        this.patrimonio = valor;
      },
    }
  };
  </script>
  