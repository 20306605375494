<template>
    <div class="mt-5" style="display: flex; justify-content: center;">
      <v-card elevation="5" :width="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '850' : '800') : ''"
        :height="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '380' : '350') : ''" class="pa-10 mt-3"
        style="display: grid; justify-content: center; align-items: center;" rounded>
        <span class="px-lg-16 text-h5 font-weight-medium primary--text text-center">Seu perfil de investidor é:
          <br><br><strong>{{ profile.name }}</strong></span>
        <span
          class="px-lg-16 text-lg-body-1 font-weight-medium primary--text text-center my-10">{{ profile.desc }}</span>
      </v-card>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      profile: {
        type: Object,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  </style>
  