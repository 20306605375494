<template>
    <div style="display: flex; justify-content: space-between;" class="py-3">
      <span class="primary--text text-md-h6">{{ label }}</span>
      <div class="d-flex align-center">
        <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="handleFileChange"
        />
        <FileDisplay :fileName="fileName" />
        <svg
          @click="triggerFileInput"
          style="width: 1.5rem; cursor: pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
          />
        </svg>
      </div>
    </div>
  </template>
  
  <script>
  import FileDisplay from "./FileDisplay.vue";
  
  export default {
    components: {
      FileDisplay
    },
    props: {
      label: String,
      fileName: String,
      fileType: String,
      onFileChange: Function
    },
    methods: {
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      handleFileChange(event) {
        this.onFileChange(event);
      }
    }
  };
  </script>
  