<template>
    <div>
      <div class="text-center mt-2">
        <span class="secondary--text mt-xl-1 text-caption text-uppercase font-weight-bold"
          style="font-size: 10px !important;">Valor total do empréstimo</span>
      </div>
      <v-col class="ml-1 mr-2 pa-0 grey lighten-3 rounded text-center">
        <span class="primary--text font-weight-light font-aleo " style="font-size: 16px; padding-top: 0;">
          {{ total }}
        </span>
      </v-col>
      <v-row class="pb-0 px-1 pt-3">
        <v-col cols="6" class="py-0 px-2">
          <div class="secondary--text text-caption text-uppercase font-weight-bold text-center mt-2"
            style="font-size: 9px !important;">
            Taxa de retorno
            <v-tooltip max-width="350" bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark size="15" v-bind="attrs" v-on="on">
                      mdi-information-outline
                  </v-icon>
              </template>
              <span>A Taxa de Juros acima é igual ao seu Retorno Mensal Bruto (antes do desconto de IR, custos de transferência e potencial inadimplência).</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="6" class="py-0 px-2">
          <div class="secondary--text text-caption text-uppercase font-weight-bold text-center mt-2"
            style="font-size: 9px !important;">
            Prazo de operação
          </div>
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col class="mr-1 ml-2 pa-0 grey lighten-3 rounded d-flex align-center text-center justify-center">
          <span class="primary--text font-weight-light font-aleo "
            style="font-size: 16px; padding-top: 0;">{{ nominal }} <br>
            <span class="texto2 primary--text font-weight-medium" style="padding: 0 !important;">NOMINAL
              MENSAL</span>
          </span>
        </v-col>
        <v-col class="ml-1 mr-2 pa-0 grey lighten-3 rounded d-flex align-center text-center justify-center">
          <span class="primary--text align-center font-weight-light font-aleo"
            style="font-size: 16px; padding-top: 0;">{{ negocio.prazo }} MESES</span>
        </v-col>
      </v-row>
      <div class="text-center mt-4">
        <span class="secondary--text mt-xl-1 text-caption text-uppercase font-weight-bold"
          style="font-size: 10px !important;">Investimento Mínimo</span>
      </div>
      <div class="grey lighten-3 rounded align-center text-center" style="padding-top: 0">
        <span class="primary--text font-weight-light font-aleo" style="font-size: 16px; padding-top: 0;">{{ticket }}</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      negocio: Object,
      total: String,
      nominal: String,
      ticket: String
    }
  };
  </script>
  