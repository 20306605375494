<template>
    <v-app>
        <v-main style="background: rgb(249 250 251); height: 100vh; padding: 0;">
            <v-container class="custom-container mt-8" style="border: 0px" :style="{
                display: currentScreenIndex === 0 ? 'grid' : 'block',
                justifyItems: currentScreenIndex === 0 ? 'center' : 'initial',
                alignItems: currentScreenIndex === 0 ? 'center' : 'initial'
            }" fluid>
                <v-app-bar fixed color="white" style="z-index: 99;">
                    <v-img max-width="150" src="@/assets/belat.png"></v-img>
                    <v-spacer></v-spacer>

                </v-app-bar>
                <v-app-bar fixed color="#D64D7A"
                style="height: 10px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;">
            </v-app-bar>
                <div class="d-flex flex-column items-center primary" style="width: 100%">
                    <div class="px-6 py-10 d-flex align-center"
                        style="border-bottom: 1px solid #14aa95; justify-content: center">
                        <div class="d-flex">
                            <div class="text-h5 font-weight-medium text-center white--text "
                                style="display: flex; align-items: center; font-family: 'Raleway'">
                                Dados pessoais
                            </div>
                        </div>
                    </div>
                </div>
                <v-row class="d-flex justify-center ">
                    <QuestionTimeLine :items="currentTimeline" :activeIndex="currentPosition"
                        :completedIndexes="completedIndexes" />
                </v-row>
                <template v-if="currentQuestionIndex == 4">
                    <h1 class="primary--text text-h5 font-weight-bold mt-10">DOCUMENTO DE IDENTIFICAÇÃO</h1>
                    <span class="primary--text">Clique para fazer o upload do seu documento de identificação</span>
                </template>
                <template v-if="currentQuestionIndex == 5">
                    <h1 class="primary--text text-h5 font-weight-bold mt-10">DOCUMENTO DE COMPROVAÇÃO DE RESIDÊNCIA</h1>
                    <span class="primary--text">Clique para fazer o upload do seu comprovante</span>
                </template>
                <div class="mt-10">
                    <v-col style="justify-content: center" :style="{

                width: currentScreenIndex === 0 ? '700px' : 'auto',
                display: currentQuestionIndex === 4 ? 'flex' : '',
                justifyItems: currentScreenIndex === 0 ? 'center' : 'initial',
                alignItems: currentScreenIndex === 0 ? 'center' : 'initial'
            }">
                        <div v-if="currentQuestionIndex == 0">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field :rules="rules" label="Nome completo" outlined dense
                                        v-model="user.nome"></v-text-field>
                                </v-col>

                                <v-col cols="3">
                                    <v-text-field :rules="rules" :error="cpfError" required
                                        @blur="titularUser.cnpj_cpf = formatarCPF(titularUser.cnpj_cpf)"
                                        @input="titularUser.cnpj_cpf = formatarCPF(titularUser.cnpj_cpf)" maxlength="14"
                                        label="CPF" outlined dense v-model="formattedValorCPF"></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field :error="dateError" :rules="rulesNascimento" type="date" required
                                        label="Data de nascimento" v-model="pessoaFisica.data_de_nascimento" outlined
                                        dense></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <v-text-field :error="identidadeError" :rules="rules"
                                        label="Documento de identificação (RG, CNH ou RNE)" required outlined dense
                                        v-model="pessoaFisica.identidade"></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field :error="emissaoError" :rules="rules" type="date" required
                                        label="Data de emissão documento" v-model="pessoaFisica.data_emissao_identidade"
                                        outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-select :error="expedidorError" :rules="rules" label="Órgão" color="secondary"
                                        v-model="pessoaFisica.orgao_expeditor"
                                        :items="getSelectItems({ text: 'Orgão Expedidor' })" item-text="both"
                                        item-value="codigo" outlined dense></v-select>
                                </v-col>
                                <v-col cols="2">
                                    <v-select :error="ufExError" :rules="rules" label="Uf" color="secondary"
                                        v-model="pessoaFisica.uf_orgao_expeditor"
                                        :items="getSelectItems({ text: 'UF' })" item-text="codigo" item-value="codigo"
                                        outlined dense></v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <v-select :error="sexoError" :rules="rules" label="Sexo" color="secondary"
                                        v-model="pessoaFisica.sexo_id" :items="getSelectItems({ text: 'Sexo' })"
                                        item-text="nome" item-value="id" outlined dense></v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-select :error="civilError" :rules="rules" label="Estado Civil" color="secondary"
                                        v-model="pessoaFisica.estado_civil_id"
                                        :items="getSelectItems({ text: 'Estado Civil' })" item-text="nome"
                                        item-value="id" outlined dense></v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-select :error="nacionalidadeError" :rules="rules" label="Nacionalidade"
                                        color="secondary" v-model="pessoaFisica.nacionalidade"
                                        :items="getSelectItems({ text: 'Nacionalidade' })" item-text="nome"
                                        item-value="id" outlined dense></v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <v-select :error="naturalidadeError" :rules="rules" label="Naturalidade"
                                        color="secondary" v-model="pessoaFisica.naturalidade"
                                        :items="getSelectItems({ text: 'Naturalidade' })" item-text="nome"
                                        item-value="id" outlined dense></v-select>
                                </v-col>
                                <v-col cols="8">
                                    <v-text-field :error="maeError" :rules="rules" label="Nome da mãe" required outlined
                                        dense v-model="pessoaFisica.nome_da_mae"></v-text-field>
                                </v-col>
                            </v-row>
                        </div>

                        <div v-if="currentQuestionIndex == 1">
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field :error="cargoError" :rules="touchedCargo ? rules : [true]" label="Cargo" outlined dense
                                        v-model="pessoaFisica.cargo" @blur="touchedCargo = true"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field :error="patrimonioError" :rules=" rules"
                                        label="Valor do Patrimônio Financeiro" @change="formatarSalario" outlined dense
                                        v-model="formattedValorPatrimonio" ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field :error="salarioError" :rules="touchedSalario ? rulesSalario : [true]" :error-messages="salarioErrorMessage" label="Renda Mensal"
                                         @change="onChangeSalario" outlined dense
                                        v-model="formattedSalario" @blur="touchedSalario = true"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field label="Email" outlined dense v-model="user.email"
                                        disabled></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field @input="user.phone_number = formatarPhone(user.phone_number)"
                                        label="Telefone" outlined dense v-model="user.phone_number"
                                        disabled></v-text-field>
                                </v-col>
                            </v-row>
                        </div>

                        <div v-if="currentQuestionIndex == 2">
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field :error="cepError" :rules="touchedCep ? rulesCep : [true]"
                                        @input="titularEndereco.cep = formatarCEP(titularEndereco.cep)"
                                        @blur="fetchCEPData(titularEndereco.cep); touchedCep = true;" label="CEP" outlined dense
                                        v-model="formattedValorCEP"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field :error="ruaError" :rules="touchedRua ? rules : [true]" label="Rua" outlined dense
                                        v-model="titularEndereco.logradouro" @blur="touchedRua = true"></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field :error="numeroError" :rules="touchedNumero ? rules: [true]" label="Número" outlined dense
                                        v-model="titularEndereco.numero"  @blur="touchedNumero = true"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field label="Complemento" outlined dense
                                        v-model="titularEndereco.complemento"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field :error="bairroError" :rules="rules" label="Bairro" outlined dense
                                        v-model="titularEndereco.bairro"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field :error="cidadeError" :rules="rules" label="Cidade" outlined dense
                                        v-model="titularEndereco.cidade"></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field :error="ufError" :rules="rules" label="Estado" outlined dense
                                        v-model="titularEndereco.uf"></v-text-field>
                                </v-col>
                            </v-row>
                        </div>

                        <div v-if="currentQuestionIndex == 3">
                            <v-row>
                                <v-col cols="4">
                                    <v-combobox :rules="rules" :error="numeroBancoError" label="Número do banco"
                                        color="secondary" v-model="titularUser.numero_do_banco"
                                        :items="getSelectItems({ text: 'Número do banco' })" item-text="displayText"
                                        item-value="codigo" outlined dense></v-combobox>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field :rules="rules" :error="agenciaError" v-mask="'####'" label="Agência"
                                        outlined dense v-model="titularUser.numero_da_agencia_bancaria"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field :rules="rules" maxlength="13" :error="numeroContaError"
                                        label="Número da conta" outlined dense
                                        v-model="titularUser.numero_da_conta_corrente_bancaria"
                                        @blur="formatNumeroConta"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-select :rules="rules" :error="tipoContaError" label="Tipo de Conta"
                                        color="secondary" v-model="titularUser.tipo_de_conta_bancaria_id"
                                        :items="getReversedSelectItems({ text: 'Tipo de conta' })" item-text="nome"
                                        item-value="id" outlined dense></v-select>
                                </v-col>
                            </v-row>
                        </div>


                        <div v-if="currentQuestionIndex == 4">
                            <v-row class="d-flex justify-center align-center">
                                <v-col cols="6">
                                        <v-card class="d-grid justify-center items-center" @click="openFileInput('RgFrente')" style="min-height: 160px; max-height: 160px; min-width: 314px;">
                                            <div class="d-flex justify-center mx-10">
                                                <v-img v-if="titularUser.anexo_rg" class="ma-5" max-width="50"  contain
                                                    :src="check"></v-img>
                                                <v-img v-else max-width="80" class="mt-10 mb-2" :src="image"></v-img>
                                            </div>
                                            <div class="px-lg-6 px-3">
                                                <!-- <h1 v-if="titularUser.anexo_rg"
                                                    class="primary--text text-body-1 font-weight-bold text-center pb-lg-5 px-lg-5">
                                                    {{ titularUser.anexo_rg?.anexo ? titularUser.anexo_rg?.anexo.nome :
                    titularUser?.anexo_rg?.nome }}
                                                </h1> -->
                                                <h1
                                                    class="primary--text text-body-1 font-weight-bold text-center pb-lg-5 px-lg-5">
                                                    {{ descRgFrente }}
                                                </h1>
                                            </div>
                                        </v-card>
                                    <input type="file" ref="fileInputRgFrente" style="display: none"
                                        @change="handleFileUpload($event, 'anexo_rg')"
                                        accept="application/pdf, image/*">
                                </v-col>
                                <v-col cols="6">
                                    <v-card class="d-grid justify-center" @click="openFileInput('RgTras')" style="min-height: 160px; max-height: 160px; min-width: 314px;">
                                        <div class="d-flex justify-center mx-10">
                                            <v-img v-if="titularUser.anexo_rg_tras" class="ma-5" max-width="40" contain
                                                :src="check"></v-img>
                                            <v-img v-else max-width="80" class="mt-10 mb-2" :src="imageVerso"></v-img>
                                        </div>
                                        <div class="px-lg-6 px-3">
                                            <!-- <h1 v-if="titularUser?.anexo_rg_tras"
                                                class="primary--text text-body-1 font-weight-bold text-center pb-lg-5 px-lg-5">
                                                {{ titularUser?.anexo_rg_tras?.anexo ?
                titularUser?.anexo_rg_tras?.anexo.nome :
                titularUser?.anexo_rg_tras?.nome }}
                                            </h1> -->
                                            <h1 
                                                class="primary--text text-body-1 font-weight-bold text-center pb-lg-5 px-lg-5">
                                                {{ descRgTras }}
                                            </h1>
                                        </div>
                                    </v-card>
                                    <input type="file" ref="fileInputRgTras" style="display: none"
                                        @change="handleFileUpload($event, 'anexo_rg_tras')"
                                        accept="application/pdf, image/*">
                                </v-col>
                            </v-row>
                        </div>

                        <div v-if="currentQuestionIndex == 5">
                            <v-row class="d-flex justify-center align-center">
                                <v-col cols="6">
                                    <v-card class="d-grid justify-center" @click="openFileInput(titularUser.anexo_comprovante_residencia)" style="min-height: 160px; min-width: 314px;">
                                        <div class="d-flex justify-center mx-10">
                                            <v-img v-if="titularUser.anexo_comprovante_residencia" class="ma-7"
                                                max-width="40" contain :src="check"></v-img>
                                            <v-img v-else max-width="80" class="mt-10 mb-2" contain :src="enderecoIcon"></v-img>
                                        </div>
                                        <div class="px-lg-6">
                                            <h1 
                                                class="primary--text text-body-1 font-weight-bold text-center pb-lg-5 px-lg-5">
                                                {{ descComprovante }}
                                            </h1>
                                        </div>
                                    </v-card>
                                    <input type="file" :ref="'fileInput' + titularUser.anexo_comprovante_residencia"
                                        style="display: none"
                                        @change="handleFileUpload($event, 'anexo_comprovante_residencia')"
                                        accept="application/pdf, image/*">
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </div>


                <v-col cols="12">
                    <div class="d-flex justify-center">
                        <v-btn class="mr-10 px-16 primary--text" style="border: 1px solid #00265b"
                            v-if="currentQuestionIndex == 0" rounded @click="backPage">Voltar</v-btn>
                        <v-btn class="mr-10 px-16 primary--text" style="border: 1px solid #00265b"
                            v-if="currentQuestionIndex > 0" rounded @click="--currentQuestionIndex">Voltar</v-btn>
                        <v-btn class="px-10" dark rounded color="primary" :disable="pass" @click="handleButtonClick">
                            {{ currentQuestionIndex < 5 ? 'Avançar' : 'Finalizar' }} </v-btn>
                    </div>
                </v-col>
            </v-container>
        </v-main>
    </v-app>
</template>


<script>
import QuestionTimeLine from "../../Components/QuestionTimeLine.vue"
import rgFrente from "@/assets/rgFrente.png"
import rgVerso from "@/assets/rgVerso.png"
import Check from "@/assets/check.png"
import Endereco from "@/assets/endereco_icon.png"
import axios from "axios"
export default {
    props: {
        currentTimeline: Array,
        currentPosition: Number,
    },
    components: {
        QuestionTimeLine
    },
    data() {
        return {
            pass: false,
            uploadedImages: [],
            rules: [
                (v) => !!v || 'Campo obrigatório',
            ],
            rulesCep: [
                (v) => !!v || 'Campo obrigatório'
            ],
            rulesSalario: [
                v => !!v || 'Renda Mensal é obrigatória',
                v => !this.validarSalario(v) || `A Renda Mensal deve ser maior que R$ ${this.salarioMinimo}`
            ],
            cpfError: false,
            dateError: false,
            dateErrorMessage: '',
            rulesNascimento: [
                v => !!v || 'Data de nascimento é obrigatória',
                v => this.validarIdade(v) || 'Investidor deve ter pelo menos 18 anos'
            ],
            identidadeError: false,
            emissaoError: false,
            expedidorError: false,
            ufExError: false,
            sexoError: false,
            civilError: false,
            nacionalidadeError: false,
            naturalidadeError: false,
            maeError: false,
            cargoError: false,
            patrimonioError: false,
            cepError: false,
            ruaError: false,
            numeroError: false,
            bairroError: false,
            cidadeError: false,
            ufError: false,
            salarioError: false,
            salarioErrorMessage: '',
            salarioMinimo: 1320, // Defina o valor mínimo do salário aqui
            numeroBancoError: false,
            agenciaError: false,
            numeroContaError: false,
            tipoContaError: false,
            touchedCargo: false,
            touchedSalario: false,
            touchedCep: false,
            touchedRua: false,
            touchedNumero: false,
            oi: null,
            descRgFrente: "Upload da frente do documento",
            descRgTras: "Upload do verso do documento (opcional)",
            descComprovante: "Upload do comprovante",
            check: Check,
            image: rgFrente,
            imageVerso: rgVerso,
            enderecoIcon: Endereco,
            pessoaFisica: {},
            activeIndex: 0,
            completedIndexes: [0, 1],
            userId: localStorage.getItem("userId"),
            titularUser: {},
            salario: null,
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            datePicker: {},
            currentQuestionIndex: 0,
            answers: {},
            currentScreenIndex: 0,
            currentStage: 0,
            answering: false,
            titularEndereco: {},
            showOptions: false,
            genderItens: [],
            estadoCivil: [],
            nacionalidade: [],
            naturalidade: [],
            banco: [],
            tipoContaBancaria: [],
            camposObrigatorios: [
                'user.nome',
                'titularUser.cnpj_cpf',
                'pessoaFisica.data_de_nascimento'
            ],
            uf: [],
            user: {
                nome: null,
                email: null,
                phone_number: null
            },
            expeditor: [],
            stages: [
                {
                    position: 1,
                    title: '',
                    text: '',

                    questions: [
                        [
                            {
                                id: 'data',
                                type: 'select',
                                required: true,
                                text: '',
                                options: []
                            },
                        ],
                    ],
                },
            ]
        }
    },
    computed: {
        formattedNumeroConta() {
            return this.formatarNumeroConta(this.titularUser.numero_da_conta_corrente_bancaria);
        },
        dataFormatada: {
            get() {
                return this.formatarData(this.pessoaFisica?.data_de_nascimento);
            },
            set(value) {
                this.pessoaFisica.data_de_nascimento = value;
            },
        },
        emissaoFormatada: {
            get() {
                return this.formatarData(this.pessoaFisica?.data_emissao_identidade);
            },
            set(value) {
                return this.pessoaFisica.data_emissao_identidade = value
            }
        },
        formattedValorCEP: {
            get() {
                return this.formatarCEP(this.titularEndereco?.cep);
            },
            set(value) {
                this.titularEndereco.cep = value.replace(/\D/g, '')
            }
        },
        formattedValorCPF: {
            get() {
                return this.formatarCPF(this.titularUser.cnpj_cpf);
            },
            set(value) {
                this.titularUser.cnpj_cpf = value.replace(/\D/g, '');
            }
        },
        formattedValorPatrimonio: {
            get() {
                return this.formatarSalario(this.titularUser.valor_do_patrimonio);
            },
            set(value) {
                // Remova a máscara e atualize o valor
                this.titularUser.valor_do_patrimonio = value.replace(/\D/g, '').replace(',', '.').replace(/^(\d+)(\d{2})$/, "$1.$2");
            }
        },
        formattedSalario: {
            get() {
                return this.formatarSalario(this.pessoaFisica.salario);
            },
            set(value) {
                // Remova a máscara e atualize o valor
                this.pessoaFisica.salario = value.replace(/\D/g, '').replace(',', '').replace('.', '').replace(/^(\d+)(\d{2})$/, "$1.$2");
                this.validarSalario();
            }
        }
    },
    mounted() {
        this.getEstadosCivis()
        this.getSexos();
        this.getOrgaoExpeditor();
        this.getUf();
        this.getPais();
        this.getBancos();
        this.getTiposConta();
        this.getUserData()
        this.getInvestidor()
    },
    watch: {
        'pessoaFisica.data_de_nascimento': function (newVal) {
            this.dateError = !this.validarIdade(newVal);
            },
        'user.phone_number': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                if (newVal) {
                    // Remove todos os caracteres não numéricos
                    const cleanedNumber = newVal.replace(/\D/g, '');
                    // Aplica a máscara correta
                    this.user.phone_number = cleanedNumber.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4');
                } else {
                    this.user.phone_number = '';
                }
            }
        },
    },
    methods: {
        validarSalario() {
            const salario = parseFloat(this.pessoaFisica.salario);
            if (salario <= this.salarioMinimo) {
                this.salarioError = true;
                this.salarioErrorMessage = `A Renda Mensal deve ser maior que ${this.salarioMinimo}`;
            } else {
                this.salarioError = false;
                this.salarioErrorMessage = '';
            }
        },
        onChangeSalario() {
        // Chame a validação sempre que o valor mudar
        this.validarSalario();
        },
        validarIdade(data) {
            if (!data) return false;

            const today = new Date();
            const birthDate = new Date(data);
            const age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
            const dayDifference = today.getDate() - birthDate.getDate();

            if (
                age > 18 ||
                (age === 18 && monthDifference > 0) ||
                (age === 18 && monthDifference === 0 && dayDifference >= 0)
            ) {
                return true;
            }

            this.dateError = true;
            this.dateErrorMessage = 'Investidor deve ter pelo menos 18 anos';
            return false;
            },
        getReversedSelectItems() {
            const items = this.getSelectItems({ text: 'Tipo de conta' }); // Supondo que você já tenha um método getSelectItems
            return items.reverse(); // Reverte a ordem dos itens
        },
        formatNumeroConta() {
            let numeroConta = this.titularUser.numero_da_conta_corrente_bancaria;
            while (numeroConta.length < 12) {
                numeroConta = "0" + numeroConta;
            }
            numeroConta = numeroConta.substring(0, numeroConta.length - 1) + "-" + numeroConta.charAt(numeroConta.length - 1);

            this.titularUser.numero_da_conta_corrente_bancaria = numeroConta;
        },
        getSelectItems(question) {
            const mappings = {
                'Sexo': { array: this.genderItens, idKey: 'id', nomeKey: 'nome' },
                'Estado Civil': { array: this.estadoCivil, idKey: 'id', nomeKey: 'nome' },
                'Orgão Expedidor': { array: this.expeditor, key: 'codigo', bothKey: 'both' },
                'UF': { array: this.uf, key: 'codigo', nomeKey: 'nome' },
                'Tipo de conta': { array: this.tipoContaBancaria, idKey: 'id', nomeKey: 'nome' },
                'Naturalidade': { array: this.naturalidade, key: 'nome', nomeKey: 'nome' },
                'Nacionalidade': { array: this.nacionalidade, key: 'nome', nomeKey: 'nome' },
                'Número do banco': { array: this.banco, key: 'codigo', nomeKey: 'nome' },
            };

            const mapping = mappings[question.text];
            if (mapping) {
                return mapping.array.map(item => ({
                    id: item[mapping.idKey],
                    codigo: item[mapping.key],
                    nome: item[mapping.nomeKey],
                    both: `${item.codigo}:${item.nome}`,
                    displayText: `${item.codigo} - ${item.nome}`
                }));
            } else {
                return [];
            }
        },
        formatarCEP(cep) {
            // Remove caracteres não numéricos
            cep = cep?.replace(/\D/g, "");

            // Verifica se a entrada é válida e formata apenas se tiver 8 caracteres
            if (cep?.length === 8) {
                // Aplica o formato XXXXX-XXX
                cep = cep?.replace(/^(\d{5})(\d{3})/, "$1-$2");
            }

            return cep;
        },
        formatarData(data) {
            if (/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/.test(data)) return data;
            if (!data || data.includes('undefined') || !data.includes('-')) return '';
            const [ano, mes, dia] = data.split('-');
            if (ano.length !== 4 || mes.length !== 2 || dia.length !== 2) return '';
            return `${dia.padStart(2, '0')}/${mes.padStart(2, '0')}/${ano}`;
        },
        addTitular() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            const cpfWithoutPunctuation = this.titularUser.cnpj_cpf.replace(/[^\d]/g, '') || '';
            const nascimento = this.formatarData(this.pessoaFisica.data_de_nascimento);
            const identidade = this.pessoaFisica?.identidade.replace(/[^\d]/g, '') || '';
            const dataEmissao = this.formatarData(this.pessoaFisica.data_emissao_identidade);
            const estadoCivil = this.pessoaFisica.estado_civil_id;
            const ufOrgao = this.pessoaFisica.uf_orgao_expeditor;
            const expeditorOrgao = this.pessoaFisica.orgao_expeditor;
            const gender = this.pessoaFisica.sexo_id;
            const mae = this.pessoaFisica.nome_da_mae || '';
            const nacionalidade = this.pessoaFisica.nacionalidade || '';
            const naturalidade = this.pessoaFisica.naturalidade || '';
            const cargoId = this.pessoaFisica.cargo || '';
            const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            let patrimonioQuestion = this.titularUser?.valor_do_patrimonio ?? null;
            if (/R\$/.test(this.titularUser?.valor_do_patrimonio))
                patrimonioQuestion = formatter.format(parseFloat(this.titularUser.valor_do_patrimonio?.replace(/[^\d,]/g, '').replace(',', '.'))).replaceAll(',', '')
            let salarioQuestion = this.pessoaFisica?.salario ?? null;
            if (/R\$/.test(this.pessoaFisica?.salario))
                salarioQuestion = formatter.format(parseFloat(this.pessoaFisica.salario?.replace(/[^\d,]/g, '').replace(',', '.'))).replaceAll(',', '');
            const telefoneQuestion = this.user.phone_number;
            const emailId = this.user.email;
            const cepWithoutPunctuation = this.titularEndereco?.cep?.replace(/[^\d]/g, '') || '';
            const cidadeId = this.titularEndereco?.cidade || '';
            const ufId = this.titularEndereco?.uf || '';
            const numberWithoutPunctuation = this.titularEndereco?.numero?.replace(/[^\d]/g, '') || '';
            const bairroId = this.titularEndereco?.bairro || '';
            const complemento = this.titularEndereco?.complemento || '';
            const logradouroId = this.titularEndereco?.logradouro || '';
            const bancoId = this.titularUser?.numero_do_banco?.codigo ? this.titularUser.numero_do_banco.codigo : (this.titularUser?.numero_do_banco?.includes(':') ? this.titularUser.numero_do_banco.split(':')[0] : '') || '';
            const agenciaId = this.titularUser?.numero_da_agencia_bancaria?.replace(/[^\d]/g, '') || '';
            const contaId = this.titularUser?.numero_da_conta_corrente_bancaria?.replace(/[^\d]/g, '') || '';
            const tipoContaId = this.titularUser.tipo_de_conta_bancaria_id;

            const data = {
                CPF: cpfWithoutPunctuation,
                DataDeNascimento: nascimento,
                Identidade: identidade,
                DataEmissaoIdentidade: dataEmissao,
                EstadoCivil: estadoCivil,
                Sexo: gender,
                NomeDaMae: mae,
                Nacionalidade: nacionalidade,
                Naturalidade: naturalidade,
                Cargo: cargoId,
                Complemento: complemento,
                ValorDoPatrimonio: patrimonioQuestion,
                Salario: salarioQuestion,
                TelefoneCelular: telefoneQuestion,
                Email: emailId,
                Cep: cepWithoutPunctuation,
                Cidade: cidadeId,
                UF: ufId,
                Numero: numberWithoutPunctuation,
                Bairro: bairroId,
                Logradouro: logradouroId,
                OrgaoExpedidor: expeditorOrgao,
                UFOrgaoExpedidor: ufOrgao,
                NumeroDoBanco: bancoId,
                NumeroDaAgenciaBancaria: agenciaId,
                NumeroDaContaCorrenteBancaria: contaId,
                TipoDeContaBancaria: tipoContaId,
            };

            if (this.currentQuestionIndex === 4) {
                data.anexo_rg = this.titularUser.anexo_rg.anexo ? this.titularUser.anexo_rg : { 'anexo': this.titularUser.anexo_rg };
                data.anexo_rg_tras = this.titularUser.anexo_rg_tras?.anexo ? this.titularUser.anexo_rg_tras : { 'anexo': this.titularUser.anexo_rg_tras };
            }

            if (this.currentQuestionIndex === 5) {
                data.anexo_comprovante_residencia = this.titularUser.anexo_comprovante_residencia?.anexo ? this.titularUser.anexo_comprovante_residencia : { 'anexo': this.titularUser.anexo_comprovante_residencia };
            }

            return axios
                .post(this.urlEnv + "updateInvestidor", data, config)
                .then(() => {
                })
                .catch((err) => {
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.mensagem
                    ) {
                        const errors = err.response.data.mensagem;
                        const messageError = Object.values(errors).join(", ");
                        this.$toast.error(messageError);
                    } else {
                        console.error(err);
                    }
                    throw err
                });
        },
        backPage() {
            this.$parent.goToPreviousScreen();
        },
        formatarPhone(valor) {
            // Remove todos os caracteres não numéricos
            valor = valor?.replace(/\D/g, "");

            // Formatação para telefone com código do país e DDD
            if (valor?.length === 11) {
                valor = valor?.replace(/^(\d{2})(\d{5})(\d{4})$/, "+$1 ($2) $3");
            } else if (valor?.length === 10) {
                valor = valor?.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
            } else if (valor?.length === 9) {
                valor = valor?.replace(/^(\d{5})(\d{4})$/, "$1-$2");
            } else if (valor?.length === 8) {
                valor = valor?.replace(/^(\d{4})(\d{4})$/, "$1-$2");
            }

            return valor;
        },
        formatarCPF(valor) {
            valor = valor?.replace(/\D/g, "");

            // Aplica a máscara do CPF (###.###.###-##)
            valor = valor?.replace(/(\d{3})(\d)/, "$1.$2");
            valor = valor?.replace(/(\d{3})(\d)/, "$1.$2");
            valor = valor?.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

            return valor;
        },

        formatarSalario(valor) {
            if (valor == null)
                return 'R$ ';
            valor = valor?.replace(/\D/g, "");
            valor = (parseFloat(valor) / 100).toFixed(2).replace(".", ",");
            valor = "R$ " + valor?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
            return valor;
        },
        validateCPF(cpf) {
            console.log(cpf)
            cpf = cpf?.replace(/\D/g, '');
            if (cpf?.length !== 11) {
                this.$toast.error('CPF deve ter 11 dígitos.');
                return false;
            }

            let sum = 0;
            let remainder;

            for (let i = 1; i <= 9; i++) {
                sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
            }

            remainder = (sum * 10) % 11;

            if ((remainder === 10) || (remainder === 11)) {
                remainder = 0;
            }

            if (remainder !== parseInt(cpf.substring(9, 10))) {
                this.$toast.error('Insira um CPF válido.');
                return false;
            }

            sum = 0;
            for (let i = 1; i <= 10; i++) {
                sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
            }

            remainder = (sum * 10) % 11;

            if ((remainder === 10) || (remainder === 11)) {
                remainder = 0;
            }

            if (remainder !== parseInt(cpf.substring(10, 11))) {
                this.$toast.error('Insira um CPF válido.');
                return false;
            }

            return true;
        },
        async getInvestidor() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                axios
                    .get(this.urlEnv + `getInvestidor`, config)
                    .then((response) => {
                        this.titularEndereco = response.data.data.investidor.endereco
                        if (this.titularEndereco == null)
                            this.titularEndereco = {};
                        this.titularUser = response.data.data.investidor;
                        this.titularUser?.anexo_rg
                            ? this.titularUser.anexo_rg = JSON.parse(this.titularUser.anexo_rg)
                            : null;
                        this.titularUser?.anexo_rg_tras
                            ? this.titularUser.anexo_rg_tras = JSON.parse(this.titularUser.anexo_rg_tras)
                            : null;
                        this.titularUser?.anexo_comprovante_residencia
                            ? this.titularUser.anexo_comprovante_residencia = JSON.parse(this.titularUser.anexo_comprovante_residencia)
                            : null;
                        if(this.titularUser?.numero_do_banco) this.titularUser.numero_do_banco = this.titularUser?.banco_formatado;
                        this.pessoaFisica = response.data.data.investidor.pessoa_fisica ?? { salario: null };

                        console.log(this.pessoaFisica, '1', this.titularUser, '2', this.titularEndereco, '3')
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } catch (err) {
                console.log(err);
            }
        },
        async getUserData() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            axios.get(this.urlEnv + "user", config).then(response => {
                // if(response.)
                console.log(response)
                let userData = response.data;
                this.user.email = userData.email;
                this.user.phone_number = userData.phone_number
                this.user.nome = userData.name
                //console.log(userData);
            }).catch(error => {
                console.error(error);
            })
        },
        async getEstadosCivis() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(
                    this.urlEnv + "getEstadosCivis",
                    config
                );
                this.estadoCivil = response.data.data.estados_civis;
            } catch (error) {
                console.error(error);
            }
        },
        async getOrgaoExpeditor() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(
                    this.urlEnv + "getOrgaoExpeditor",
                    config
                );
                this.expeditor = response.data.data.orgao_expeditor;
            } catch (error) {
                console.error(error);
            }
        },
        async getUf() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(
                    this.urlEnv + "getUf",
                    config
                );
                this.uf = response.data.data.uf;
            } catch (error) {
                console.error(error);
            }
        },
        async getSexos() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(this.urlEnv + "getSexos", config);
                this.genderItens = response.data.data.sexos;
            } catch (error) {
                console.error(error);
            }
        },
        async getPais() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(this.urlEnv + "getPais", config);
                this.nacionalidade = response.data.data.paises;
                this.naturalidade = response.data.data.paises;
                //console.log(this.nacionalidade, this.naturalidade);
            } catch (error) {
                console.error(error);
            }
        },
        async getBancos() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(this.urlEnv + "getBanco", config);
                this.banco = response.data.data.banco;
                // console.log(this.banco);
            } catch (error) {
                console.error(error);
            }
        },
        async getTiposConta() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(this.urlEnv + "getTipoConta", config);
                this.tipoContaBancaria = response.data.data.tipos_de_conta;
                //console.log(this.tipoContaBancaria);
            } catch (error) {
                console.error(error);
            }
        },
        handleFileUpload(event, questionKey) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                if (file.type.startsWith('image/')) {
                    const img = new Image();
                    img.src = e.target.result;

                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        const maxWidth = 800;
                        const maxHeight = 600;
                        let width = img.width;
                        let height = img.height;
                        if (width > height) {
                            if (width > maxWidth) {
                                height *= maxWidth / width;
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;

                        ctx.drawImage(img, 0, 0, width, height);

                        const dataURL = canvas.toDataURL('image/jpeg', 0.7);

                        const imageExists = this.uploadedImages.some(image => image.content === dataURL);
                        if (imageExists) {
                            this.$toast.error('Esta imagem já foi adicionada anteriormente.');
                            return;
                        }

                        const imageObject = {
                            nome: file.name,
                            content: dataURL
                        };

                        this.uploadedImages.push(imageObject);
                        this.titularUser[questionKey] = imageObject;
                    };
                } else if (file.type === 'application/pdf') {
                    const pdfObject = {
                        nome: file.name,
                        content: e.target.result
                    };
                    this.titularUser[questionKey] = pdfObject;
                    console.log('PDF detected. Compressing PDF...');
                } else {
                    console.log('Tipo de arquivo não suportado');
                }
            };

            reader.readAsDataURL(file);
        },



        async getInfo() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                axios
                    .get(this.urlEnv + `getMeusDados`, config)
                    .then((response) => {
                        this.titularUser = response.data.data.dados_pessoais;
                        // console.log(this.titularUser);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } catch (err) {
                console.log(err);
            }
        },
        openFileInput(index) {
            this.$refs['fileInput' + index].click(); // Abre o input file correspondente ao card clicado
        },
        getCamposObrigatoriosFaltando() {
            return this.camposObrigatorios.filter(campo => {
                const valorCampo = campo.split('.').reduce((obj, key) => obj[key], this);
                return !valorCampo;
            });
        },
        validateDate() {
            this.dateError = !this.pessoaFisica.data_de_nascimento;
        },
        handleButtonClick() {
            let isValid = true;
            if (this.currentQuestionIndex === 0) {
                if (!this.titularUser.cnpj_cpf || !this.validateCPF(this.titularUser.cnpj_cpf)) {
                    this.cpfError = true;
                    isValid = false;
                } else {
                    this.cpfError = false;
                }
                if (!this.pessoaFisica.data_de_nascimento) {
                    this.dateError = true;
                    isValid = false;
                } else {
                    this.dateError = false;
                }
                if (!this.pessoaFisica.identidade) {
                    this.identidadeError = true;
                    isValid = false;
                } else {
                    this.identidadeError = false;
                }
                if (!this.pessoaFisica.data_emissao_identidade) {
                    this.emissaoError = true;
                    isValid = false;
                } else {
                    this.emissaoError = false;
                }
                if (!this.pessoaFisica.orgao_expeditor) {
                    this.expedidorError = true;
                    isValid = false;
                } else {
                    this.expedidorError = false;
                }
                if (!this.pessoaFisica.uf_orgao_expeditor) {
                    this.ufExError = true;
                    isValid = false;
                } else {
                    this.ufExError = false;
                }
                if (!this.pessoaFisica.sexo_id) {
                    this.sexoError = true;
                    isValid = false;
                } else {
                    this.sexoError = false;
                }
                if (!this.pessoaFisica.estado_civil_id) {
                    this.civilError = true;
                    isValid = false;
                } else {
                    this.civilError = false;
                }
                if (!this.pessoaFisica.nacionalidade) {
                    this.nacionalidadeError = true;
                    isValid = false;
                } else {
                    this.nacionalidadeError = false;
                }
                if (!this.pessoaFisica.naturalidade) {
                    this.naturalidadeError = true;
                    isValid = false;
                } else {
                    this.naturalidadeError = false;
                }
                if (!this.pessoaFisica.nome_da_mae) {
                    this.maeError = true;
                    isValid = false;
                } else {
                    this.maeError = false;
                }
            }

            if (this.currentQuestionIndex === 1) {
                let salario = this.pessoaFisica.salario
                if (/R\$/.test(this.pessoaFisica.salario))
                    salario = parseFloat(this.pessoaFisica.salario?.replace(/[^\d,]/g, '').replace(',', '.'));
                const patrimonio = this.titularUser.valor_do_patrimonio
                const cargo = this.pessoaFisica.cargo
                if (!cargo) {
                    this.cargoError = true;
                    this.touchedCargo = true
                    isValid = false;
                } else {
                    this.cargoError = false
                }
                if (!patrimonio) {
                    this.patrimonioError = true
                    isValid = false;
                } else {
                    this.patrimonioError = false
                }
                if (!salario || parseFloat(salario) < 1320) {
                    this.salarioError = true
                    this.touchedSalario = true;
                    isValid = false;
                } else {
                    this.salarioError = false
                }
            }

            if (this.currentQuestionIndex === 2) {
                const cep = this.titularUser.cnpj_cpf;
                const rua = this.titularEndereco?.logradouro
                const numero = this.titularEndereco?.numero
                const bairro = this.titularEndereco?.bairro
                const cidade = this.titularEndereco?.cidade
                const estado = this.titularEndereco?.uf

                if (!cep) {
                    this.cepError = true
                    this.touchedCep = true;
                    isValid = false;
                } else {
                    this.cepError = false
                }
                if (!rua) {
                    this.touchedRua = true;
                    this.ruaError = true
                    isValid = false;
                } else {
                    this.ruaError = false
                }
                if (!numero) {
                    this.numeroError = true
                    this.touchedNumero = true;
                    isValid = false;
                } else {
                    this.numeroError = false
                }
                if (!bairro) {
                    this.bairroError = true
                    isValid = false;
                } else {
                    this.bairroError = false
                }
                if (!cidade) {
                    this.cidadeError = true
                    isValid = false;
                } else {
                    this.cidadeError = false
                }
                if (!estado) {
                    this.ufError = true
                    isValid = false;
                } else {
                    this.ufError = false
                }
            }

            if (this.currentQuestionIndex === 3) {
                const banco = this.titularUser.numero_do_banco;
                const agencia = this.titularUser.numero_da_agencia_bancaria
                const conta = this.titularUser.numero_da_conta_corrente_bancaria
                const tipoConta = this.titularUser.tipo_de_conta_bancaria_id

                if (!banco) {
                    this.numeroBancoError = true
                    isValid = false;
                } else {
                    this.numeroBancoError = false
                }
                if (!agencia) {
                    this.agenciaError = true
                    isValid = false;
                } else {
                    this.agenciaError = false
                }
                if (!conta) {
                    this.numeroContaError = true
                    isValid = false;
                } else {
                    this.numeroContaError = false
                }
                if (!tipoConta) {
                    this.tipoContaError = true
                    isValid = false;
                } else {
                    this.tipoContaError = false
                }
            }

            if (this.currentQuestionIndex === 4) {
                const rgFrente = this.titularUser.anexo_rg
                if (!rgFrente) {
                    this.$toast.error('Envie o documento frontal');
                    isValid = false;
                }
            }

            if (this.currentQuestionIndex === 5) {
                const comprovanteResidencia = this.titularUser.anexo_comprovante_residencia
                if (!comprovanteResidencia) {
                    this.$toast.error('Envie o documento de comprovante de residência');
                    isValid = false;
                }
            }
            if (isValid == true) {
                if (this.currentQuestionIndex === 0 || this.currentQuestionIndex === 1 || this.currentQuestionIndex === 2 || this.currentQuestionIndex === 3) {
                    this.addTitular().then(() => {
                        this.currentQuestionIndex++;
                    }).catch((error) => {
                        // this.currentQuestionIndex = 0
                        console.log(error);
                    });
                } else if (this.currentQuestionIndex === 4) {
                    if (this.titularUser.anexo_rg) {
                        this.addTitular().then(() => {
                            this.currentQuestionIndex++;
                        }).catch((error) => {
                            // this.currentQuestionIndex = 0
                            console.log(error);
                        });
                    } else {
                        console.log("Por favor, envie os anexos antes de prosseguir.");
                    }
                } else if (this.currentQuestionIndex === 5) {
                    if (this.titularUser.anexo_comprovante_residencia) {
                        this.finishStage()
                    }
                }
            } else {
                console.log("Por favor, corrija os erros antes de prosseguir.");
            }
        },

        finishStage() {
            this.pass = true
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            const data = {
                questions: this.stages[this.currentStage].questions,
                dataComplete: true
            };
            console.log(data)
            try {
                // axios
                //     .post(this.urlEnv + "saveQuestions", data, config)
                //     .then((response) => {
                //         console.log(response.data);
                //         this.$emit('incrementPosition')
                //         this.$parent.goToNextScreen();
                //     })
                //     .catch((error) => {
                //         this.$toast.error(error);
                //     });
                axios
                    .post(this.urlEnv + "saveQuestions", data, config)
                    .then(() => {
                        this.addTitular().then(() => {
                            this.$emit('incrementPosition');
                            this.$parent.goToNextScreen();
                        }).catch((error) => {
                            this.pass = false
                            this.$toast.error(error);
                        });
                    })
                    .catch((error) => {
                        this.pass = false
                        console.log(error)
                    });
            } catch (error) {
                console.log(error);
            }
        },

        getColor(position) {
            return position <= this.currentPosition ? 'secondary' : 'gray';
        },
        async fetchCEPData(cep) {
            if(!cep || cep.length < 8) return;
            const response = await axios.get(
                `https://viacep.com.br/ws/${cep}/json/`
            );
            const data = response.data;

            if (!data.erro) {
                console.log(cep)
                this.titularEndereco.logradouro = data.logradouro;
                this.titularEndereco.bairro = data.bairro;
                this.titularEndereco.cidade = data.localidade;
                this.titularEndereco.uf = data.uf;
                this.$forceUpdate();
            } else {
                console.error('CEP not found');
            }
        },
    }

}
</script>

<style scoped>
.row {
    margin: -20px !important
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.primary--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

.custom-container {
    /* max-width: 1440px; */
    margin: 0 auto;
    border: 1px solid #fff;
    padding: 20px 0;
}

.stage-indicator {
    display: inline-block;
    width: 100px;
    /* Ajuste conforme necessário */
    padding: 5px;
    margin: 0 10px;
    /* Espaçamento entre as etapas */
    border: 1px solid #ccc;
    border-radius: 5px;
}

.v-timeline::before {
    transform: rotate(90deg) !important;
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
    left: 0 !important
}
</style>