<template>
    <v-btn @click="getBusinessDetail" style="width: 100%" :color="hover ? 'secondary' : 'primary'">
        faça uma simulação
    </v-btn>
</template>

<script>
export default {
    props: {
        businessId: String
    },
    methods: {
        getBusinessDetail() {
            this.$emit('getBusinessDetail', this.businessId);
        }
    }
};
</script>
