<template>
    <v-col col="12" md="12" style="padding:0;">
        <v-card>
            <div class="d-md-flex" :style="{ height: $vuetify.breakpoint.height >= 900 ? '55vh' : '45vh' }">
                <v-img :max-width="$vuetify.breakpoint.mdAndUp ? '50%' : 'none'" src="@/assets/imageComplete.png">
                    <div style="color: #032a5a;" class="pa-12">
                        <h1 class="text-h4">{{ $t('hello') }}, {{ user.name }}</h1>
                        <p class="text-h6">{{ $t('welcome') }} {{ $t('to') }} Banca ética</p>
                    </div>
                </v-img>
                <div style="background: #032a5a; width: 100%;">
                    <div class="d-flex justify-center">
                        <v-card-text class="white--text d-flex justify-center pt-lg-8 mx-xl-4 mt-xl-5 align-center text-subtitle-1 text-lg-h5 text-xl-h4 font-weight-bold">
                            {{ $t('text_1_home') }}
                        </v-card-text>
                    </div>
                    <div class="d-flex justify-center">
                        <v-card-text class="textHome white--text mx-xl-2 text-xl-h5 text-caption d-flex pl-lg-6 pl-xl-8 pt-3" :class="[$vuetify.breakpoint.xl ? 'font-weight-semibold' : 'font-weight-semibold']">
                            {{ $t('text_2_home') }}
                        </v-card-text>
                    </div>
                    <div class="d-flex justify-start">
                        <v-card-text class="pl-lg-6 ml-xl-2 pt-lg-4 pt-xl-8 text-xl-body-1" style="color: #14aa95">
                            {{ $t('text_3_home') }}
                        </v-card-text>
                    </div>
                </div>
            </div>
        </v-card>
    </v-col>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}
</style>
