export default {
    methods: {
      formatPrazo(prazo) {
        return `${prazo} meses`;
      },
      formatDate(date) {
        if (date) {
          const [year, month, day] = date.split("-");
          return `${day}/${month}/${year}`;
        }
        return "";
      },
      formatCurrency(value) {
        const formatter = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
        return formatter.format(value);
      },
      formatPercentage(value) {
        const formatter = new Intl.NumberFormat("pt-BR", {
          style: "percent",
          minimumFractionDigits: 2,
        });
        const formattedValue = formatter.format(value / 100);
        return formattedValue + " a.m";
      },
      unformatCurrency(value) {
        if (!value) return 0;
        return Number(value.replace(/[^\d,-]/g, '').replace(',', '.'));
      },
      formatCEP(cep) {
        cep = cep?.replace(/\D/g, "");
        if (cep?.length === 8) {
            cep = cep?.replace(/^(\d{5})(\d{3})/, "$1-$2");
        }
        return cep;
      },
      formatData(data) {
        if (/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/.test(data)) return data;
        if (!data || data.includes('undefined') || !data.includes('-')) return '';
        const [ano, mes, dia] = data.split('-');
        if (ano.length !== 4 || mes.length !== 2 || dia.length !== 2) return '';
        return `${dia.padStart(2, '0')}/${mes.padStart(2, '0')}/${ano}`;
      },
      formatAccountNumber() {
        let accountNumber = this.titularUser.numero_da_conta_corrente_bancaria;
        while (accountNumber.length < 13) {
            accountNumber = "0" + accountNumber;
        }
        accountNumber = accountNumber.substring(0, accountNumber.length - 1) + "-" + accountNumber.charAt(accountNumber.length - 1);

        this.titularUser.numero_da_conta_corrente_bancaria = accountNumber;
      },
      formatPhone(value) {
        value = value?.replace(/\D/g, "");

        if (value?.length === 11) {
            value = value?.replace(/^(\d{2})(\d{5})(\d{4})$/, "+$1 ($2) $3");
        } else if (value?.length === 10) {
            value = value?.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
        } else if (value?.length === 9) {
            value = value?.replace(/^(\d{5})(\d{4})$/, "$1-$2");
        } else if (value?.length === 8) {
            value = value?.replace(/^(\d{4})(\d{4})$/, "$1-$2");
        }

        return value;
      },
      formatCPF(value) {
        value = value?.replace(/\D/g, "");

        value = value?.replace(/(\d{3})(\d)/, "$1.$2");
        value = value?.replace(/(\d{3})(\d)/, "$1.$2");
        value = value?.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

        return value;
      },
      formatSalary(value) {
        if (value == null)
            return 'R$ ';
        value = value?.replace(/\D/g, "");
        value = (parseFloat(value) / 100).toFixed(2).replace(".", ",");
        value = "R$ " + value?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        return value;
      },
    },
  };
  