<template>
  <div class="d-flex flex-column align-center">
    <v-row style="width: 80%">
      <v-col class="pt-lg-10 pt-5" cols="12">
        <FileUploader
          label="Documento de identificação frente"
          :fileName="identificationFileName"
          :fileType="'identification'"
          @onFileChange="onFileChange"
        />
        <CustomDivider />
        <FileUploader
          label="Documento de identificação verso"
          :fileName="identificationBackFileName"
          :fileType="'identificationBack'"
          @onFileChange="onFileChange"
        />
        <CustomDivider />
        <FileUploader
          label="Comprovante de residência"
          :fileName="residenceFileName"
          :fileType="'residence'"
          @onFileChange="onFileChange"
        />
        <CustomDivider />
      </v-col>
    </v-row>
    <v-col cols="12" class="d-flex justify-center">
      <v-btn
        class="transition-fast-in-fast-out mr-2"
        color="primary"
        @click="uploadFile"
        rounded
        :disabled="!identificationFileName && !residenceFileName && !identificationBackFileName"
      >
        Confirmar envio
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";
import FileUploader from "../Components/FileUploader.vue";
import CustomDivider from "../Components/CustomDivider.vue";

export default {
  components: {
    FileUploader,
    CustomDivider
  },
  data() {
    return {
      userId: localStorage.getItem("userId"),
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      identificationFileName: null,
      residenceFileName: null,
      identificationBackFileName: null,
    };
  },
  methods: {
    uploadFile() {
      const formData = new FormData();
      formData.set('rg', this.$refs['identificationFileInput'].files[0]);
      formData.set('rg_tras', this.$refs['identificationBackFileInput'].files[0]);
      formData.set('comprovante', this.$refs['residenceFileInput'].files[0]);

      axios.post(`${this.urlEnv}uploadDocs`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) => {
        this.identificationFileName = null;
        this.residenceFileName = null;
        this.identificationBackFileName = null;
        if (response.data.code == 'WARNING') {
          this.$toast.warning(response.data.mensagem);
        } else {
          this.$toast.success(response.data.mensagem);
        }
      })
      .catch((error) => {
        this.$toast.error(error.data.mensagem);
        console.log(error);
      });
    },
    onFileChange(event) {
      const fileType = event.target.dataset.fileType;
      const file = event.target.files[0];
      if (file.type.startsWith('image/') || file.type === 'application/pdf') {
        if (file.type === 'application/pdf') {
          this.$refs[`${fileType}FileInput`].files = event.target.files;
        } else {
          const reader = new FileReader();

          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              const maxWidth = 800;
              const maxHeight = 600;
              let width = img.width;
              let height = img.height;
              if (width > height) {
                if (width > maxWidth) {
                  height *= maxWidth / width;
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width *= maxHeight / height;
                  height = maxHeight;
                }
              }
              canvas.width = width;
              canvas.height = height;

              ctx.drawImage(img, 0, 0, width, height);

              const dataURL = canvas.toDataURL('image/jpeg', 0.7);

              const compressedFile = this.dataURLToFile(dataURL, file.name);

              const fileList = new DataTransfer();
              fileList.items.add(compressedFile);

              this.$refs[`${fileType}FileInput`].files = fileList.files;
            };
          };
          reader.readAsDataURL(file);
        }
        this[`${fileType}FileName`] = file.name;
      } else {
        this.$toast.error('Por favor, selecione uma imagem ou um PDF.');
        event.target.value = '';
        this[`${fileType}FileName`] = null;
      }
    },
    dataURLToFile(dataURL, filename) {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
  }
};
</script>

<style>
</style>
