import apiService from "@/services/apiService";
import axios from 'axios';

export default {
    data() {
        return {
            tipoContaBancaria: [],
            bancos: [],
            genderItens: [{ codigo: null, nome: null }],
            estadoCivil: [{ codigo: null, nome: null }],
        };
    },
    methods: {
        async getBancos(prop_name='bancos') {
            try {
                const response = await apiService.getBancos();
                this[prop_name] = response.data.data.banco;
            } catch (error) {
                console.error(error);
            }
        },
        async getTiposConta() {
            try {
                const response = await apiService.getTiposConta();
                this.tipoContaBancaria = response.data.data.tipos_de_conta;
            } catch (error) {
                console.error(error);
            }
        },
        async getEstadosCivis() {
            try {
                const response = await apiService.getEstadosCivis();
                this.estadoCivil = response.data.data.estados_civis;
            } catch (error) {
                console.error(error);
            }
        },
        async getSexos() {
            try {
                const response = await apiService.getSexos();
                this.genderItens = response.data.data.sexos;
            } catch (error) {
                console.error(error);
            }
        },
        async getOrgaoExpeditor() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(
                    this.urlEnv + "getOrgaoExpeditor",
                    config
                );
                this.expeditor = response.data.data.orgao_expeditor;
            } catch (error) {
                console.error(error);
            }
        },
        async getUf() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(
                    this.urlEnv + "getUf",
                    config
                );
                this.uf = response.data.data.uf;
            } catch (error) {
                console.error(error);
            }
        },
        async getPais() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(this.urlEnv + "getPais", config);
                this.nacionalidade = response.data.data.paises;
                this.naturalidade = response.data.data.paises;
                //console.log(this.nacionalidade, this.naturalidade);
            } catch (error) {
                console.error(error);
            }
        },
    }
};
