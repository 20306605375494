<template>
    <div>
        <layout>
            <template v-slot:content>
                <GreetingSection :user="user" />
                <InvestmentSummary 
                    :totalInvestido="total_investido"
                    :totalReceber="total_receber"
                />
            </template>
        </layout>
    </div>
</template>

<script>
import layout from "@/layouts/internalLayout.vue";
import GreetingSection from "./Components/GreetingSection.vue";
import InvestmentSummary from "./Components/InvestmentSummary.vue";
import userMixin from "@/mixins/userMixin";
import investorMixin from "@/mixins/investorMixin";

export default {
    metaInfo() {
        return {
            title: 'BELat - Home'
        }
    },
    components: {
        layout,
        GreetingSection,
        InvestmentSummary
    },
    mixins: [userMixin, investorMixin],
    data() {
        return {
            total_investido: 0,
            total_receber: 0,
                  token: localStorage.getItem("token"),
        };
    },
    methods: {
    },
    async created() {
        await this.getInvestorInfo();
    }
};
</script>

<style scoped>
.v-navigation-drawer__border {
    display: none;
}
</style>
