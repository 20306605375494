export default {
    methods: {
      validateCPF(cpf) {
        const cpfNumbers = this.removeNonNumeric(cpf);
        if (cpfNumbers.length !== 11) {
          this.$toast.error('CPF deve ter 11 dígitos.');
          return false;
        }
  
        const cpfArray = cpfNumbers.split("").map(Number);
        const [digit1, digit2] = cpfArray.slice(9);
  
        const sum = (arr) => arr.reduce((acc, curr) => acc + curr, 0);
        const mod = (n, mod) => (n % mod < 2 ? 0 : mod - (n % mod));
  
        const checkDigit = (arr, factor) =>
          mod(sum(arr.map((num, idx) => num * (factor - idx))), 11);
  
        if (checkDigit(cpfArray.slice(0, 9), 10) !== digit1) {
          this.$toast.error('Insira um CPF válido.');
          return false;
        }
  
        if (checkDigit(cpfArray.slice(0, 10), 11) !== digit2) {
          this.$toast.error('Insira um CPF válido.');
          return false;
        }
  
        return true;
      },
  
      removeNonNumeric(value) {
        return value.replace(/\D/g, "");
      },
    },
  };
  