<template>
    <v-row class="mt-2 px-3">
      <v-col v-for="(image, index) in images" :key="index" cols="6" class="py-1 px-2">
        <v-img :src="getPhoto(image)" height="40"></v-img>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    props: {
      images: Array,
    },
    methods: {
      getPhoto(image) {
        return `data:image/png;base64,${image}`;
      }
    },
  };
  </script>
  