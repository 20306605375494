<template>
  <div>
    <v-btn icon @click="returnSignUp">
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.97601 8L8.76012 14.6328C8.91371 14.783 9 14.9867 9 15.1991C9 15.4115 8.91371 15.6152 8.76012 15.7654C8.60653 15.9156 8.39822 16 8.18101 16C7.9638 16 7.75549 15.9156 7.6019 15.7654L0.240305 8.56633C0.164132 8.49202 0.103697 8.40375 0.0624612 8.30657C0.0212257 8.20939 0 8.10521 0 8C0 7.89479 0.0212257 7.79061 0.0624612 7.69343C0.103697 7.59625 0.164132 7.50798 0.240305 7.43367L7.6019 0.234581C7.75549 0.0843811 7.9638 0 8.18101 0C8.39822 0 8.60653 0.0843811 8.76012 0.234581C8.91371 0.38478 9 0.588495 9 0.800909C9 1.01332 8.91371 1.21704 8.76012 1.36724L1.97601 8Z"
          fill="#032A5A" />
      </svg>
    </v-btn>
    <span class="pl-1 font-weight-bold text-caption" @click="returnSignUp">VOLTAR</span>
  </div>
</template>
  
  <script>
  export default {
    props: {
      returnSignUp: {
        type: Function,
        required: true
      }
    }
  }
  </script>
  