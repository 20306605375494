<template>
    <v-expansion-panel class="pb-1">
      <v-expansion-panel-header class="text-left font-weight-bold">
        <span class="text-left font-weight-medium primary--text">Impacto Positivo</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="mx-0 pb-2">
          <v-col cols="12" class="no-horizontal-align">
            <div>
              <span class="primary--text caption">
                {{ negocio?.impacto_socioambiental?.length > 0 ? negocio?.impacto_socioambiental : 'Sem impacto socioambiental declarado' }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </template>
  
  <script>
  export default {
    props: {
      negocio: Object,
    },
  };
  </script>
  