<template>
  <div style="width: 100%">
    <v-container style="border: 0px;" fluid>
      <div class="d-flex">
        <v-row>
          <v-col cols="12">
            <h2 style="font-size: 20px;" class="mb-2 primary--text font-weight-medium">{{ question.text }}</h2>
            <div class="d-flex mt-3">
              <toggle-button 
                :id="id" 
                v-model="localShowOptions" 
                color="#14AA95"
                :labels="{ checked: 'Sim', unchecked: 'Não' }" 
                :sync="true" 
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    question: Object,
    showOptions2: Boolean,
  },
  data() {
    return {
      localShowOptions: this.showOptions2,
    };
  },
  watch: {
    showOptions2(newVal) {
      this.localShowOptions = newVal;
    },
    localShowOptions(newVal) {
      this.$emit('update:showOptions2', newVal);
    },
  },
};
</script>
