<template>
    <div>
      <component :is="currentComponent" />
    </div>
  </template>
  
  <script>
  export default {
    props: {
      section: {
        type: String,
        required: true
      }
    },
    computed: {
      currentComponent() {
        const components = {
          'dados-pessoais': () => import('../Pages/PersonalInfo.vue'),
          'informacoes-adicionais': () => import('../Pages/JobPage.vue'),
          'endereco': () => import('../Pages/AddressPage.vue'),
          'dados-bancarios': () => import('../Pages/AccountDetail.vue'),
          'meus-documentos': () => import('../Pages/MyDocs.vue'),
        };
        return components[this.section] || 'div';
      }
    }
  };
  </script>
  