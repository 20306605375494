<template>
    <layout :click-busi="false">
        <template v-slot:content>
            <v-container style="padding: 10px; border: 0px" class="mt-2 custom-container" fluid>
                <h3 class="pa-1 ml-5 mb-5 text-h5 primary--text">Invista com consciência.</h3>
                <v-row class="d-flex justify-end" style="margin-bottom: 0.3rem">
                    <v-col cols="11" class="pa-1">
                        <vueSelect :businesses="businesses" :areasDeInvestimento="areasDeInvestimento"
                            @filtrarBusinesses="filtrarBusinesses" />
                    </v-col>
                </v-row>
                <BusinessList :businesses="filteredBusinesses" @getBusinessDetail="getBusinessDetail" />
                <v-row class="mt-5">
                    <v-col cols="12" class="text-center">
                        <BusinessDetailButton
                            :businessId="selectedBusinessId"
                            @getBusinessDetail="getBusinessDetail" />
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </layout>
</template>

<script>
import vueSelect from "./Components/SelectVueMobile.vue"
import BusinessList from "./Components/BusinessList.vue";
import BusinessDetailButton from "./Components/BusinessDetailButton.vue";
import layout from "@/layouts/internalLayoutMobile.vue";
import businessMixin from "@/mixins/businessMixin";
import investorMixin from "@/mixins/investorMixin";
import userMixin from "@/mixins/userMixin";
import { getPhoto, numberToReal, numberToPercentage } from "@/js/belatUtils";

export default {
    metaInfo() {
        return {
            title: 'BELat - Negócios'
        }
    },
    components: {
        layout,
        vueSelect,
        BusinessList,
        BusinessDetailButton
    },
    mixins: [businessMixin, investorMixin, userMixin],
    data() {
        return {
            negocio: {},
            titularUser: {},
            dialog: false,
            businessOptions: [],
            selectedBusiness: null,
            drawer: false,
            group: null,
            businessId: localStorage.getItem("negocio"),
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            areasDeInvestimento: [],
            businesses: [],
            filteredBusinesses: [],
            userId: localStorage.getItem("userId"),
            items: [
                {
                    title: "Meus Investimentos",
                    icon: "mdi-home-city",
                    route: "/user/MyInvestiments",
                },
                { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
            ],
        };
    },
    methods: {
        close() {
            this.dialog = false;
        },
        finish() {
            this.$router.push({
                name: "ListView",
            });
        },
    },
    computed: {
        getPhoto() {
            return getPhoto;
        },
        numberToReal() {
            return numberToReal;
        },
        numberToPercentage() {
            return numberToPercentage;
        },
    },
    mounted() {
        this.getInvestidor();
        this.getListagem();
        this.getUserData();
    },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Aleo&display=swap');

.font-aleo {
    font-family: 'Aleo', sans-serif !important;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.secondary--text,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

@media (min-width: 2560px) {}

@media (min-width: 1920px) and (max-width: 2559px) {
    .texto {
        font-size: 0.61rem !important;
    }

    .texto2 {
        font-size: 0.59rem !important;
    }

    .porcentagem {
        font-size: 1.1rem !important
    }

    .mensal {
        font-size: 0.815rem !important
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .v-card__text {
        padding: 7px !important;
    }

    .texto {
        font-size: 0.60rem !important;
    }

    .texto2 {
        font-size: 0.59rem !important;
    }

    .porcentagem {
        font-size: 1.1rem !important
    }

    .mensal {
        font-size: 0.800rem !important
    }
}

@media (max-width: 1279px) {}


.v-input__slot {
    margin: 0 !important
}

.v-card__actions {
    display: none !important;
}

.VueCarousel-navigation-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #14AA95 !important;
    left: 2.3rem !important;
    top: 1rem !important;
}

.VueCarousel-navigation-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #14AA95 !important;
    right: 2.3rem !important;
    top: 1rem !important;
}

.VueCarousel-navigation-button {
    color: white !important;
}
</style>