<template>
  <v-main style="background: #00265b;  overflow-x:hidden">
                <div class="white" style="width: 100%;">
                  <div class="d-flex flex-column items-center">
                    <div class="px-6 py-3 d-flex align-center" style="justify-content: center">
                      <div class="d-flex">
                        <div class="text-h5 font-weight-medium text-center primary--text"
                          style="display: flex; align-items: center; font-family: 'Raleway'">
                          {{ $t('investment_profile') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-row class="d-flex justify-center">
                    <div class="">
                      <svg width="230" height="4" viewBox="0 0 230 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="230" height="4" rx="2" fill="#D9D9D9" />
                        <path d="M0 2.08696C0 0.934362 0.934362 0 2.08696 0H23V4H1.91304C0.8565 4 0 3.1435 0 2.08696Z"
                          fill="#14AA95" />
                        <path d="M46 0H69V4H46V0Z" fill="#14AA95" />
                        <path d="M23 0H46V4H23V0Z" fill="#14AA95" />
                        <path d="M69 0H92V4H69V0Z" fill="#14AA95" />
                        <path d="M92 0H115V4H92V0Z" fill="#14AA95" />
                        <path d="M115 0H138V4H115V0Z" fill="#14AA95" />
                        <path d="M138 0H161V4H138V0Z" fill="#14AA95" />
                        <path d="M161 0H184V4H161V0Z" fill="#14AA95" />
                        <path d="M184 0H207V4H184V0Z" fill="#14AA95" />
                      </svg>
              
                      <!-- <QuestionTimeLine :items="currentTimeline" :activeIndex="currentPosition"
                          :completedIndexes="completedIndexes" /> -->
                    </div>
                  </v-row>
                </div>
    <div class="adjustScreen mt-8 mt-xl-12 mt-lg-8">
      <v-col v-for="question, questionId in stages[currentStage].questions[currentQuestionIndex]" :key="questionId"
        :style="{
              padding: currentScreenIndex === 0 ? '2px' : '4px',
              width: currentScreenIndex === 0 ? '' : 'auto',
              display: currentScreenIndex === 0 ? 'grid' : 'block',
              justifyItems: currentScreenIndex === 0 ? 'center' : 'initial',
              alignItems: currentScreenIndex === 0 ? 'center' : 'initial'
            }">

        <h3 v-if="question.extra_title" style="color: #14AA95">{{ question.extra_title }}</h3>

        <template v-if="question.type == 'radio'">
          <div class="mx-5" style="display: flex; justify-content: center;">

            <div style="display: grid; justify-content: center; align-items: center;">
              <!-- <p class="px-xl-24 px-lg-16 text-center text-h7 secondary--text text-h7 font-weight-thin"
                      style="margin-bottom: 0 !important">{{ currentQuestionIndex + 1 +
        '/' + stages[currentStage].questions.length }}</p> -->
              <div>
                <h2 class="pb-5 px-xl-20 px-lg-10 text-h6 font-weight-bold " style="color:#7FD2C9;line-height: 24.65px;">{{ question.text }}
                </h2>


                <v-radio-group class="px-lg-16" v-model="question.answer" style="margin-top: 0 !important;">
                  <v-radio dark color="secondary" :id="question.id" @click="handleButtonClick"
                    v-for="option in question.options" :key="option.value" :label="option.text" :value="option.value"
                    class="white-label" style="min-height: 60px;">
                  </v-radio>
                </v-radio-group>
              </div>

            </div>
          </div>
        </template>

      </v-col>

    </div>
    <div class="footer">
      <v-col cols="12" class="pt-5">
        <div class="d-flex justify-center">
          <v-btn color="primary" class="mr-5 px-10 white--text" elevation="0"
            style="border: 1px solid #14AA95 !important; background: #00265b !important"
            v-if="currentQuestionIndex == 0" rounded @click="backPage">Voltar</v-btn>

          <v-btn color="primary" class="mr-5 px-10 white--text" style="border: 1px solid #14AA95 !important"
            v-if="currentQuestionIndex > 0" rounded @click="currentQuestionIndex--">{{ $t('back') }}</v-btn>
          <v-btn class="px-12" dark rounded color="secondary" @click="handleButtonClick">
            {{ currentQuestionIndex < stages[currentScreenIndex].questions.length - 1 ? $t('advance') : $t('finish') }}
              </v-btn>

        </div>
      </v-col>
    </div>

  </v-main>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    currentTimeline: Array,
    currentPosition: Number,
  },
  data() {
    return {
      activeIndex: 0,
      currentQuestionIndex: 0,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      answers: {},
      currentScreenIndex: 0,
      currentStage: 0,
      answering: false,
      showOptions: false,
      stages: [
        {
          position: 1,
          title: 'Perfil de investimento',
          text: 'Saiba que tipo de investidor você é.',
          // landing: {
          //   title: 'Ative sua conta para investir!',
          //   text: 'Faremos algumas perguntas sobre suas metas e finanças para descobrir que tipo de investidor você é e seu nível de risco.',
          // },
          questions: [
            [
              {
                required: true,
                id: 'finalidadeInvestimento',
                type: 'radio',
                text: this.$t('question1Invest'),
                options: [
                  {
                    text: this.$t('option1question1Invest'),
                    value: 'option1question1Invest',
                    points: 0
                  },
                  {
                    text: this.$t('option2question1Invest'),
                    value: 'option2question1Invest',
                    points: 5

                  },
                  {
                    text: this.$t('option3question1Invest'),
                    value: 'option3question1Invest',
                    points: 10
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'tempoAplicado',
                type: 'radio',
                text: this.$t('question2Invest'),
                options: [
                  {
                    text: this.$t('option1question2Invest'),
                    value: 'option1question2Invest',
                    points: 0
                  },
                  {
                    text: this.$t('option2question2Invest'),
                    value: 'option2question2Invest',
                    points: 5
                  },
                  {
                    text: this.$t('option3question2Invest'),
                    value: 'option3question2Invest',
                    points: 10
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'investimentoConhecimento',
                type: 'radio',
                text: this.$t('question3Invest'),
                options: [
                  {
                    text: this.$t('option1question3Invest'),
                    value: 'option1question3Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option2question3Invest'),
                    value: 'option2question3Invest',
                    points: 5
                  },
                  {
                    text: this.$t('option3question3Invest'),
                    value: 'option3question3Invest',
                    points: 9
                  },
                  {
                    text: this.$t('option4question3Invest'),
                    value: 'option4question3Invest',
                    points: 10
                  },
                  {
                    text: this.$t('option5question3Invest'),
                    value: 'option5question3Invest',
                    points: 0
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'valoresMobiliarios',
                type: 'radio',
                text: this.$t('question4Invest'),
                options: [
                  {
                    text: this.$t('option1question4Invest'),
                    value: 'option1question4Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option2question4Invest'),
                    value: 'option2question4Invest',
                    points: 5
                  },
                  {
                    text: this.$t('option3question4Invest'),
                    value: 'option3question4Invest',
                    points: 9
                  },
                  {
                    text: this.$t('option4question4Invest'),
                    value: 'option4question4Invest',
                    points: 10
                  },
                  {
                    text: this.$t('option5question4Invest'),
                    value: 'option5question4Invest',
                    points: 0
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'frequencia',
                type: 'radio',
                text: this.$t('question5Invest'),
                options: [
                  {
                    text: this.$t('option1question5Invest'),
                    value: 'option1question5Invest',
                    points: 10
                  },
                  {
                    text: this.$t('option2question5Invest'),
                    value: 'option2question5Invest',
                    points: 5
                  },
                  {
                    text: this.$t('option3question5Invest'),
                    value: 'option3question5Invest',
                    points: 3
                  },
                  {
                    text: this.$t('option4question5Invest'),
                    value: 'option4question5Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option5question5Invest'),
                    value: 'option5question5Invest',
                    points: 0
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'mercadoFinanceiro',
                type: 'radio',
                text: this.$t('question6Invest'),
                options: [
                  {
                    text: this.$t('option1question6Invest'),
                    value: 'option1question6Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option2question6Invest'),
                    value: 'option2question6Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option3question6Invest'),
                    value: 'option3question6Invest',
                    points: 4
                  },
                  {
                    text: this.$t('option4question6Invest'),
                    value: 'option4question6Invest',
                    points: 5
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'mensalDeclarada',
                type: 'radio',
                text: this.$t('question7Invest'),
                options: [
                  {
                    text: this.$t('option1question7Invest'),
                    value: 'option1question7Invest',
                    points: 0
                  },
                  {
                    text: this.$t('option2question7Invest'),
                    value: 'option2question7Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option3question7Invest'),
                    value: 'option3question7Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option4question7Invest'),
                    value: 'option4question7Invest',
                    points: 4
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'bensPatrimonio',
                type: 'radio',
                text: 'Qual o valor dos ativos (bens materiais, imóveis e aplicações financeiras) que compõem seu patrimônio? ',
                options: [
                  {
                    text: this.$t('option1question8Invest'),
                    value: 'option1question8Invest',
                    points: 0
                  },
                  {
                    text: this.$t('option2question8Invest'),
                    value: 'option2question8Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option3question8Invest'),
                    value: 'option3question8Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option4question8Invest'),
                    value: 'option4question8Invest',
                    points: 4
                  },
                  {
                    text: this.$t('option5question8Invest'),
                    value: 'option5question8Invest',
                    points: 5
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'relacaoInvestimento',
                type: 'radio',
                text: this.$t('question9Invest'),
                options: [
                  {
                    text: this.$t('option1question9Invest'),
                    value: 'option1question9Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option2question9Invest'),
                    value: 'option2question9Invest',
                    points: 3
                  },
                  {
                    text: this.$t('option3question9Invest'),
                    value: 'option3question9Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option4question9Invest'),
                    value: 'option4question9Invest',
                    points: 0
                  },
                  {
                    text: this.$t('option5question9Invest'),
                    value: 'option5question9Invest',
                    points: 5
                  },
                ]
              },
            ],
            [
              {
                id: 'volumeInvestido',
                type: 'radio',
                required: true,
                text: this.$t('question10Invest'),
                options: [
                  {
                    text: this.$t('option1question10Invest'),
                    value: 'option1question10Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option2question10Invest'),
                    value: 'option2question10Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option3question10Invest'),
                    value: 'option3question10Invest',
                    points: 3
                  },
                  {
                    text: this.$t('option4question10Invest'),
                    value: 'option4question10Invest',
                    points: 5
                  },
                  {
                    text: this.$t('option5question10Invest'),
                    value: 'option5question10Invest',
                    points: 0
                  },
                ]
              },
            ],
          ],
        },
      ]
    }
  },
  mounted() {
    this.getMeusDados()
    console.log(this.currentScreenIndex)
  },
  methods: {
    backPage() {
      this.$parent.goToPreviousScreen();
    },
    checkAnswers() {
      const questions = this.stages[this.currentStage].questions[this.currentQuestionIndex];
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        if (question.required && !question.answer) {
          return false; // Retorna falso se uma pergunta obrigatória não foi respondida
        }
      }
      return true; // Retorna verdadeiro se todas as perguntas obrigatórias foram respondidas
    },
    async getMeusDados() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        axios
          .get(this.urlEnv + `getQuestions`, config)
          .then((response) => {

            this.stages[this.currentStage].questions.forEach(questionGroup => {
              // Loop through each question object in the group
              questionGroup.forEach(question => {
                // Get the answer from the first array using the question's ID
                const answer = response.data.data.questions[question.id]?.answer;
                // If answer exists in the first array, add it to the question object in the second array
                if (answer) {
                  question.answer = answer;
                  this.$nextTick(() => {
                    this.$forceUpdate();
                  });
                }
              });
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    handleButtonClick() {
      if (!this.checkAnswers()) {
        this.$toast.error('Por favor, preencha todos os campos para continuar.');
        return;
      } else {
        if (this.currentQuestionIndex < this.stages[this.currentScreenIndex].questions.length - 1) {
          setTimeout(() => {
            this.currentQuestionIndex++;
          }, 350);
        } else {
          this.finishStage();
        }
      }
    },
    finishStage() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      const data = {
        questions: this.stages[this.currentStage].questions,
        investComplete: true,
      };
      try {
        axios
          .post(this.urlEnv + "saveQuestions", data, config)
          .then((response) => {
            console.log(response.data);
            // this.$emit('incrementPosition')
            this.$parent.goToNextScreen();
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    getColor(position) {
      return position <= this.currentPosition ? 'secondary' : 'gray'; // Retorna 'secondary' se a posição for menor ou igual a currentScreenIndex
    },
  },

}
</script>

<style scoped>
.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.primary--text,
.secondary--text,
.text-center,
.d-flex {
  font-family: 'Raleway', cursive !important;
}

.custom-container {
  /* max-width: 1440px; */
  margin: 0 auto;
  border: 1px solid #fff;
  padding: 20px 0;
}

.stage-indicator {
  display: inline-block;
  width: 100px;
  /* Ajuste conforme necessário */
  padding: 5px;
  margin: 0 10px;
  /* Espaçamento entre as etapas */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.v-timeline::before {
  transform: rotate(90deg) !important;
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
  left: 0 !important
}

.footer {
  background-color: #00265b;
}
</style>

<style>
.adjustScreen {
  height: 85vh;
}


.white-label .v-label {
  color: white !important;
}


.white-label .v-icon {
  color: rgb(141, 133, 133);
  border-radius: 50%;
  background-image: radial-gradient(white 50%, transparent 50%);
}

.white-label {
  border-bottom: 1px solid #0B376D;
}

.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  margin-bottom: 10px;
}
/* .custom-radio .v-input--selection-controls__input .v-input--selection-controls__ripple::before {
  background-color: white;
  background: white !important
  /* z-index: ; */

/* .custom-radio .v-input--selection-controls__input .v-input--selection-controls__ripple {
  border-color: white;
} */


</style>