<template>
    <div>
        <v-main style="background: #00265b; overflow-x:hidden">
                  <div class="white" style="width: 100%">
                      <div class="d-flex flex-column items-center">
                          <div class="px-6 py-3 d-flex align-center"
                              style="justify-content: center">
                              <div class="d-flex">
                                  <div class="text-h5 font-weight-medium text-center primary--text "
                                      style="display: flex; align-items: center; font-family: 'Raleway'">
                                      Perfil de Impacto
                                  </div>
                              </div>
                          </div>
                      </div>
                      <v-row class="d-flex justify-center">
                    <div class="">
                      <svg width="230" height="4" viewBox="0 0 230 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="230" height="4" rx="2" fill="#D9D9D9" />
                        <path d="M0 2.08696C0 0.934362 0.934362 0 2.08696 0H23V4H1.91304C0.8565 4 0 3.1435 0 2.08696Z"
                          fill="#14AA95" />
                        <path d="M46 0H69V4H46V0Z" fill="#14AA95" />
                        <path d="M23 0H46V4H23V0Z" fill="#14AA95" />
                        <path d="M69 0H92V4H69V0Z" fill="#14AA95" />
                        <path d="M92 0H115V4H92V0Z" fill="#14AA95" />
                        <path d="M115 0H138V4H115V0Z" fill="#14AA95" />
                        <path d="M138 0H161V4H138V0Z" fill="#14AA95" />
                        <path d="M161 0H184V4H161V0Z" fill="#14AA95" />
                        <path d="M184 0H207V4H184V0Z" fill="#14AA95" />
                      </svg>
              
                      <!-- <QuestionTimeLine :items="currentTimeline" :activeIndex="currentPosition"
                          :completedIndexes="completedIndexes" /> -->
                    </div>
                  </v-row>
                  </div>
                <div class="adjustScreen mt-5 mt-xl-12 mt-lg-5 ">
                    <v-col v-for="question, questionId in stages[currentStage].questions[currentScreenIndex]"
                        :key="questionId" :style="{
                padding: currentScreenIndex === 0 ? '4px' : 'auto',
                width: currentScreenIndex === 0 ? '700px' : 'auto',
                display: currentScreenIndex === 0 ? 'grid' : 'block',
                justifyItems: currentScreenIndex === 0 ? 'center' : 'initial',
                alignItems: currentScreenIndex === 0 ? 'center' : 'initial'
            }">
    
                        <h3 class="mt-xl-10 mt-lg-0 white--text" v-if="question.extra_title">{{ question.extra_title }}</h3>
    
                        <template v-if="question.type == 'select'">
                            <div class="mx-3">
                                <h2 style="font-size: 19px;" class="mb-2 font-weight-bold white--text font-weight-medium text-xl-h6 text-lg-h6">{{ question.text }}</h2>
                                <v-col style="padding: 2px" cols="12">
                                    <v-select placeholder="Opções" class="changeColor" :id="question.id" multiple color="white" v-model="question.answer"
                                        :items="question.options.map(option => ({ text: option.text, value: option.value }))"
                                        outlined dense required>
                                        <template v-slot:prepend-item>
                                    <v-list-item ripple @click="selectAllQuestions(question)">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Selecionar todos
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:selection="{ item, index }">
                                    <!-- {{ item.text }} -->
                                    <span v-if="index < 1">
                                        <span>{{ item.text }} </span>
                                    </span>
                                    <span v-if="index === 1" class="text-grey text-caption align-self-center">
                                        (+{{ question.answer.length - 1 }} outros)
                                    </span>
                                </template>
                                    </v-select>
                                </v-col>
                            </div>
                        </template>


                        <template v-if="question.type == 'conditional'">
                            <div style="width: 100%">
    
                                <v-container style="border: 0px;" class="" fluid>
                                    <div class="d-flex">
                                        <v-row>
                                            <v-col cols="12">
                                                <h2 style="font-size: 19px;" class="mb-2 font-weight-bold white--text text-xl-h6 text-lg-h6 font-weight-medium">{{
                question.text }}</h2>
                                                <div class="d-flex mt-3">
                                                    <toggle-button :id="question.id" v-model="showOptions"
                                                        :value="showOptions" color="#14AA95"
                                                        :labels="{ checked: 'Sim', unchecked: 'Não' }" :sync="true" />
                                                    </div>
                                            </v-col>
                                        </v-row>
    
                                    </div>
                                    <v-row>
                                    </v-row>
                                </v-container>
                            </div>
                        </template>
                        <!-- <template v-if="question.type == 'conditional'">
                            <div style="width: 100%">
    
                                <v-container style="border: 0px;" class="" fluid>
                                    <div class="d-flex">
                                        <v-row>
                                            <v-col cols="12">
                                                <h2 class="white--text text-xl-h6 text-lg-h6 font-weight-medium">{{
                question.text }}</h2>
                                                <div class="d-flex mt-3">
                                                    <toggle-button :id="question.id" v-model="showOptions"
                                                        :value="showOptions" color="#14AA95"
                                                        :labels="{ checked: 'Sim', unchecked: 'Não' }" :sync="true" />
                                                    </div>
                                                    <div class="mt-5">
                                                        <v-col style="padding: 2px" cols="12">
                                                            <v-select dark v-if="showOptions" class="ml-lg-5" color="secondary" dense
                                                                placeholder="Opções" outlined
                                                                v-model="question.answer"
                                                                :items="question.options.map(option => ({ text: option.text, value: option.value }))"></v-select>
                                                        </v-col>
                                                    </div>
                                            </v-col>
                                        </v-row>
    
                                    </div>
                                    <v-row>
                                    </v-row>
                                </v-container>
                            </div>
                        </template> -->
    
                    </v-col>
                </div>
                <div class="footer">
                    <v-col cols="12" class="mt-5 mt-xl-12 mt-lg-5 ">
                        <div class="d-flex justify-center">
                            <v-btn class="mr-3 px-12 white--text" color="primary" style="border: 1px solid #14AA95 !important"
                                v-if="currentQuestionIndex == 0" rounded @click="backPage">Voltar</v-btn>
                            <v-btn class="mr-3 px-12 white--text" style="border: 1px solid #00265b"
                                v-if="currentScreenIndex > 0" rounded @click="currentScreenIndex--">Voltar</v-btn>
                            <v-btn class="px-10" dark rounded color="secondary"
                                @click="currentScreenIndex < stages[currentScreenIndex].questions.length - 1 ? currentScreenIndex++ : finishStage()">
                                {{ currentScreenIndex < stages[currentScreenIndex].questions.length - 1 ? 'Avançar'
                                    : 'Continuar' }} </v-btn>
                        </div>
                    </v-col>
                </div>
        </v-main>
    </div>
</template>

<script>
// import QuestionTimeLine from "../Components/QuestionTimeLine.vue"
import axios from 'axios';
export default {
    props: {
        currentTimeline: Array,
    },
    components: {
        // QuestionTimeLine
    },
    data() {
        return {
            activeIndex: 0,
            completedIndexes: [],
            currentQuestionIndex: 0,
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            answers: {},
            currentScreenIndex: 0,
            currentStage: 0,
            answering: false,
            showOptions: false,
            stages: [
                {
                    position: 1,
                    title: 'Perfil de impacto',
                    text: 'Saiba que tipo de investidor você é.',
                    // landing: {
                    //   title: 'Ative sua conta para investir!',
                    //   text: 'Faremos algumas perguntas sobre suas metas e finanças para descobrir que tipo de investidor você é e seu nível de risco.',
                    // },
                    questions: [
                        [
                            {
                                id: 'ods',
                                type: 'select',
                                required: true,
                                text: this.$t('question2Impact'),
                                options: [
                                    {
                                        value: 'option1question2Impact',
                                        text: this.$t('option1question2Impact'),
                                    },
                                    {
                                        value: 'option2question2Impact',
                                        text: this.$t('option2question2Impact'),
                                    },
                                    {
                                        value: 'option3question2Impact',
                                        text: this.$t('option3question2Impact'),
                                    },
                                    {
                                        value: 'option4question2Impact',
                                        text: this.$t('option4question2Impact'),
                                    },
                                    {
                                        value: 'option5question2Impact',
                                        text: this.$t('option5question2Impact'),
                                    },
                                    {
                                        value: 'option6question2Impact',
                                        text: this.$t('option6question2Impact'),
                                    },
                                    {
                                        value: 'option7question2Impact',
                                        text: this.$t('option7question2Impact'),
                                    },
                                    {
                                        value: 'option8question2Impact',
                                        text: this.$t('option8question2Impact')
                                    },
                                    {
                                        value: 'option9question2Impact',
                                        text: this.$t('option9question2Impact'),
                                    },
                                    {
                                        value: 'option10question2Impact',
                                        text: this.$t('option10question2Impact'),
                                    },
                                    {
                                        value: 'option11question2Impact',
                                        text: this.$t('option11question2Impact'),
                                    },
                                    {
                                        value: 'option12question2Impact',
                                        text: this.$t('option12question2Impact'),
                                    },
                                    {
                                        value: 'option13question2Impact',
                                        text: this.$t('option13question2Impact'),
                                    },
                                    {
                                        value: 'option14question2Impact',
                                        text: this.$t('option14question2Impact'),
                                    },
                                    {
                                        value: 'option15question2Impact',
                                        text: this.$t('option15question2Impact'),
                                    },
                                    {
                                        value: 'option16question2Impact',
                                        text: this.$t('option16question2Impact'),
                                    },
                                    {
                                        value: 'option17question2Impact',
                                        text: this.$t('option17question2Impact'),
                                    },
                                    {
                                        value: 'option18question2Impact',
                                        text: this.$t('option18question2Impact')
                                    }
                                ]
                            },
                            {
                                id: 'financiar',
                                type: 'conditional',
                                text: this.$t('question4Impact'),
                                // options: [
                                //     {
                                //         value: 'option1question3Impact',
                                //         text: this.$t('option1question3Impact'),
                                //     },
                                //     {
                                //         value: 'option2question3Impact',
                                //         text: this.$t('option2question3Impact'),
                                //     },
                                //     {
                                //         value: 'option3question3Impact',
                                //         text: this.$t('option3question3Impact'),
                                //     },
                                //     {
                                //         value: 'option4question3Impact',
                                //         text: this.$t('option4question3Impact'),
                                //     },
                                //     {
                                //         value: 'option5question3Impact',
                                //         text: this.$t('option5question3Impact'),
                                //     },
                                //     {
                                //         value: 'option6question3Impact',
                                //         text: this.$t('option6question3Impact'),
                                //     },
                                //     {
                                //         value: 'option7question3Impact',
                                //         text: this.$t('option7question3Impact'),
                                //     },
                                //     {
                                //         value: 'option8question3Impact',
                                //         text: this.$t('option8question3Impact'),
                                //     },
                                // ]
                            },
                        ],
                    ],
                },
            ]
        }
    },
    mounted() {
        // console.log(this.stages[this.currentStage].questions)
        this.getMeusDados()
        // console.log(this.currentScreenIndex)
    },
    methods: {
        backPage() {
            this.$parent.goToPreviousScreen();
        },
        checkAnswers() {
            const questions = this.stages[this.currentStage].questions[this.currentScreenIndex];
            for (let i = 0; i < questions.length; i++) {
                const question = questions[i];
                if (question.required && !question.answer) {
                    return false; // Retorna falso se uma pergunta obrigatória não foi respondida
                }
            }
            return true; // Retorna verdadeiro se todas as perguntas obrigatórias foram respondidas
        },
        async getMeusDados() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                axios
                    .get(this.urlEnv + `getQuestions`, config)
                    .then((response) => {

                        this.stages[this.currentStage].questions.forEach(questionGroup => {
                            // Loop through each question object in the group
                            questionGroup.forEach(question => {
                                // Get the answer from the first array using the question's ID
                                const answer = response.data.data.questions[question.id]?.answer;
                                // If answer exists in the first array, add it to the question object in the second array
                                if (answer) {
                                    question.answer = answer;
                                    if (question.id == "investidorImpacto") {
                                        this.showOptions = true
                                    }
                                    console.log(this.showOptions)
                                    this.$nextTick(() => {
                                        this.$forceUpdate();
                                    });
                                }
                            });
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } catch (err) {
                console.log(err);
            }
        },
        finishStage() {
            if (!this.checkAnswers()) {
                this.$toast.error('Por favor, preencha todos os campos para continuar.');
                return;
            }
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };


            const data = {
                questions: this.stages[this.currentStage].questions,
                impactComplete: true,
            };

            console.log(data)

            try {
                axios
                    .post(this.urlEnv + "saveQuestions", data, config)
                    .then((response) => {
                        console.log(response.data);
                        this.$emit('incrementPosition')
                        this.$parent.goToNextScreen();
                    })
                    .catch((error) => {
                        this.$toast.error(error);
                    });
            } catch (error) {
                console.log(error);
            }
        },
        // finishStage() {
        //     // this.$emit('incrementPosition')
        //     // this.$parent.goToNextScreen();
        //     console.log(this.stages[this.currentStage].questions)
        // },
        getColor(position) {
            return position <= this.currentScreenIndex ? 'secondary' : 'gray'; // Retorna 'secondary' se a posição for menor ou igual a currentScreenIndex
        },
        selectAllQuestions(question) {
            const allOptions = question.options.length ? question.options.map(option => option.value) : [];
            question.answer = question.answer?.length === allOptions.length ? [] : allOptions;
            this.$nextTick(() => {
                this.$forceUpdate();
            });
            console.log(question.answer);
        }
    },

}
</script>

<style scoped>
.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.primary--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

.custom-container {
    /* max-width: 1440px; */
    margin: 0 auto;
    border: 1px solid #fff;
    padding: 20px 0;
}


.v-timeline::before {
    transform: rotate(90deg) !important;
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
    left: 0 !important
}

.adjustScreen {
    height: 90vh;
}
.footer {
    background-color: #00265b;
}
</style>