import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            desenvolvimento_social: '#032A5A',
            educacao_e_cultura: '#d64d7a',
            meio_ambiente: '#14AA95',
            primary: '#00265b',
            secondary: '#14AA95',
            button: '#D64D7A',
            error: '#b71c1c',
            gray: '#CBD2DB'
          },
        },
      },
      fonts: {
        roboto: 'Raleway, sans-serif',
      },
});
