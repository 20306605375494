<template>
    <div style="display: flex; justify-content: center;">
        <v-card elevation="5" :width="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '1150' : '1000') : ''"
            :height="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '400' : '360') : ''"
            v-if="question.options && question.options.length > 0" class="pa-5"
            style="justify-content: center; align-items: center; border-radius: 2% !important" rounded>
            <div style="display: grid; justify-content: center; align-items: center;">
                <p class="px-xl-24 px-lg-16 text-center text-h7 primary--text text-h7 font-weight-thin"
                    style="margin-bottom: 0 !important">
                    {{ currentQuestionIndex + 1 + '/' + totalQuestions }}
                </p>
                <div>
                    <h2 class="py-5 px-xl-20 px-lg-10 text-h6 font-weight-bold primary--text">{{ question.text }}</h2>
                    <v-radio-group class="px-lg-12" v-model="selectedValue" style="margin-top: 0 !important">
                        <v-radio color="secondary" :id="question.id" @click="handleButtonClick(question.id)"
                            v-for="option in question.options" :key="option.value" :label="option.text"
                            :value="option.value"></v-radio>
                    </v-radio-group>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        question: Object,
        currentQuestionIndex: Number,
        totalQuestions: Number,
    },
    data() {
        return {
            selectedValue: this.question.answer
        };
    },
    watch: {
        selectedValue(newValue){
            this.$emit("update-answer", newValue, this.question.id);
        }
    },
    methods: {
        handleButtonClick() {
            this.$emit("button-clicked");
        },
    },
};
</script>
