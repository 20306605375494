<template>
    <v-main style="background: rgb(249 250 251); height: 100vh; padding: 0;">
        <div class="d-flex flex-column items-center primary" style="width: 100%">
          <div class="px-6 py-5 d-flex align-center" style="justify-content: center">
            <div class="d-flex">
              <div class="text-h5 font-weight-medium text-center white--text"
                style="display: flex; align-items: center; font-family: 'Raleway'">
                {{ $t('investment_profile') }}
              </div>
            </div>
          </div>
        </div>  
        <template>
            <div class="ImageInvest" style="height: 80vh;">
                <div style="display: flex; justify-content: center;">
                  <v-card elevation="0" class="mt-16 pa-10 mx-5"
                    style="display: grid; justify-content: center; align-items: center; border: 1px solid gray; transform: scaleX(-1);">
                    <span class="px-lg-16 font-weight-medium primary--text text-center" style="font-size: 16px;">Seu perfil de investidor é:
                      <br><br><strong style="font-size: 20px;">{{ selectedProfile.name }}</strong></span>
                    <span
                      class="text-center" style="font-size: 13px;">{{ selectedProfile.desc }}</span>
                  </v-card>
                </div>
            </div>
        </template>
  
        <v-col cols="12" class="mt-4" style="padding: 2px">
          <div class="footer d-flex justify-center">
            <v-btn class="mr-5 px-10 primary--text" style="border: 1px solid #00265b" v-if="currentQuestionIndex == 0"
              rounded @click="backPage">{{ $t('back') }}</v-btn>
            <v-btn class="px-10" dark rounded color="primary" @click="handleButtonClick">
              {{ $t('finish') }}
            </v-btn>
          </div>
        </v-col>
    </v-main>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    props: {
      currentTimeline: Array,
      currentPosition: Number,
    },
    data() {
      return {
        activeIndex: 0,
        completedIndexes: [0],
        currentQuestionIndex: 0,
        token: localStorage.getItem("token"),
        urlEnv: process.env.VUE_APP_SERVICE_URL,
        profiles: [
          {
            name: 'Conservador',
            desc: 'Clientes com este perfil têm como objetivo a preservação do capital e possuem baixa tolerância a riscos. Também é representado por clientes que, apesar de estarem dispostos a correr um pouco mais de riscos na busca por retornos diferenciados, tenham necessidade de sacar os recursos em curto período de tempo. '
          },
          {
            name: 'Moderado',
            desc: 'Clientes com este perfil estão dispostos a correr alguns riscos em investimentos, buscando um retorno diferenciado no médio prazo, com baixa necessidade de liquidez no curto prazo, havendo disponibilidade para diversificar parte das aplicações em alternativas mais arrojadas. '
          },
          {
            name: 'Arrojado',
            desc: 'Este perfil é representado por clientes com alta tolerância a riscos, baixa ou nenhuma necessidade de liquidez no curto/médio prazo e que estejam dispostos a aceitar as oscilações dos mercados de risco (e possíveis perdas) na busca por retornos diferenciados no longo prazo. '
          }
        ],
        selectedProfile: {
          name: 'Carregando...',
          desc: ''
        },
        currentScreenIndex: 0,
      }
    },
    mounted() {
      this.getMeusDados()
    },
    methods: {
      backPage() {
        this.$parent.goToPreviousScreen();
      },
      async getMeusDados() {
        const config = {
          headers: { Authorization: `Bearer ${this.token}` },
        };
        try {
          axios
            .get(this.urlEnv + `getQuestions`, config)
            .then((response) => {
              let totalPoints = 0;
              const questions = response.data.data.questions;
              for (var key in questions) {
                if (Object.prototype.hasOwnProperty.call(questions, key)) {
                  if (Object.prototype.hasOwnProperty.call(questions[key], 'points')) {
                    totalPoints += questions[key].points;
                  }
                }
              }
              if (totalPoints <= 22)
                this.selectedProfile = this.profiles.find(profile => profile.name == "Conservador");
              else if (totalPoints >= 23 && totalPoints <= 32)
                this.selectedProfile = this.profiles.find(profile => profile.name == "Moderado");
              else if (totalPoints >= 33)
                this.selectedProfile = this.profiles.find(profile => profile.name == "Arrojado");
            })
            .catch((error) => {
              console.error(error);
            });
        } catch (err) {
          console.log(err);
        }
      },
      handleButtonClick() {
        this.finishStage();
      },
      finishStage() {
        const config = {
          headers: { Authorization: `Bearer ${this.token}` },
        };
        const data = {
          profile: this.selectedProfile,
          investComplete: true,
        };
        try {
          axios
            .post(this.urlEnv + "saveQuestions", data, config)
            .then(() => {
              this.$emit('incrementPosition')
              this.$parent.goToNextScreen();
            })
            .catch((error) => {
              this.$toast.error(error);
            });
        } catch (error) {
          console.log(error);
        }
      },
      getColor(position) {
        return position <= this.currentPosition ? 'secondary' : 'gray'; // Retorna 'secondary' se a posição for menor ou igual a currentScreenIndex
      },
    },
  
  }
  </script>
  
  <style scoped>
  .text-h4,
  .text-xl-h4,
  .text-xl-h5,
  .text-h3,
  .text-h5 .text-h6,
  .white--text,
  .primary--text,
  .text-center,
  .d-flex {
    font-family: 'Raleway', cursive !important;
  }
  
  .custom-container {
    /* max-width: 1440px; */
    margin: 0 auto;
    border: 1px solid #fff;
    padding: 20px 0 ;
  }
  
  .stage-indicator {
    display: inline-block;
    width: 100px;
    /* Ajuste conforme necessário */
    padding: 5px;
    margin: 0 10px;
    /* Espaçamento entre as etapas */
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .v-timeline::before {
    transform: rotate(90deg) !important;
  }
  
  .v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
    left: 0 !important
  }

  .footer {
    border: none;
    background: white;
  }

  .ImageInvest {
    background-image:url(@/assets/imageInvestPoint.png);
    background-repeat: no-repeat;
    background-size: contain;
    transform: scaleX(-1);
    /* filter: invert(); */
  }
  </style>