<template>
    <div>
    <v-col cols="12" class="pa-2">
        <v-text-field v-model="localName" class="rounded-lg" label="Nome"
            hide-details="auto" outlined type="text" dense ></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-2">
        <v-text-field :rules="rulesEmail" type="email" v-model="localEmail"
            class="rounded-lg" label="Email" hide-details="auto" outlined dense></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-2">
        <v-text-field v-mask="'(##) # ####-####'" v-model="localPhoneNumber"
            class="rounded-lg" label="Telefone" hide-details="auto" outlined dense></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-2">
        <v-text-field :append-icon="localShowPass1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="localShowPass1 = !localShowPass1" :type="localShowPass1 ? 'text' : 'password'" class="rounded-lg"
            label="Senha" :rules="rules" hide-details="auto" name="password" v-model="localPassword" outlined
            dense></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-2">
        <v-text-field :append-icon="localShowPass2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="localShowPass2 = !localShowPass2" :type="localShowPass2 ? 'text' : 'password'"
            class="rounded-lg" v-model="localConfirmPassword" :rules="confirmPasswordRules" label="Confirme a Senha"
            hide-details="auto" outlined dense></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-2">
        <v-checkbox v-model="localClickJuridica" dense hide-details>
            <template v-slot:label>
            <h5 class="primary--text" style="font-weight: 500;">
                Sou investidor(a) pessoa juridica
            </h5>
            </template>
        </v-checkbox>
        <v-checkbox v-model="localCheckbox" dense hide-details>
            <template v-slot:label>
            <div>
                <h5 class="primary--text" style="font-weight: 500;">
                Eu concordo com os <a href="/pdf/TERMO_E_USO_plataforma_Belat.pdf" target="_blank" @click.stop><b><u>termos e condições</u></b></a>
                </h5>
            </div>
            </template>
        </v-checkbox>
        <v-checkbox v-model="localCheckbox2" dense hide-details>
            <template v-slot:label>
            <div>
                <h5 class="primary--text" style="font-weight: 500;">
                Eu concordo com as <a href="/pdf/POLITICA_DE_PRIVACIDADE_plataforma_Belat_24_0507.pdf" target="_blank" @click.stop><b><u>políticas de privacidade</u></b></a>
                </h5>
            </div>
            </template>
        </v-checkbox>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
        <v-hover v-slot="{ hover }">
            <v-btn :class="{ 'on-hover': hover }" block rounded class="pa-5 py-6 transition-fast-in-fast-out"
            :color="hover ? 'primary' : 'secondary'" @click="submit" :disabled="!(localCheckbox && localCheckbox2) || disabled">
            <span>Criar conta</span>
            </v-btn>
        </v-hover>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
        <span class="text-center blue-grey--text text--darken-1">
            Já tem uma conta?
            <a class="text-decoration-none secondary--text " @click="$emit('goToLogin')">
            Clique aqui
            </a>
        </span>
        </v-col>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        phone_number: String,
        email: String,
        password: String,
        confirmPassword: String,
        checkbox: Boolean,
        checkbox2: Boolean,
        showPass1: Boolean,
        showPass2: Boolean,
        confirmPass1: Boolean,
        disabled: Boolean
    },
    data() {
        return {
            localName: this.name,
            localPhoneNumber: this.phone_number,
            localEmail: this.email,
            localPassword: this.password,
            localConfirmPassword: this.confirmPassword,
            localCheckbox: this.checkbox,
            localCheckbox2: this.checkbox2,
            localShowPass1: this.showPass1,
            localShowPass2: this.showPass2,
            localClickJuridica: false,
            rulesEmail: [
                (v) => this.validateEmail(v) || "E-mail inválido",
            ],
            rules: [
                (v) => !!v || "Campo obrigatório",
                (v) =>
                    (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
                (v) =>
                    (v && /[A-Z]/.test(v)) ||
                    "A senha deve conter pelo menos uma letra maiúscula",
                (v) =>
                    (v && /[a-z]/.test(v)) ||
                    "A senha deve conter pelo menos uma letra minúscula",
                (v) =>
                    (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
            ],
            confirmPasswordRules: [
                (value) => !!value || "Digite a senha novamente",
                (value) => value === this.localPassword || "As senhas são diferentes",
            ],
        };
    },
    watch: {
        localName(newVal) {
            this.$emit('update:name', newVal);
        },
        localPhoneNumber(newVal) {
            this.$emit('update:phone_number', newVal);
        },
        localEmail(newVal) {
            this.$emit('update:email', newVal);
        },
        localPassword(newVal) {
            this.$emit('update:password', newVal);
        },
        localConfirmPassword(newVal) {
            this.$emit('update:confirmPassword', newVal);
        },
        localCheckbox(newVal) {
            this.$emit('update:checkbox', newVal);
        },
        localCheckbox2(newVal) {
            this.$emit('update:checkbox2', newVal);
        },
        localShowPass1(newVal) {
            this.$emit('update:showPass1', newVal);
        },
        localShowPass2(newVal) {
            this.$emit('update:showPass2', newVal);
        },
        localClickJuridica(newVal) {
            this.$emit('update:clickJuridica', newVal);
        }
    },
    methods: {
        validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        submit() {
            this.$emit('submit');
        }
    },
    
};
</script>
