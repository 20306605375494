import apiService from "@/services/apiService";

export default {
    data() {
        return {
            user: {
                id: null,
                name: null,
                email: null,
                phone_number: null,
                profile_photo_url: null,
            },
        };
    },
    methods: {
        async getUserData() {
            try {
                const response = await apiService.getUserData();
                const userData = response.data;
                this.user.id = userData.id;
                this.user.name = userData.name;
                this.user.email = userData.email;
                this.user.phone_number = userData.phone_number;
                this.user.profile_photo_url = userData.profile_photo_url;
                localStorage.setItem("userId", userData.id);
            } catch (error) {
                console.error(error);
            }
        },
        async checkUser() {
            try{
                const response = await apiService.checkUser();
                if(response.data.status == false) 
                    this.$router.replace('/templateRegister');
            }catch(error){
                console.error(error);
            }
        },
        async getMeusDados() {
            try {
                const response = await apiService.getMeusDados();
                this.titularUser = response.data.data.dados_bancarios;
                this.titularAll = response.data.data.dados_bancarios.tipo_conta;  
            } catch (error) {
                console.error(error);
            }
        },
        async getTitularEndereco(){
            try {
                const response = await apiService.getMeusDados();
                this.titularUser = response.data.data.endereco;
            } catch (error) {
                console.error(error);
            }
        },
        async getDadosContato(){
            try {
                const response = await apiService.getMeusDados();
                this.titularUser = response.data.data.dados_contato;
                this.titular = response.data.data;
            } catch (error) {
                console.error(error);
            }
        },
        async getDadosProfissao(){
            try {
                const response = await apiService.getMeusDados();
                this.titularUser = response.data.data.dados_profissionais;
                this.infoAditional = response.data.data.acesso
                this.infoTotal = response.data.data.dados_pessoais    
            } catch (error) {
                console.error(error);
            }
        },
        async getDadosPessoais(){
            try {
                const response = await apiService.getMeusDados();
                this.titularUser = response.data.data.dados_pessoais;  
            } catch (error) {
                console.error(error);
            }
        },
    }
};
