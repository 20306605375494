<template>
    <v-row class="pt-6 px-5">
      <v-col cols="12" md="4">
        <v-text-field
          v-mask="'###.###.###-##'"
          label="CPF *"
          v-model="internalTitularUser.cpf"
          @input="updateField('cpf', $event)"
          disabled
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="internalTitularUser.estado_civil"
          :items="estadoCivil"
          item-text="nome"
          item-value="codigo"
          :label="`${$t('marital_status')}`"
          @input="updateField('estado_civil', $event)"
          disabled
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
            :label="`${$t('date_of_birth')}`"
            v-model="internalTitularUser.data_nascimento"
            :value="formatDate(birth)"
            type="date"
            @input="updateField('data_nascimento', $event)"
            disabled
            outlined
            dense
            hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-mask="'##.###.###-#'"
          label="Documento de identificação"
          v-model="internalTitularUser.identidade"
          @input="updateField('identidade', $event)"
          disabled
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
            type="date"
            label="Data emissão do documento"
            v-model="internalTitularUser.data_emissao_identidade"
            :value="formatDate(emissaoDoc)"
            @input="updateField('data_emissao_identidade', $event)"
            disabled
            outlined
            dense
            hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          label="Órgão"
          v-model="internalTitularUser.orgao_expeditor"
          @input="updateField('orgao_expeditor', $event)"
          disabled
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          label="Uf"
          v-model="internalTitularUser.uf_orgao_expeditor"
          @input="updateField('uf_orgao_expeditor', $event)"
          disabled
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="py-2">
        <v-select
          :label="`${$t('gender')} *`"
          v-model="internalTitularUser.sexo"
          @input="updateField('sexo', $event)"
          :items="genderItens"
          item-text="nome"
          item-value="codigo"
          disabled
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" class="py-2">
        <v-text-field
          :label="`${$t('birthplace')} *`"
          v-model="internalTitularUser.naturalidade"
          @input="updateField('naturalidade', $event)"
          disabled
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="py-2">
        <v-text-field
          :label="`${$t('nationality')} *`"
          v-model="internalTitularUser.nacionalidade"
          @input="updateField('nacionalidade', $event)"
          disabled
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="`${$t('mothers_name')} *`"
          v-model="internalTitularUser.nome_mae"
          @input="updateField('nome_mae', $event)"
          disabled
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    props: {
      titularUser: Object,
      estadoCivil: Array,
      genderItens: Array,
      emissaoDoc: null,
      birth: null,
    },
    data() {
      return {
        internalTitularUser: this.titularUser
      }
    },
    methods: {
      formatDate(date) {
      if (date instanceof Date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
      }
      return date;
    },
      updateField(field, value) {
        this.$emit('update-titular-user', { field, value });
        this.internalTitularUser[field] = value;
      }
    },
    watch: {
      titularUser: {
        handler(newVal) {
          this.internalTitularUser = { ...newVal };
        },
        deep: true
      }
    },
    mounted(){
      console.log(this.titularUser);
    }
  };
  </script>
  