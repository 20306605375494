<template>
    <v-select
      class="rounded-lg"
      :items="items"
      :label="label"
      hide-details="auto"
      v-model="selectedAccountType"
      item-text="nome"
      item-value="id"
      outlined
      :disabled="disabled"
      dense
      @input="onInput"
    ></v-select>
  </template>
  
  <script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },
      value: {
        type: [String, Number],
        default: '',
      },
      label: {
        type: String,
        default: 'Tipo de conta',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedAccountType: this.value,
      };
    },
    watch: {
      value(newValue) {
        this.selectedAccountType = newValue;
      },
    },
    methods: {
      onInput(value) {
        this.$emit('input', value);
      },
    },
  };
  </script>
  