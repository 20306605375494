<template>
    <v-hover v-slot="{ hover }">
      <v-btn style="width: 100%"
        :class="{ 'on-hover': hover }" class="rounded-xl px-16 transition-fast-in-fast-out"
        :aria-label="text"
        :disabled="disabled"
        :color="hover ? 'secondary' : 'primary'">
        {{ text }}
      </v-btn>
    </v-hover>
  </template>
  
  <script>
  export default {
    props: {
      text: String,
      disabled: Boolean
    },
  };
  </script>
  