<template>
  <div>
    <UserPersonalInfo 
      :titularUser="titularUser" 
      :estadoCivil="estadoCivil" 
      :genderItens="genderItens"
      @update-titular-user="handleUpdateTitularUser" 
    />
    <CustomDivider />
    <UserContactInfo />
  </div>
</template>

<script>
import UserPersonalInfo from '../Components/UserPersonalInfo.vue';
import UserContactInfo from '../Components/UserContactInfo.vue';
import CustomDivider from '../Components/CustomDivider.vue';
import userMixin from '@/mixins/userMixin';
import dataGetMixin from '@/mixins/dataGetMixin';
import apiService from '@/services/apiService';

export default {
  components: {
    UserPersonalInfo,
    UserContactInfo,
    CustomDivider
  },
  mixins: [userMixin, dataGetMixin],
  data() {
    return {
      estadoCivil: [],
      genderItens: [],
      titularUser: {}
    };
  },
  methods: {
    handleUpdateTitularUser({ field, value }) {
      this.$set(this.titularUser, field, value);
    },
    formatDate(date) {
      if (date instanceof Date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
      }
      return date;
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async confirmUser() {
      const userData = {
        name: this.user.name,
        phone_number: this.user.phone_number,
        email: this.user.email,
        password: this.password,
      };
      try {
        await apiService.createLogin(userData);
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    this.getUserData();
    this.getDadosPessoais();
    this.getSexos();
    this.getEstadosCivis();
  }
};
</script>
