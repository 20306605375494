<template>
  <v-container class="custom-container" style="height: 100%; padding: 0;">
    <v-row style="height:100%;">
      <v-app-bar class="custom-container2" fixed color="#DF6E8B" style="height: 15px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;">
      </v-app-bar>
      <v-col cols="12" md="7" class="primary d-flex flex-column justify-center position-relative">
        <div class="d-flex justify-end">
          <v-img class="gifLogo" src="@/assets/gif_banca.gif"></v-img>
        </div>
        <!-- Texto de background -->
        <div class="col-9">
          <div class="d-flex align-start h-100 flex-column pl-16 mt-16" style="position: relative;">
            <v-img class="mb-2" max-width="250" src="@/assets/banca_etica.png"></v-img>
            <h3 class="pt-5 text-h4 font-weight-medium white--text">Olá, <br /> que bom ver você aqui.</h3>
            <p style="font-size: 1.2rem;" class="mt-5 font-weight-light white--text mb-3">Aqui, na plataforma de investimento da BELAT, você pode conectar seus investimentos com seus valores, e gerar um impacto socioambiental positivo em qualquer operação de sua escolha.
              <br>Impacto é uma questão de escolha. Venha conosco e faça a sua</p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="5" lx="5" class="pa-20 white" style="z-index: 10; display: flex; justify-content: center; align-items: center;">
        <div style="width: 70%; max-width: 420;">
          <v-row class="text-center">
            <v-col cols="12">
              <h1 class="primary--text text-h4 pt-lg-10">Criar uma conta</h1>
            </v-col>
            <RegisterForm
              :disabled="disabled" 
              :name="name"
              :phone_number="phone_number"
              :email="email"
              :password="password"
              :confirmPassword="confirmPassword"
              :checkbox="checkbox"
              :checkbox2="checkbox2"
              :showPass1="showPass1"
              :showPass2="showPass2"
              @update:name="name = $event"
              @update:phone_number="phone_number = $event"
              @update:email="email = $event"
              @update:password="password = $event"
              @update:confirmPassword="confirmPassword = $event"
              @update:checkbox="checkbox = $event"
              @update:checkbox2="checkbox2 = $event"
              @update:showPass1="showPass1 = $event"
              @update:showPass2="showPass2 = $event"
              @update:clickJuridica="clickJuridica = $event"
              @submit="submit"
              @goToLogin="goToLogin"
            />
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import cryptoMixin from "@/mixins/cryptoMixin";
import axios from "axios";
import RegisterForm from "./Components/RegisterForm.vue";

export default {
  metaInfo() {
    return {
      title: 'BELat - Cadastro'
    }
  },
  mixins: [cryptoMixin],
  components: {
    RegisterForm
  },
  data() {
    return {
      clickJuridica: false,
      errorMessageMap: {
        name: "O campo nome é obrigatorio",
        email: "O campo email é obrigatorio",
        phone_number: "O campo telefone é obrigatorio",
        password: "O campo senha é obrigatorio",
        confirmPassword: "Confirme a senha.",
      },
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      name: null,
      phone_number: null,
      email: null,
      password: null,
      confirmPassword: null,
      showPass1: false,
      confirmPass1: false,
      showPass2: false,
      confirmPass2: false,
      isLoginMode: false,
      disabled: false,
      rulesEmail: [
        (v) => this.validateEmail(v) || "E-mail inválido",
      ],
      rules: [
        (v) => !!v || "Campo obrigatório",
        (v) =>
          (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
        (v) =>
          (v && /[A-Z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra maiúscula",
        (v) =>
          (v && /[a-z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra minúscula",
        (v) =>
          (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
      ],
      confirmPasswordRules: [
        (value) => !!value || "Digite a senha novamente",
        (value) => value === this.password || "As senhas são diferentes",
      ],
      checkbox: false,
      checkbox2: false,
    };
  },
  methods: {
    updateFullName() {
      this.full_name = `${this.name} ${this.surname}`;
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    removeNonNumeric(value) {
      return value?.replace(/\D/g, "");
    },
    submit() {
      this.disabled = true;
      const fields = ["name", "email", "phone_number", "password", "confirmPassword"];
      for (const field of fields) {
        if (!this[field]) {
          this.$toast.error(this.errorMessageMap[field]);
          this.disabled = false;
          return;
        }
      }

      const emailValid = this.rulesEmail.every(rule => rule(this.email) === true);
      const passwordValid = this.rules.every(rule => rule(this.password) === true);
      const confirmPasswordValid = this.confirmPasswordRules.every(rule => rule(this.confirmPassword) === true);

      if (!passwordValid || !confirmPasswordValid || !emailValid) {
          this.$toast.error("Por favor, corrija os erros nos campos.");
          this.disabled = false;
          return;
      }

      let url = 'createLogin'
      if (this.clickJuridica) url = 'createLead';
      
      const userData = {
        name: this.name,
        phone_number: this.removeNonNumeric(this.phone_number),
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword
      };

      axios
        .post(this.urlEnv + url, userData, { headers: { 'X-Frontend-URL': window.location.origin } })
        .then(() => {
          if (this.clickJuridica) {
            const encryptedUser = this.encrypt(JSON.stringify(userData));
            localStorage.setItem('encryptedUser', encryptedUser);
            this.$router.replace("/juridica");
          } else {
            this.$router.push({
              name: "SendEmail",
              query: { email: this.encrypt(this.email) }
            });
          }
        })
        .catch((err) => {
          this.disabled = false;
          if (err?.response?.data?.mensagem) {
            Object.values(err.response.data.mensagem).forEach((input) => {
              this.$toast.error(input.length > 0 ? input[0] : input);
            });
          } else {
            console.log(err);
          }
        });
    },
    goToLogin() {
      this.$router.replace("/");
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.custom-letter-spacing {
  font-family: 'Montserrat', sans-serif !important;
}

.v-messages,
.v-text-field__details {
  display: none;
}

.custom-container {
  max-width: 1440px;
  margin: 0 auto;
  border: 1px solid #fff;
}

.custom-container2 {
  max-width: 1461px;
  margin: 0 auto;
  border: 1px solid #fff;
}

.gifLogo {
  position: absolute;
  top: -30%;
  left: 27%;
  bottom: 2%;
  max-width: 40%;
}

.teste {
  position: absolute;
  top: -25%;
  left: 27%;
  bottom: 2%;
  max-width: 40%;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
  font-family: 'Raleway', cursive !important;
}
</style>
