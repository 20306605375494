<template>
    <div class="d-flex align-center" style="border-bottom: 1px solid #14aa95;">
      <div class="d-flex ml-16">
        <v-list-item-avatar class="ml-2" size="60">
          <v-img :src="user.profile_photo_url"></v-img>
        </v-list-item-avatar>
        <div class="text-h4 font-weight-medium text-center white--text"
             style="display: flex; align-items: center; font-family: 'Raleway'">
          {{ user.name }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      user: {
        type: Object,
        required: true
      }
    }
  }
  </script>
  