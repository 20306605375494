<template>
    <v-hover v-slot="{ hover }">
      <v-btn rounded style="width: 100%" :class="{ 'on-hover': hover }" class="transition-fast-in-fast-out mt-3"
        :color="hover ? 'secondary' : 'primary'" @click="$emit('click')" :disabled="disabled"
      >
        {{ $t('simulate_offer') }}
      </v-btn>
    </v-hover>
  </template>
  
  <script>
  export default {
    props: {
      disabled: Boolean,
    }
  };
  </script>
  