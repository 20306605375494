<template>
    <div class="timeline">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="timeline-item"
        :class="{ 'is-completed': completedIndexes.includes(index) }"
        @click="setActiveIndex(index)"
      >
        <div class="timeline-item-circle">
          <v-icon color="white" v-if="completedIndexes.includes(index)">mdi-check</v-icon>
        </div>
        <div v-if="index < items.length - 1" class="timeline-line"></div>
        <div class="timeline-item-content" style="font-weight: 600;">
            <div  class="timeline-color-text">
                <span>
                    {{ item.title }}
                </span>
            </div>
            <div style="font-weight: 500;">
                <p>{{ item.text }}</p>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      items: {
        type: Array,
        required: true
      },
      activeIndex: {
        type: Number,
        default: 0
      },
      completedIndexes: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      setActiveIndex(index) {
        this.$emit('update:activeIndex', index);
      }
    }
  };
  </script>
  
  <style scoped>

  .timeline-color-text {
    color: #14AA95 !important;
  }
  .timeline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .timeline-item {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .timeline-item-circle {
    width: 8px;
    height: 8px;
    min-height: 8px;
    min-width: 8px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 0 0 6px #14AA95;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .timeline-item-circle .v-icon {
    font-size: 12px;
    color: #14AA95;
  }
  
  .timeline-item-content {
    margin-left: 20px; 
  }
  
  .timeline-line {
    position: absolute;
    width: 2px;
    height: 95%; 
    background-color: #ccc;
    left: 2%; 
    transform: translateX(-150%);
    z-index: 1; 
    top: 46px; 
  }
  
  .timeline-item.is-active .timeline-item-circle {
    background-color: #ffffff;
    box-shadow: 0 0 0 6px #14AA95;
  }
  
  .timeline-item.is-completed .timeline-item-circle {
    background-color: #14AA95;
    box-shadow: 0 0 0 6px #14AA95;
  }

  /* .timeline-item.is-active .timeline-item-circle[data-v-96c712ec] {
    background-color: #ffffff;
    box-shadow: 0 0 0 6px #14AA95;
    width: 11px !important;
    height: 8px !important;
  } */
  </style>
  