<template>
  <div>
    <v-col cols="12">
      <h1 class="text-center">{{ $t('accesses') }}</h1>
    </v-col>
    <v-row class="d-flex justify-center">
      <v-col cols="9" md="6">
        <!-- <label>Email</label> -->
        <v-text-field
          :rules="rulesEmail"
          type="email"
          v-model="user.email"
          class="rounded-lg"
          hide-details="auto"
          label="Email"
          outlined
          dense
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="9" md="6">
        <v-text-field
          v-on:keyup.enter="confirmUser"
          :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPass1 = !showPass1"
          :type="showPass1 ? 'text' : 'password'"
          class="rounded-lg"
          :label="$t('new_password')"
          :rules="rules"
          hide-details="auto"
          name="password"
          v-model="password"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-col cols="12" class="d-flex justify-center">
      <v-hover v-slot="{ hover }">
        <v-btn
          :class="{ 'on-hover': hover }"
          class="transition-fast-in-fast-out"
          :color="hover ? 'primary' : 'secondary'"
          @click="confirmUser"
        >
          <span>{{$t('confirm')}}</span>
        </v-btn>
      </v-hover>
    </v-col>
  </div>
</template>

<script>
import userMixin from "@/mixins/userMixin";
import apiService from "@/services/apiService";
export default {
  data() {
    return {
      password: null,
      showPass1: false,
      confirmPass1: false,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      rules: [
        (v) => !!v || "Campo obrigatório",
        (v) =>
          (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
        (v) =>
          (v && /[A-Z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra maiúscula",
        (v) =>
          (v && /[a-z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra minúscula",
        (v) =>
          (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
      ],
      rulesEmail: [(v) => this.validateEmail(v) || "E-mail inválido"],
    };
  },
  mixins: [userMixin],
  methods: {
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async confirmUser() {
      const userData = {
        name: this.user.name,
        phone_number: this.user.phone_number,
        email: this.user.email,
        password: this.password,
      };
      try{
        await apiService.createLogin(userData);
      }catch(error){
        console.error(error);
      }
    },
  },
  mounted() {
    this.getUserData();
  },
};

</script>
