<template>
  <div>
    <layout>
      <template v-slot:content>
        <div class="d-flex justify-center mt-5">
          <h2 class="text-center primary--text">{{ $t('my_investments') }}</h2>
        </div>
        <v-data-table
          :page.sync="page"
          :headers="headers"
          :items="desserts"
          class="mt-10 px-5 no-background"
          hide-default-footer
          mobile-breakpoint="xs"
          @page-count="pageCount = $event"
          :disable-sort="true"
        >
          <template slot="no-data">
            {{ $t('no_investments_found') }}
          </template>
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-select
                v-model="sortBy"
                :items="sortOptions"
                label="Ordenar por"
                single-line
                hide-details
              ></v-select>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <v-card class="mb-4 custom-card">
              <v-card-title>
                <span style="color:#032A5A; font-size:16px">{{ item.nome_projeto }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <table style="width: 100%;">
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('start_date') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.data_inicio }}</td>
                  </tr>
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('business') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.nome_projeto }}</td>
                  </tr>
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('investiment_value') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.valor_investido }}</td>
                  </tr>
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('rate') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.taxa_retorno }}</td>
                  </tr>
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('deadline') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.prazo }}</td>
                  </tr>
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('status') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.status.nome }}</td>
                  </tr>
                </table>
              </v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </template>
    </layout>
  </div>
</template>

<script>
import formatMixin from "@/mixins/formatMixin";
import businessMixin from "@/mixins/businessMixin";
import layout from "@/layouts/internalLayoutMobile.vue";

export default {
  metaInfo() {
    return {
      title: 'BELat - Meus Investimentos'
    };
  },
  components: {
    layout,
  },
  mixins: [formatMixin, businessMixin],
  data() {
    return {
      page: 1,
      pageCount: 0,
      sortBy: '',
      sortOptions: [
        { text: this.$t('start_date'), value: 'data_inicio' },
        { text: this.$t('investiment_value'), value: 'valor_investido' },
        { text: this.$t('rate'), value: 'taxa_retorno' },
        { text: this.$t('deadline'), value: 'prazo' },
        { text: this.$t('status'), value: 'status.nome' },
      ],
      headers: [],
      desserts: [],
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
    };
  },
  mounted() {
    this.getInvestiments();
  },
};
</script>

<style scoped>
.custom-card {
  border-radius: 16px;
}
.text-start {
  font-family: 'Raleway';
}
th > span {
  font-family: 'Raleway';
  color: white;
}
.v-card {
  margin-bottom: 16px;
  border: 1px solid #dddddd;
}
table tr td {
  padding: 0.5rem 0;
}
</style>
