<template>
  <v-app>
    <v-app-bar fixed color="white" max-height="70" style="z-index: 99;">
      <v-img max-width="150" src="../assets/belat.png" @click="finish" style="cursor: pointer"></v-img>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <div v-on="on" style="cursor: pointer" @click="$router.push('/user/MyInfo')">
            <span class="mr-2" style="color:#032A5A;font-size: 15px; font-weight: 600;">{{ user.name }}</span>
            <v-avatar>
              <v-img max-width="40" max-height="40" style="object-fit: contain;" src="../assets/icon/user.png"></v-img>
            </v-avatar>
          </div>
        </template>
      </v-menu>

    </v-app-bar>
    <v-navigation-drawer width="20%" style="max-width: 300px" permanent app>
      <v-list-item class="flex justify-center items-center my-5">
      </v-list-item>
      <v-list nav>
        <v-list-item color="secondary" link class="adjustInternal mt-5 mb-0" :to="{ name: 'HomePage' }">
          <v-list-item-icon>
            <svg width="35" height="31" viewBox="0 0 35 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M30.5193 30.1152H4.48047V9.4751H5.94951V28.6461H29.0502V9.4751H30.5193V30.1152Z"
                fill="#032A5A" />
              <path
                d="M34.1553 13.5887L17.5184 1.79958L0.844701 13.5887L0 12.4134L17.5184 0L35 12.4134L34.1553 13.5887Z"
                fill="#032A5A" />
              <path d="M15.7187 29.16H14.2496V18.9134H10.1363V29.16H8.66724V17.4443H15.7187V29.16Z" fill="#032A5A" />
              <path d="M25.892 23.2471H18.8406V17.4443H25.892V23.2471ZM20.3096 21.778H24.4229V18.9134H20.3096V21.778Z"
                fill="#032A5A" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="tile" color="primary">Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="secondary" class="adjustInternal mb-0" link :to="{ name: 'ListView' }">
          <v-list-item-icon>
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.2594 15.0453C17.6142 14.7245 16.8633 14.4876 15.9447 14.3199C14.8584 14.1449 12.9301 13.6674 12.2448 12.4025C11.8548 11.6844 11.9058 10.7403 12.3979 9.5957C14.195 6.84357 18.2157 8.55317 19.0067 8.92498L19.6264 7.60542C18.5984 7.1206 17.4137 6.75608 16.229 6.65037V4.846H14.771V6.66495C13.3384 6.82534 12.0188 7.46325 11.1367 8.86301L11.0857 8.95778C10.3785 10.558 10.3384 11.9505 10.9654 13.0987C11.9022 14.8193 14.0638 15.4936 15.7005 15.7561C16.466 15.8946 17.0893 16.0878 17.596 16.343C19.8196 17.5131 19.229 19.0149 19.0286 19.5216C18.5984 20.6808 17.8439 21.439 16.7868 21.7707C15.2558 22.2518 13.2363 21.7707 11.3809 20.4876L10.5498 21.6868C11.9168 22.6346 13.3894 23.2032 14.7746 23.36V25.2336H16.2327V23.3673C16.5753 23.3272 16.907 23.2616 17.2242 23.1631C18.7114 22.6929 19.8086 21.6103 20.3882 20.0428C21.1938 18.0015 20.421 16.1826 18.2594 15.0453Z"
                fill="#032A5A" />
              <path
                d="M15.5 30.1729C7.22904 30.1729 0.5 23.4438 0.5 15.1729C0.5 6.90189 7.22904 0.172852 15.5 0.172852C23.771 0.172852 30.5 6.90189 30.5 15.1729C30.5 23.4438 23.771 30.1729 15.5 30.1729ZM15.5 1.63093C8.03098 1.63093 1.95808 7.70748 1.95808 15.1729C1.95808 22.6382 8.03098 28.7148 15.5 28.7148C22.969 28.7148 29.0419 22.6419 29.0419 15.1729C29.0419 7.70384 22.9654 1.63093 15.5 1.63093Z"
                fill="#032A5A" />
            </svg>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="tile" color="primary">Investir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="secondary" class="adjustInternal mb-0" link :to="{ name: 'MyInvestiments' }">
          <v-list-item-icon>
            <svg width="31" height="33" viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4244_2740)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.53483 17.9207C3.93818 17.9207 0.21875 14.2013 0.21875 9.60466C0.21875 5.00801 3.93818 1.28857 8.53483 1.28857C13.1315 1.28857 16.8509 5.00801 16.8509 9.60466C16.8509 14.2013 13.0964 17.9207 8.53483 17.9207ZM8.53483 2.69213C4.74522 2.69213 1.62231 5.77996 1.62231 9.60466C1.62231 13.4294 4.71013 16.5172 8.53483 16.5172C12.3595 16.5172 15.4474 13.4294 15.4474 9.60466C15.4474 5.77996 12.3244 2.69213 8.53483 2.69213ZM9.23651 4.09506H7.83295V4.71383C7.10588 4.85686 6.44175 5.24313 5.97317 5.98993L5.93808 6.09519C5.51702 7.04259 5.48193 7.88472 5.86791 8.5865C6.49951 9.70934 7.86797 10.0602 8.63993 10.1655C8.95573 10.2006 9.27153 10.3059 9.51715 10.4462C10.4295 10.9375 10.254 11.4989 10.1488 11.7445C9.93822 12.2708 9.58733 12.6217 9.13118 12.7621C8.4294 13.0077 7.44691 12.7621 6.5346 12.1305L5.72755 13.2884C6.40967 13.7607 7.13103 14.076 7.83295 14.205V14.973H9.23651V14.1956C9.36197 14.1642 9.49013 14.1205 9.62242 14.0604C10.4996 13.7797 11.1663 13.1481 11.5172 12.2007C11.9734 10.9725 11.4821 9.88479 10.2189 9.18301C9.79787 8.93739 9.34171 8.79704 8.85046 8.72686C8.53466 8.69177 7.44691 8.48123 7.13111 7.88472C6.95566 7.60401 6.99075 7.18295 7.20128 6.69171C8.01 5.47863 9.88659 6.30419 10.2518 6.46485L10.2891 6.48116L10.8856 5.21797C10.3884 4.98065 9.81837 4.79065 9.23651 4.70188V4.09506ZM5.06092 29.8855V22.7979H11.9033V29.8855H12.956V18.2371H19.7984V29.8855H20.8862V11.8155H17.8685L24.3249 4.48193L30.7812 11.8155H27.7636V29.8855H29.2022V31.2891H3.58724V29.8855H5.06092ZM10.4997 29.8508H6.46448V24.2014H10.4997V29.8508ZM14.3596 29.8516H18.3948V19.6407H14.3596V29.8516ZM1.72746 29.8855H0.604614V31.2891H1.72746V29.8855ZM26.3249 29.8513H22.2897V10.4471H20.9563L24.2898 6.62237L27.6583 10.412H26.3249V29.8513Z"
                  fill="#032A5A" />
              </g>
              <defs>
                <clipPath id="clip0_4244_2740">
                  <rect width="30.5625" height="32.0005" fill="white" transform="translate(0.21875 0.288574)" />
                </clipPath>
              </defs>
            </svg>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="tile" color="primary">Meus Investimentos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item class="mb-0" link :to="{ name: 'transparencia' }">
          <v-list-item-icon>
            <img src="../assets/Icono-transparencia.svg" width="35" alt="Home Icon" >
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="tile" color="primary">Transparencia</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item color="secondary" class="adjustInternal mb-0" link :to="{ name: 'ContactUs' }">
          <v-list-item-icon>
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22.5197 28.645L14.0626 20.188H0.971924V0.163086H28.0282V20.188H22.5197V28.645ZM2.26803 18.8918H14.6135L21.2236 25.5344V18.9242H26.7321V1.4916H2.26803V18.8918Z"
                fill="#1A2D58" />
              <path d="M20.6405 5.34766H8.35986V6.64376H20.6405V5.34766Z" fill="#1A2D58" />
              <path d="M20.6405 9.46289H8.35986V10.759H20.6405V9.46289Z" fill="#1A2D58" />
              <path d="M20.6405 13.5776H8.35986V14.8737H20.6405V13.5776Z" fill="#1A2D58" />
            </svg>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="tile" color="primary">Fale Conosco</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list-item class="adjustInternal mb-0" link :to="{ name: 'config' }">
          <v-list-item-icon>
            <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.4543 24.2352C12.952 24.2352 9.30737 20.5906 9.30737 16.0883C9.30737 11.5861 12.952 7.94141 17.4543 7.94141C21.9565 7.94141 25.6012 11.5861 25.6012 16.0883C25.6012 20.5906 21.9565 24.2352 17.4543 24.2352ZM17.4543 9.16651C13.6258 9.16651 10.5325 12.2599 10.5325 16.0883C10.5325 19.9167 13.6258 23.0101 17.4543 23.0101C21.2827 23.0101 24.3761 19.9167 24.3761 16.0883C24.3761 12.2599 21.2827 9.16651 17.4543 9.16651Z"
                fill="#1A2D58" />
              <path
                d="M20.9151 29.7786H14.0239L13.7482 27.5735C13.1357 27.3897 12.5538 27.1447 11.9718 26.8384L10.2261 28.186L5.3563 23.3469L6.70391 21.6011C6.42826 21.0192 6.18324 20.4373 5.96885 19.8247L3.76367 19.5184V12.6273L5.96885 12.3516C6.15262 11.7391 6.39764 11.1571 6.70391 10.5752L5.3563 8.79882L10.2261 3.92905L11.9718 5.27666C12.5538 5.00101 13.1357 4.756 13.7482 4.5416L14.0239 2.33643H20.9151L21.2213 4.5416C21.8339 4.72537 22.4158 4.97039 22.9977 5.27666L24.7741 3.92905L29.6439 8.79882L28.2963 10.5752C28.5719 11.1571 28.8169 11.7391 29.0313 12.321L31.2365 12.5966V19.5184L29.0313 19.7941C28.8476 20.4066 28.6026 20.9886 28.2963 21.5705L29.6439 23.3469L24.7741 28.2166L22.9977 26.869C22.4158 27.1447 21.8339 27.3897 21.2213 27.6041L20.9151 29.7786ZM15.0958 28.5535H19.8431L20.0881 26.624L20.4863 26.5015C21.252 26.2871 22.0177 25.9502 22.7527 25.5827L23.0896 25.3989L24.6516 26.5934L27.99 23.255L26.7955 21.693L26.9793 21.3561C27.3775 20.6517 27.6837 19.886 27.8981 19.1203L28.0206 18.7221L29.9502 18.4771V13.7298L28.0206 13.4848L27.8981 13.0867C27.6837 12.321 27.3775 11.5553 26.9793 10.8509L26.7955 10.514L27.99 8.95196L24.621 5.58294L23.0896 6.77741L22.7527 6.59364C22.0483 6.19549 21.2826 5.88921 20.4863 5.67482L20.0881 5.55231L19.8431 3.59215H15.0958L14.8508 5.52168L14.4527 5.64419C13.687 5.85859 12.9213 6.19549 12.1862 6.56302L11.8493 6.74678L10.318 5.55231L6.97956 8.92133L8.17403 10.4527L7.99026 10.7896C7.59211 11.494 7.28583 12.2597 7.07144 13.0254L6.94893 13.4236L5.0194 13.6686V18.3852L6.94893 18.6302L7.07144 19.0284C7.28583 19.8247 7.62273 20.5598 7.99026 21.2948L8.17403 21.6317L6.97956 23.1631L10.318 26.5015L11.8493 25.307L12.2169 25.4908C12.9213 25.889 13.687 26.1952 14.4833 26.4096L14.8814 26.5321L15.0958 28.5535Z"
                fill="#1A2D58" />
            </svg>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="tile" color="primary">Configurações</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="adjustInternal mb-0" link @click="logout">
          <v-list-item-icon>
            <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.8322 15.5605H12.7222V16.7856H28.8322V15.5605Z" fill="#1A2D58" />
              <path
                d="M25.3406 21.1962L24.4524 20.308L28.6177 16.1733L24.4524 12.008L25.3406 11.1504L30.3635 16.1733L25.3406 21.1962Z"
                fill="#1A2D58" />
              <path
                d="M17.4694 29.9554H8.28114C6.25973 29.9554 4.63647 28.3321 4.63647 26.3107V6.0353C4.63647 4.01389 6.25973 2.39062 8.28114 2.39062H17.4694C19.4908 2.39062 21.1141 4.01389 21.1141 6.0353V9.89436H19.889V6.0353C19.889 4.68769 18.817 3.61572 17.4694 3.61572H8.28114C6.93354 3.61572 5.86157 4.68769 5.86157 6.0353V26.3107C5.86157 27.6583 6.93354 28.7303 8.28114 28.7303H17.4694C18.817 28.7303 19.889 27.6583 19.889 26.3107V22.5435H21.1141V26.3107C21.1141 28.3015 19.4602 29.9554 17.4694 29.9554Z"
                fill="#1A2D58" />
            </svg>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="tile" color="primary">Sair</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </template>
    </v-navigation-drawer>
    <v-main class="white"
      style="margin-top: 60px; max-width: 1380px; padding: 0;max-height: 90vh;overflow-y: auto;overflow-x: hidden;">
      <slot name="content"></slot>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import userMixin from "@/mixins/userMixin";
export default {

  name: "internalLayout",
  props: {
    clickBusi: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [userMixin],
  data() {
    return {
      tabs: null,
      infoUserData: false,
      drawerData: false,
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      userId: localStorage.getItem("userId"),
      token: localStorage.getItem("token"),
      drawer: false,
      group: null,
      teste: false,
    };
  },
  methods: {
    closeUserData() {
      this.infoUserData = false;
    },
    finish() {
      this.$router.push({
        name: "HomePage",
      });
    },
    logout() {
      localStorage.clear();
      this.$router.replace("/");
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>

<style scoped>
.v-input__slot {
  padding: 0;
}

.v-slide-group__content,
.v-tabs-bar__content {
  display: flex !important;
  justify-content: center !important;
}

/* .tile:hover {
  color: #14aa95
} */

.v-list-item {
  margin-bottom: 0px;
}

.list-item {
  cursor: pointer;
}

.v-list-item--active svg path {
  fill: #14AA95
}

.adjustInternal:hover svg path {
  fill: #14AA95;
  /* Substitua 'yourNewColor' pela cor desejada */
}

.adjustInternal:hover .tile {
  color: #14AA95;
}
</style>