<template>
    <v-main style="background: rgb(249 250 251); height: 100%;">
        <v-container class="custom-container" style="height: 100%; padding: 0;">
            <v-main style="height: 100%">
                <v-app-bar class="custom-container2" fixed color="#DF6E8B" style="height: 15px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;">
    
</v-app-bar>
                <v-row style="height:100%;">
                    <v-col cols="12" md="7" class="primary d-flex flex-column justify-center position-relative">
                        <div class="d-flex justify-end">
                            <v-img class="gifLogo" src="@/assets/gif_banca.gif"></v-img>
                        </div>
                        <!-- Texto de background -->
                        <div class="col-9">
                            <div class="d-flex align-start h-100 flex-column pl-16 mt-16" style="position: relative;">
                            <v-img class="mb-2" max-width="250" src="@/assets/banca_etica.png"></v-img>
                            <h3 class="pt-5 text-h4 font-weight-medium white--text">Olá, <br /> que bom ver você aqui.</h3>
                            <p style="font-size: 1.2rem;" class="mt-5 font-weight-light white--text mb-3">Aqui, na plataforma de investimento da BELAT, você pode conectar seus investimentos com seus valores, e gerar um impacto socioambiental positivo em qualquer operação de sua escolha.
                                <br>Impacto é uma questão de escolha. Venha conosco e faça a sua</p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="5" lx="5" class="white"
                        style="z-index: 10; display: flex; justify-content: center; align-items: center;">
                        <div style="width: 60%">

                            <v-row class="text-center">
                                <v-col cols="12">
                                    <v-icon color="secondary" size="70">
                                        mdi-lock
                                    </v-icon>
                                    <p class="text-center text-h5 primary--text">
                                        Esqueceu sua senha?
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="d-flex justify-center">
                                    <span class="text-center blue-grey--text text--darken-1">
                                        Digite seu endereço de e-mail para receber um link de verificação e redefinir
                                        sua senha
                                    </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-on:keyup.enter="submit" :rules="rulesEmail" type="email"
                                        v-model="email" class="rounded-lg" label="E-mail" hide-details="auto" outlined
                                        dense></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="d-flex justify-center">
                                    <v-hover v-slot="{ hover }">
                                        <v-btn rounded block :class="{ 'on-hover': hover }"
                                            class="pa-6 px-16 transition-fast-in-fast-out"
                                            :color="hover ? 'secondary' : 'primary'" @click="submit" :disabled="disabled">
                                            <span>{{ disabled ? `Poderá reenviar em ${secondsToEnable ? secondsToEnable : 60} segundo(s)` : 'Redefinir senha'}}</span>
                                        </v-btn>
                                    </v-hover>
                                </v-col>

                                <v-col cols="12" class="d-flex justify-center">
                                    <span class="text-center blue-grey--text text--darken-1">
                                        Deseja entrar?
                                        <a class="text-decoration-none secondary--text " href="/">
                                            Clique aqui
                                        </a>
                                    </span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-main>
        </v-container>
    </v-main>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            errorMessageMap: {
                email: "O campo email é obrigatorio",
            },
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            email: null,
            rulesEmail: [
                (v) => this.validateEmail(v) || "E-mail inválido",
            ],
            disabled: false,
            secondsToEnable: 0,
        };
    },
    methods: {
        validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        submit() {
            this.disabled = true;
            let hasError = false;
            const fields = ["email"];
            for (const field of fields) {
                if (!this[field]) {
                    this.$toast.error(this.errorMessageMap[field]);
                    this.disabled = false;
                    hasError = true;
                    break;
                }
            }
            if(hasError) return;

            axios
                .post(this.urlEnv + 'recoveryPassword', {
                    email: this.email,
                })
                .then((response) => {
                    this.$toast.success(response.data.mensagem);
                    const startTime = Date.now();
                    const interval = setInterval(() => {
                        const currentTime = Date.now();
                        const timeRemaining = Math.max(0, Math.round((startTime + 60000 - currentTime) / 1000));
                        this.secondsToEnable = timeRemaining;
                        if (timeRemaining === 0) {
                            clearInterval(interval);
                            this.disabled = false;
                        }
                    }, 1000);
                })
                .catch((err) => {
                    this.disabled = false;
                    if (err.response && err.response.data && err.response.data.mensagem) {
                        this.$toast.error(err.response.data.mensagem);
                    } else {
                        console.error(err);
                        this.$toast.error("As informações não conferem");
                    }
                });
        },
    },
};
</script>

<style scoped>
.custom-container {
    max-width: 1440px;
    margin: 0 auto;
    border: 1px solid #fff;
}

.gifLogo {
    position: absolute;
    top: -30%;
    left: 27%;
    bottom: 2%;
    max-width: 40%;
}

.custom-container2 {
  max-width: 1461px;
  margin: 0 auto;
  border: 1px solid #fff;
}

/*
  @media screen and (max-width: 1366px) {
      .teste {
          bottom: 8%;
      }
  }
  
  @media screen and (max-width: 1350px) {
      .teste {
          left: 15%; 
          bottom: 22%;
          max-width: 659px;
      }
  }
  
  @media screen and (max-width: 1930px) {
      .teste {
          left: 23%;
      }
  }
  
  @media screen and (max-width: 2000px) {
      .teste {
          bottom: 15%;
      }
  }
  */

.v-main__wrap {
    background: rgb(249 250 251) !important;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}
</style>