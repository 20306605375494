import CryptoJS from 'crypto-js';

export default {
    methods: {
        decrypt(encryptedData) {
            const bytes = CryptoJS.AES.decrypt(encryptedData, 'j8zMKJnI3MLiU2KzCgAhsqBvTjFXB6D0rXCq1m7DHEM=');
            return bytes.toString(CryptoJS.enc.Utf8);
        },
        encrypt(data) {
            return CryptoJS.AES.encrypt(data, 'j8zMKJnI3MLiU2KzCgAhsqBvTjFXB6D0rXCq1m7DHEM=').toString();
        },
    }
};
