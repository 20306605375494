<template>
    <v-main style="background: rgb(249 250 251);">

        <div class="d-flex align-center"
            style="width: 100%; justify-content: space-between; background: white; border-bottom: 1px solid #ccc;">
            <div class="">
                <v-btn icon @click="backPage">
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.97601 8L8.76012 14.6328C8.91371 14.783 9 14.9867 9 15.1991C9 15.4115 8.91371 15.6152 8.76012 15.7654C8.60653 15.9156 8.39822 16 8.18101 16C7.9638 16 7.75549 15.9156 7.6019 15.7654L0.240305 8.56633C0.164132 8.49202 0.103697 8.40375 0.0624612 8.30657C0.0212257 8.20939 0 8.10521 0 8C0 7.89479 0.0212257 7.79061 0.0624612 7.69343C0.103697 7.59625 0.164132 7.50798 0.240305 7.43367L7.6019 0.234581C7.75549 0.0843811 7.9638 0 8.18101 0C8.39822 0 8.60653 0.0843811 8.76012 0.234581C8.91371 0.38478 9 0.588495 9 0.800909C9 1.01332 8.91371 1.21704 8.76012 1.36724L1.97601 8Z"
                            fill="#032A5A" />
                    </svg>
                </v-btn>
            </div>
            <div class="my-5 mx-5">
                <svg width="124" height="40" viewBox="0 0 124 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M47.7651 21.5957C48.9982 20.6614 49.5987 19.2813 49.5987 17.3862C49.62 15.7937 49.0033 14.249 47.9034 13.1608C46.8722 12.1363 45.5116 11.5842 44.0767 11.6108H35.2169C34.8343 11.6108 34.6112 11.8125 34.5898 12.1788V32.2866C34.5898 32.7006 34.8132 32.929 35.2169 32.929H44.826C44.8634 32.929 44.9005 32.929 44.9376 32.929C46.3567 32.929 47.8076 32.2866 48.9449 31.1506C50.1302 29.9668 50.7891 28.4328 50.752 26.9463C50.752 24.7169 49.5349 22.5512 47.7705 21.5904L47.7651 21.5957ZM37.6141 23.4377H44.3742C45.1927 23.4217 46.0645 23.8039 46.7127 24.4515C47.3611 25.1045 47.7067 25.9645 47.68 26.8775C47.68 28.4275 46.5851 29.9934 44.4911 29.9934H37.6192V23.4377H37.6141ZM43.4282 20.4544H37.6141V14.5463H43.4549C45.4531 14.5463 46.6011 15.6079 46.6011 17.4552C46.6011 19.3025 45.416 20.4544 43.4282 20.4544Z"
                        fill="#032A5A" />
                    <path
                        d="M69.7521 11.6108H55.8966C55.4926 11.6108 55.2695 11.8337 55.2695 12.2372V32.2866C55.2695 32.7006 55.4926 32.929 55.8966 32.929H69.7361C70.1722 32.929 70.4113 32.7006 70.4113 32.2866V30.4871C70.4113 30.0836 70.1722 29.8608 69.7361 29.8608H58.2935V23.2147H67.1266C67.5837 23.2147 67.8441 22.9864 67.8441 22.5883V20.8472C67.8441 20.4438 67.5784 20.2049 67.1266 20.2049H58.2935V14.6524H69.7521C70.1879 14.6524 70.4273 14.4242 70.4273 14.0101V12.2425C70.4273 11.839 70.1879 11.6161 69.7521 11.6161V11.6108Z"
                        fill="#032A5A" />
                    <path
                        d="M87.7321 31.0444H77.3257V12.6406C77.3257 11.9027 76.8528 11.6373 76.4435 11.6373C75.9172 11.6373 75.5612 12.0408 75.5612 12.6406V31.9893C75.5612 32.5572 75.9172 32.9341 76.4435 32.9341H87.7321C87.9872 32.9341 88.205 32.8599 88.3856 32.7059C88.5345 32.5839 88.7098 32.3555 88.7098 31.9576C88.7098 31.5327 88.4547 31.039 87.7321 31.039V31.0444Z"
                        fill="#032A5A" />
                    <path
                        d="M100.115 12.2902L100.078 12.2C99.9985 12.0248 99.892 11.8921 99.7591 11.8072C99.6158 11.7063 99.4562 11.6533 99.2969 11.6533H99.164C98.9727 11.6798 98.6218 11.7966 98.4251 12.2849L90.8038 31.6019C90.7507 31.7345 90.724 31.8778 90.724 32.0264C90.724 32.3024 90.8305 32.5466 91.0218 32.7112C91.1865 32.8545 91.3938 32.929 91.6223 32.929H91.665C91.8243 32.929 92.2176 32.8705 92.4303 32.3077L94.4498 27.1852H104.08L106.1 32.3237C106.318 32.8705 106.716 32.9341 106.876 32.9341H106.913C107.131 32.9341 107.343 32.8439 107.519 32.6795C107.71 32.4988 107.811 32.2653 107.811 32.0054C107.811 31.8778 107.784 31.7452 107.731 31.6125L100.121 12.2902H100.115ZM103.336 25.3115H95.1778L99.2649 14.9338L103.331 25.3115H103.336Z"
                        fill="#032A5A" />
                    <path
                        d="M122.347 11.6373H108.746C108.21 11.6373 107.848 12.0195 107.848 12.5822C107.848 13.1449 108.21 13.5271 108.746 13.5271H114.678V31.984C114.678 32.5519 115.044 32.929 115.586 32.929C116.129 32.929 116.458 32.5466 116.458 31.984V13.5271H122.347C122.868 13.5271 123.219 13.1396 123.219 12.5663C123.219 11.993 122.878 11.632 122.347 11.632V11.6373Z"
                        fill="#032A5A" />
                    <path
                        d="M0.171563 0.155659C0.442616 -0.120372 0.85185 0.054802 0.85185 0.054802L26.5965 9.63625C26.5965 9.63625 26.9792 9.71589 27.0323 10.0981C27.0854 10.4803 26.8249 10.6926 26.8249 10.6926L11.2581 24.9188C11.2581 24.9188 11.0083 25.1789 10.6682 25.1099C10.328 25.0462 10.2218 24.68 10.2218 24.68L0.028067 0.851045C0.028067 0.851045 -0.0941738 0.426382 0.171563 0.155659Z"
                        fill="#D64D7A" />
                    <path
                        d="M24.4652 16.0115C24.2845 15.6983 23.9338 15.693 23.9338 15.693L3.88658 13.1397L3.87593 13.1185L3.22753 13.0336C3.22753 13.0336 2.81298 12.9911 2.61634 13.2512C2.4197 13.5113 2.54194 13.9147 2.54194 13.9147L11.8162 39.575C11.8162 39.575 11.9969 40.0156 12.3742 39.9996C12.7569 39.9838 12.9429 39.6281 12.9429 39.6281L24.428 16.6114C24.428 16.6114 24.6353 16.3247 24.4546 16.0115H24.4652Z"
                        fill="#14AA95" />
                    <path
                        d="M5.36937 13.3255L10.2324 24.6853C10.2324 24.6853 10.3387 25.0462 10.6788 25.1152C11.0189 25.1789 11.2687 24.9241 11.2687 24.9241L21.6856 15.4064L5.3747 13.3255H5.36937Z"
                        fill="#032A5A" />
                </svg>

            </div>
        </div>
        <v-col cols="12" xs="12" sm="12" md="5" lx="5" class="white"
            style="z-index: 10; display: flex; justify-content: center; align-items: center;">
            <div style="width: 75%">

                <v-row class="text-center">
                    <v-col cols="12">
                        <p class="text-center text-h5 primary--text">
                            Digite a nova senha
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                        <span class="text-left blue-grey--text text--darken-1">
                            <p class="mb-2" style="font-size: 14px;">
                                Digite sua nova senha. Lembre-se de que ela deve ter:
                            </p>
                            <ul>
                                <li class="text-left primary--text" style="font-size: 14px;">Um minimo de 8
                                    caracteres</li>
                                <li class="text-left primary--text" style="font-size: 14px;">Incluir pelo menos um
                                    caractere especial</li>
                                <li class="text-left primary--text" style="font-size: 14px;">Conter uma letra
                                    maiúscula</li>
                            </ul>
                        </span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-on:keyup.enter="submit" :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPass1 = !showPass1" :type="showPass1 ? 'text' : 'password'"
                            class="rounded-lg" label="Digite sua nova senha" :rules="rules" hide-details="auto"
                            name="password" v-model="password" outlined dense>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-on:keyup.enter="submit" :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPass2 = !showPass2" :type="showPass2 ? 'text' : 'password'"
                            class="rounded-lg" label="Digite novamente sua senha" :rules="rulesConfirm"
                            hide-details="auto" name="confirmPassword" v-model="confirmPassword" outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-dialog v-model="dialog" persistent max-width="300">
                    <v-card class="pa-5">
                        <v-card-text class="text-center primary--text" style="font-size: 15px;">Parabéns! Sua senha foi alterada com sucesso. Clique em continuar para fazer
                            login</v-card-text>

                        <v-spacer></v-spacer>
                        <v-btn rounded block color="primary" @click="backPage">
                            Fazer login
                        </v-btn>

                    </v-card>
                </v-dialog>
                <v-row>
                    <v-col cols="12" class="d-flex justify-center mt-8">
                        <v-hover v-slot="{ hover }">
                            <v-btn rounded block :class="{ 'on-hover': hover }"
                                class="pa-6 px-16 transition-fast-in-fast-out" :color="hover ? 'secondary' : 'primary'"
                                @click="submit">
                                <span>Atualização</span>
                            </v-btn>
                        </v-hover>
                    </v-col>
                </v-row>
            </div>
            <v-app-bar class="custom-container2" fixed color="#DF6E8B"
                style="height: 15px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;">

            </v-app-bar>
        </v-col>
    </v-main>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            dialog: false,
            errorMessageMap: {
                password: "O campo senha é obrigatorio",
                confirmPassword: "O campo confirmação de senha é obrigatório"
            },
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            password: null,
            confirmPassword: null,
            showPass1: false,
            showPass2: false,
            rules: [
                (v) => !!v || "Campo obrigatório",
                (v) =>
                    (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
                (v) =>
                    (v && /[A-Z]/.test(v)) ||
                    "A senha deve conter pelo menos uma letra maiúscula",
                (v) =>
                    (v && /[a-z]/.test(v)) ||
                    "A senha deve conter pelo menos uma letra minúscula",
                (v) =>
                    (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
            ],
            rulesConfirm: [
                (v) => !!v || "Campo obrigatório",
                (v) => v === this.password || "As senhas não coincidem"
            ]
        };
    },
    created() {
        if (!this.$route.params.token || !this.$route.params.email) {
            this.$router.push('/');
        }
    },
    methods: {
        backPage() {
            localStorage.clear()
            this.$router.push("/mobile/home")
        },
        submit() {
            const token = this.$route.params.token;
            const email = this.$route.params.email;
            const fields = ["password", "confirmPassword"];
            for (const field of fields) {
                if (!this[field]) {
                    this.$toast.error(this.errorMessageMap[field]);
                    return;
                }
            }
            axios
                .post(this.urlEnv + `resetPassword/${token}`, {
                    email: email,
                    password: this.password,
                    password_confirmation: this.confirmPassword
                })
                .then((response) => {
                    this.$toast.success(response.data.mensagem);
                    // this.$router.push('/');
                    this.dialog = true
                })
                .catch((err) => {
                    if (err.response && err.response.data && err.response.data.mensagem) {
                        this.$toast.error(err.response.data.mensagem);
                    } else {
                        console.error(err);
                        this.$toast.error("Token expirado");
                    }
                });
        },
    },
};
</script>

<style scoped>
.custom-container {
    max-width: 1440px;
    margin: 0 auto;
    border: 1px solid #fff;
}

.gifLogo {
    position: absolute;
    top: -30%;
    left: 27%;
    bottom: 2%;
    max-width: 40%;
}

.custom-container2 {
    max-width: 1461px;
    margin: 0 auto;
    border: 1px solid #fff;
}

/*
  @media screen and (max-width: 1366px) {
      .teste {
          bottom: 8%;
      }
  }
  
  @media screen and (max-width: 1350px) {
      .teste {
          left: 15%; 
          bottom: 22%;
          max-width: 659px;
      }
  }
  
  @media screen and (max-width: 1930px) {
      .teste {
          left: 23%;
      }
  }
  
  @media screen and (max-width: 2000px) {
      .teste {
          bottom: 15%;
      }
  }
  */

.v-main__wrap {
    background: rgb(249 250 251) !important;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}
</style>