<template>
    <v-card flat>
    <v-card-text class="py-0">
      <v-row class="mt-2">
        <v-col cols="6" >
          <span class="mt-xl-2 primary--text text-overline">
            {{ $t('impact') }}
          </span>
          <p class="mt-2 primary--text">{{ negocio.impacto }}</p>
          <span class="mt-xl-2 primary--text text-overline">
            Boas Práticas
          </span>
          <p class="mt-2 primary--text">{{ negocio.boas_praticas }}</p>
        </v-col>
        <v-col cols="6" >
          <span class="mt-xl-2 primary--text text-overline">
            {{ $t('we_support') }}
          </span>
          <p class="mt-2 primary--text">{{ negocio.pq_apoiamos }}</p>
          <v-row>
            <v-col class="ma-0 pa-1" cols="3" v-for="ods in negocio.ods" :key="ods.id">
            <v-img :src="ods.imagem.content"></v-img>
          </v-col>
        </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
  
  <script>
  export default {
    props: {
      negocio: Object
    }
  };
  </script>
  