<template>
  <div class="d-flex flex-column align-center">
    <div style="width: 80%">
      <v-row class="pt-8">
        <v-col cols="12" md="4">
          <BankSelector
            :disabled="!editing"
            label="Número do banco"
            v-model="titularUser.banco"
            :items="getSelectItems({ text: 'Número do banco' })"
          />
        </v-col>
        <v-col cols="6" sm="3">
          <v-text-field
            class="rounded-lg"
            :label="$t('branch')"
            v-mask="'####'"
            hide-details="auto"
            v-model="titularUser.agencia"
            outlined
            dense
            :rules="[maxLengthRule(4)]"
            :disabled="!editing"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="3">
          <v-text-field
            class="rounded-lg"
            :label="$t('checking_account')"
            hide-details="auto"
            v-model="titularUser.conta_corrente"
            outlined
            dense
            :rules="[maxLengthRule(16)]"
            :disabled="!editing"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <AccountTypeSelector
            :items="getSelectItems({ text: 'Tipo de conta' })"
            label="Tipo de conta"
            v-model="titularAll"
            :disabled="!editing"
          />
        </v-col>
      </v-row>
    </div>
    <v-col cols="12" class="d-flex justify-center">
      <v-hover v-slot="{ hover }">
        <v-btn
          :class="{ 'on-hover': hover }"
          class="transition-fast-in-fast-out mr-2"
          :color="editing ? 'white' : 'button'"
          @click="editing = !editing"
          :dark="!editing"
          rounded
        >
          {{ editing ? 'Cancelar' : 'Editar' }}
        </v-btn>
      </v-hover>
      <v-hover v-slot="{ hover }" v-if="editing">
        <v-btn
          :class="{ 'on-hover': hover }"
          class="transition-fast-in-fast-out"
          color="primary"
          @click="updateTitular"
          rounded
        >
          <span>{{ $t('confirm') }}</span>
        </v-btn>
      </v-hover>
    </v-col>
  </div>
</template>

<script>
import BankSelector from '../Components/BankSelector.vue';
import AccountTypeSelector from '../Components/AccountTypeSelector.vue';
import userMixin from "@/mixins/userMixin";
import dataGetMixin from "@/mixins/dataGetMixin";
import axios from "axios";

export default {
  data() {
    return {
      conta: null,
      userId: localStorage.getItem("userId"),
      titularUser: {},
      titularAll: {},
      password: null,
      showPass1: false,
      confirmPass1: false,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      editing: false,
    };
  },
  components: {
    BankSelector,
    AccountTypeSelector,
  },
  mixins: [userMixin, dataGetMixin],
  methods: {
    getSelectItems(question) {
      const mappings = {
        'Tipo de conta': { array: this.tipoContaBancaria, idKey: 'id', nomeKey: 'nome' },
        'Número do banco': { array: this.bancos, bothKey: 'both', key: 'codigo' },
      };

      const mapping = mappings[question.text];
      if (mapping && mapping.array) {
        return mapping.array.map(item => ({
          id: item[mapping.idKey],
          codigo: item[mapping.key],
          nome: item[mapping.nomeKey],
          both: `${item.codigo}:${item.nome}`
        }));
      } else {
        return [];
      }
    },
    maxLengthRule(maxLength) {
      return (v) => {
        if (v && v.length <= maxLength) {
          return true;
        } else {
          return `Este campo deve ter no máximo ${maxLength} caracteres`;
        }
      };
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    updateTitular() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };

      const bancoId = this.titularUser?.banco?.codigo ? this.titularUser.banco.codigo : (this.titularUser?.banco?.includes(':') ? this.titularUser.banco.split(':')[0] : '') || '';
      const data = {
        NumeroDoBanco: bancoId,
        NumeroDaAgenciaBancaria: this.titularUser.agencia,
        NumeroDaContaCorrenteBancaria: this.titularUser.conta_corrente,
        TipoDeContaBancaria: this.titularAll
      };

      try {
        axios
          .post(this.urlEnv + "updateInvestidor", data, config)
          .then((response) => {
            this.$toast.success('Titular Atualizado')
            this.editing = !this.editing;
            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getBancos();
    this.getTiposConta();
    this.getUserData();
    this.getMeusDados(); 
  },
};
</script>
