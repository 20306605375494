<template>
  <div>
    <div class="py-6 position-relative">
      <NavigationIcon @click="navigateBack" />
      <div class="text-center title">{{ sectionTitle }}</div>
    </div>
    <SectionContainer :section="section" />
  </div>
</template>

<script>
import NavigationIcon from "../Components/NavigationIcon.vue";
import SectionContainer from "../Components/SectionContainer.vue";

export default {
  props: ['section'],
  components: {
    NavigationIcon,
    SectionContainer
  },
  computed: {
    sectionTitle() {
      const titles = {
        'dados-pessoais': 'Dados Pessoais',
        'informacoes-adicionais': 'Informações Adicionais',
        'endereco': 'Endereço',
        'dados-bancarios': 'Dados Bancários',
        'meus-documentos': 'Meus Documentos'
      };
      return titles[this.section] || 'Informações';
    }
  },
  methods: {
    navigateBack() {
      this.$router.go(-1); // Navega para a página anterior
    }
  }
};
</script>

<style scoped>
.title {
  color: #032A5A;
  font-size: 18px;
  font-weight: 700;
}
.position-relative {
  position: relative;
}
</style>
