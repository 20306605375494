<template>
  <div>
    <v-dialog max-width="500" v-model="dialog">
      <ModalCPF @close="close" />
    </v-dialog>
    <layout>
      <template v-slot:content>
        <v-container class="custom-container mt-16" fluid>
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="6">
              <FormCard
                title="Seu login foi criado"
                description="Para começar a investir, precisamos que você preencha alguns dados:"
                inputLabel="CPF"
                buttonText="Confirmar"
                :inputValue="titularUser.cnpj_cpf"
                :inputDisabled="titularUser.cnpj_cpf !== undefined && titularUser.cnpj_cpf == 15"
                @action="addTitular"
                @show-modal="dialog = true"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </layout>
  </div>
</template>

<script>
import ModalCPF from "./Modal/ModalCPF.vue";
import layout from "@/layouts/internalLayout.vue";
import FormCard from "./Components/FormCard.vue";
import validateCPF from "@/mixins/validateCPF";
import investorMixin from "@/mixins/investorMixin";
import apiService from "@/services/apiService";

export default {
  components: {
    layout,
    ModalCPF,
    FormCard,
  },
  mixins: [validateCPF, investorMixin],
  data() {
    return {
      userId: localStorage.getItem("userId"),
      dialog: true,
      token: localStorage.getItem("token"),
      titularUser: {},
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    async addTitular(new_cnpj_cpf) {
      this.titularUser.cnpj_cpf = new_cnpj_cpf;
      const cpf = this.removeNonNumeric(this.titularUser.cnpj_cpf);
      if (!this.validateCPF(cpf)) {
        this.$toast.error("Digite um CPF Válido");
        return;
      }

      const data = {
        CPF: cpf,
      };

      try {
        await apiService.updateInvestidor(this.token, data);
        this.$toast.success("Iniciando investimento");
        this.$router.push({ name: "templateRegister" });
      } catch (error) {
        if (error.response) {
          const errorCPF = error.response.data.mensagem;
          this.$toast.error(errorCPF);
        } else {
          console.error(error);
        }
      }
    },
  },
  mounted() {
    this.getInvestidor();
  },
};
</script>
