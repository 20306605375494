<template>
  <div>
    <div class="d-flex flex-column align-center w-100">
      <v-row style="width: 80%;" class="pt-6">
        <v-col cols="12" md="4">
          <UserPositionField v-model="user.position" :editing="editing" />
        </v-col>
        <v-col cols="12" md="4">
          <UserPatrimonioField v-model="user.patrimonio" :editing="editing" />
        </v-col>
        <v-col cols="12" md="4">
          <UserSalaryField v-model="user.salary" :editing="editing" />
        </v-col>
        <v-col cols="12" md="6">
          <UserEmailField v-model="user.email" :editing="editing" />
        </v-col>
        <v-col cols="12" md="4">
          <UserPhoneField v-model="user.phone" :editing="editing" />
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <EditConfirmButton :editing="editing" @toggleEdit="toggleEditing" @updateTitular="updateTitular" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import UserPositionField from '../Components/UserPositionField.vue';
import UserPatrimonioField from '../Components/UserPatrimonioField.vue';
import UserSalaryField from '../Components/UserSalaryField.vue';
import UserEmailField from '../Components/UserEmailField.vue';
import UserPhoneField from '../Components/UserPhoneField.vue';
import EditConfirmButton from '../Components/EditConfirmButton.vue';
import axios from "axios";

export default {
  props: {
    phone: String,
  },
  data() {
    return {
      editing: false,
      user: {
        position: '',
        patrimonio: '',
        salary: '',
        email: '',
        phone: this.phone
      },
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL
    };
  },
  components: {
    UserPositionField,
    UserPatrimonioField,
    UserSalaryField,
    UserEmailField,
    UserPhoneField,
    EditConfirmButton
  },
  methods: {
    toggleEditing() {
      this.editing = !this.editing;
    },
    async getMeusDados() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        axios
          .get(this.urlEnv + `getMeusDados`, config)
          .then((response) => {
            this.user.position = response.data.data.dados_profissionais.cargo;
            this.user.patrimonio = response.data.data.dados_pessoais.valor_do_patrimonio;
            this.user.salary = response.data.data.dados_profissionais.salario;
            this.user.email = response.data.data.acesso.login;
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    updateTitular() {
      const formatter = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      const salario = formatter.format(parseFloat(this.user.salary.replace(/[^\d,]/g, '').replace(',', '.'))).replaceAll(',', '')
      const patrimonio = formatter.format(parseFloat(this.user.patrimonio.replace(/[^\d,]/g, '').replace(',', '.'))).replaceAll(',', '')
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      const data = {
        Salario: salario,
        ValorDoPatrimonio: patrimonio,
        Cargo: this.user.position,
        Telefone: this.user.phone,
      };

      try {
        axios
          .post(this.urlEnv + "updateInvestidor", data, config)
          .then(() => {
            this.$toast.success('Titular Atualizado')
            this.editing = !this.editing;
          })
          .catch((err) => {
            console.log(err);
            this.editing = !this.editing;
          });
      } catch (error) {
        console.log(error);
        this.editing = !this.editing;
      }
    },
  },
  mounted() {
    this.getMeusDados();
  },
};
</script>
