<template>
  <layout>
    <template v-slot:content>
      <div class="d-flex flex-column items-center primary">
        <UserProfileHeader :user="user" />

        <div class="d-flex align-start pa-4 px-16">
          <div class="d-flex flex-column" style="width:40%">
            <b class="white--text font-weight-medium" style="font-family: 'Raleway';"> E-mail</b>
            <v-text-field append-icon="mdi-alert-circle-outline" outlined dark v-model="user.email" class="w-full" disabled></v-text-field>
          </div>
        </div>
      </div>

      <div class="mt-0">
        <v-tabs fixed-tabs v-model="tabs" color="secondary" centered>
          <v-tab v-for="n in 5" :key="n">
            {{ tabNames[n - 1] }}
          </v-tab>
        </v-tabs>
      </div>

      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-card flat class="d-flex flex-column align-center w-100">
            <v-card-text style="width: 80%">
              <PersonalInfo />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <JobPage :phone="user.phone_number" />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <AddressPage />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <AccountDetail />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <MyDocs />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </layout>
</template>

<script>
import layout from "@/layouts/internalLayout.vue";
import AccessPage from "./Pages/AccessPage.vue";
import PersonalInfo from "./Pages/PersonalInfo.vue";
import MyDocs from "./Pages/MyDocs.vue";
import AccountDetail from "./Pages/AccountDetail.vue";
import JobPage from "./Pages/JobPage.vue";
import AddressPage from "./Pages/AddressPage.vue";
import UserProfileHeader from "./Components/UserProfileHeader.vue"; 
import userMixin from "@/mixins/userMixin";

export default {
  metaInfo() {
    return {
      title: 'BELat - Minhas Informações'
    }
  },
  components: {
    AddressPage,
    PersonalInfo,
    JobPage,
    AccountDetail,
    layout,
    MyDocs,
    UserProfileHeader,
  },
  mixins: [userMixin],
  data() {
    return {
      tabs: this.$route.query.renderOnTabFour ? 4 : null,
      tabNames: ['Dados pessoais', 'Informações adicionais', 'Endereço', 'Dados bancários', 'Meus documentos'],
      userId: localStorage.getItem("userId"),
      screens: [
        AccessPage,
        PersonalInfo,
        AccountDetail,
        JobPage,
        AddressPage,
      ],
      currentScreenIndex: 0,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      items: [
        {
          title: "Meus Investimentos",
          icon: "mdi-home-city",
          route: "/user/MyInvestiments",
        },
        { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
      ],
      right: null,
    };
  },
  mounted() {
    this.checkUser();
    this.getUserData();
  },
  computed: {
    currentScreen() {
      return this.screens[this.currentScreenIndex];
    },
    totalScreens() {
      return this.screens.length;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer__border {
  display: none;
}
</style>
