import { render, staticRenderFns } from "./InvestmentSummary.vue?vue&type=template&id=9fb0ca86&scoped=true"
import script from "./InvestmentSummary.vue?vue&type=script&lang=js"
export * from "./InvestmentSummary.vue?vue&type=script&lang=js"
import style0 from "./InvestmentSummary.vue?vue&type=style&index=0&id=9fb0ca86&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fb0ca86",
  null
  
)

export default component.exports