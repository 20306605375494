<template>
    <div class="d-flex justify-center align-center flex-column" style="height: 100%;">
      <div class="imageVcard" style="border-radius: 50%; width: 150px; height: 150px; background: white;">
        <v-img style="border-radius: 20%;" :src="imageSrc"></v-img>
      </div>
      <v-card class="py-lg-7 px-lg-10 px-10 mx-16" style="border-radius: 10%;">
        <v-card-text class="primary--text">
          "{{ profileText }}"
        </v-card-text>
        <v-card-text class="primary--text font-weight-bold">
          {{ detailedProfileText }}
        </v-card-text>
      </v-card>
      <p class="primary--text text-xl-h6 font-weight-bold pa-lg-2 pa-xl-5 mt-3">{{ footerText }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      imageSrc: {
        type: String,
        required: true
      },
      profileText: {
        type: String,
        required: true
      },
      detailedProfileText: {
        type: String,
        required: true
      },
      footerText: {
        type: String,
        required: true
      }
    }
  }
  </script>
  