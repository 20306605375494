import VueI18n from 'vue-i18n'
import Vue from "vue";


Vue.use(VueI18n)

function loadLocaleMessage() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if(matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

const userLanguage = navigator.language || navigator.userLanguage;

let defaultLocale = 'pt_br';
if (userLanguage.startsWith('en')) {
    // defaultLocale = 'en';
} else if (userLanguage.startsWith('es')) {
    defaultLocale = 'es';
}

export default new VueI18n({
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    messages: loadLocaleMessage()
})