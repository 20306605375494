<template>
    <div>
        <v-row class="text-center">
            <v-col cols="12">
                <p class="text-center text-h4 font-weight-medium primary--text">
                    Acesse sua conta
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field v-on:keyup.enter="$emit('submit')" type="email" v-model="localEmail" class="rounded-lg"
                    label="Usuário" hide-details="auto" outlined dense></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field v-on:keyup.enter="$emit('submit')" :append-icon="localShowPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="togglePasswordVisibility" :type="localShowPass1 ? 'text' : 'password'" class="rounded-lg"
                    label="Senha" hide-details="auto" name="password" v-model="localPassword" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-checkbox>
                    <template v-slot:label>
                        <div>
                            <h5 class="primary--text">
                                Lembre-se de mim
                            </h5>
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="d-flex justify-center">
                <v-hover v-slot="{ hover }">
                    <v-btn rounded block :class="{ 'on-hover': hover }" class="pa-6 px-16 transition-fast-in-fast-out"
                        :color="hover ? 'secondary' : 'primary'" @click="$emit('submit')" :disabled="disabled">
                        <span>Login</span>
                    </v-btn>
                </v-hover>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
                <span class="text-center blue-grey--text text--darken-1">
                    <a class="text-decoration-none" href="/forgotPassword">
                        Esqueceu sua senha?
                    </a>
                </span>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
                <v-hover v-slot="{ hover }">
                    <v-btn rounded :class="{ 'on-hover': hover }" class="pa-6 px-16 transition-fast-in-fast-out"
                        :color="hover ? 'primary' : 'secondary'" @click="$emit('sign-up')">
                        <span>Criar uma conta</span>
                    </v-btn>
                </v-hover>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        email: String,
        password: String,
        showPass1: Boolean,
        disabled: Boolean
    },
    data() {
        return {
            localEmail: this.email,
            localPassword: this.password,
            localShowPass1: this.showPass1
        };
    },
    watch: {
        localEmail(newVal) {
            this.$emit('update:email', newVal);
        },
        localPassword(newVal) {
            this.$emit('update:password', newVal);
        },
        localShowPass1(newVal) {
            this.$emit('update:showPass1', newVal);
        }
    },
    methods: {
        togglePasswordVisibility() {
            this.localShowPass1 = !this.localShowPass1;
        }
    }
};
</script>
