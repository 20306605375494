<template>
  <div class="custom-container">
      <v-app-bar fixed color="#D64D7A"
          style="height: 10px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;">
      </v-app-bar>
    <v-row class="d-flex" style="padding: 0; height: 100vh;">
      <v-col cols="8" class="col-xl-7" style="background-color: #00ac95; padding: 0;">
        <v-img  class="gifLogo" src="@/assets/gif_banca_new.gif"></v-img>
        <div class="mx-lg-16 px-16 mt-16">
          <div style="width: 100px; height: 100px; background-color: #D64D7A; border-radius: 50%; display: flex; justify-content: center; align-items: center;">
            <v-icon size="70" color="white">mdi-party-popper</v-icon>
          </div>
        </div>
        <h1 class="mx-lg-16 text-lg-h3 mt-lg-10 white--text font-weight-bold px-lg-16" style="z-index: 10; position: relative">Parabéns, <br>
          você concluiu <br>
          seu cadastro.</h1>
          <p class="mt-5 white--text text-lg-h6 px-lg-16 mx-lg-16" style="position: relative; z-index: 10">Agora, comece a investir nas <br> empresas que estão transformando <br> o Meio Ambiente, o Desenvolvimento <br> Social e a Educação e Cultura em <br> nosso país! </p>
          <div class="mx-lg-16 px-lg-16">
            <v-btn rounded class="mt-lg-5 px-16  mt-xl-16" dark color="button" @click="finishStage">Minha Conta</v-btn>
          </div>
      </v-col>
      <v-col cols="4" class="d-flex align-center col-xl-5" style="background-color: white; z-index: 10; height: 100vh; ">
        <v-row class="d-flex justify-center">
                <div class="">
                    <TimelineVertical :items="currentTimeline" :activeIndex="currentScreenIndex" :completedIndexes="completedIndexes" />
                </div>
            </v-row>
      </v-col>

    </v-row>

  </div>
  <!-- </v-main> -->
</template>

<script>
import TimelineVertical from "../Components/TimelineVertical.vue"
export default {
  props: {
    currentPosition: Number,
    currentTimeline: Array,
    currentScreenIndex: Number,
  },
  components: {
    TimelineVertical
  },
  data() {
    return {
      activeIndex: 0,
            completedIndexes: [0,1,2,3],
      currentStage: 0,
      userId: localStorage.getItem("userId"),
      cnpj_cpf: null,
      dialog: true,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      titularUser: {},
    }
  },
  mounted() {
  },
  methods: {
    finishStage() {
      this.$router.push({
        name: "HomePage"
      });
      // console.log(this.stages[this.currentStage].questions)
    },
    getColor(position) {
      return position <= this.currentScreenIndex ? 'secondary' : 'gray'; // Retorna 'secondary' se a posição for menor ou igual a currentScreenIndex
    },
  },

}
</script>

<style scoped>
.timeline-container {
  display: block;
  /* Exibir os itens do timeline em uma única coluna */
}

.custom-container {
  /* max-width: 1440px; */
  margin: 0 auto;
  padding: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.secondary--text,
.d-flex {
  font-family: 'Raleway', cursive !important;
}

.gifLogo {
  position: absolute;
  top: -30%;
  left: 27%;
  bottom: 2%;
  max-width: 100%;
}

@media (min-width: 2560px) {}

@media (min-width: 1920px) and (max-width: 2559px) {}

@media (min-width: 1280px) and (max-width: 1919px) {
  .v-timeline--dense .v-timeline-item {
    padding: 0 !important;
  }
}

@media (max-width: 1279px) {}

.v-main__wrap {
  background: rgb(249 250 251) !important;
}

.theme--light.v-timeline::before {
  background: #14aa95 !important;
}

.oioi {
  background-image: url("@/assets/imageHand.png");
}

.gifLogo {
  z-index: 1;
  position: absolute;
  top: -20%;
  left: 35%;
  bottom: 2%;
  max-width: 40%;
}
</style>