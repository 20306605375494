<template>
    <div class="d-flex justify-center">
        <v-btn v-if="currentQuestionIndex === 0" class="mr-10 px-10 primary--text" style="border: 1px solid #00265b"
            rounded @click="backPage">
            Voltar
        </v-btn>
        <v-btn v-if="currentQuestionIndex > 0" class="mr-10 px-10 primary--text" style="border: 1px solid #00265b"
            rounded @click="goBack">
            {{ $t("back") }}
        </v-btn>
        <v-btn class="px-12" dark rounded color="primary" :disabled="isDisabled" @click="advance">
            {{ currentQuestionIndex < totalQuestions - 1 ? $t("advance") : $t("finish") }} </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        currentQuestionIndex: Number,
        totalQuestions: Number,
        isDisabled: Boolean,
    },
    methods: {
        backPage() {
            this.$emit("back-page");
        },
        goBack() {
            this.$emit("go-back");
        },
        advance() {
            this.$emit("advance");
        },
    },
};
</script>