<template>
    <v-card class="pa-8">
      <v-card-title class="d-flex justify-center">
        <h2 class="font-weight-regular">{{ title }}</h2>
      </v-card-title>
      <v-card-text class="text-center">
        <p class="font-weight-regular">
          {{ description }}
        </p>
      </v-card-text>
      <v-card-text class="text-center">
        <v-text-field
          v-mask="'###.###.###-##'"
          outlined
          :label="inputLabel"
          v-on:keyup.enter="onAction"
          append-icon="mdi-help-circle-outline"
          @click:append="showModal"
          v-model="value"
          :disabled="inputDisabled"
        ></v-text-field>
      </v-card-text>
      <v-hover v-slot="{ hover }">
        <v-card-actions class="justify-center">
          <ActionButton
            :text="buttonText"
            :color="hover ? 'primary' : 'secondary'"
            :on-click="onAction"
          />
        </v-card-actions>
      </v-hover>
    </v-card>
  </template>
  
  <script>
  import ActionButton from "./ActionButton.vue";
  
  export default {
    components: {
      ActionButton,
    },
    props: {
      title: String,
      description: String,
      inputLabel: String,
      buttonText: String,
      inputValue: String,
      inputDisabled: Boolean,
    },
    data() {
      return {
        value: this.inputValue 
      };
    },
    watch: {
      inputValue(newVal) {
          this.value = newVal;
        }
    },
    methods: {
      onAction() {
        this.$emit("action", this.value);
      },
      showModal() {
        this.$emit("show-modal");
      },
    },
  };
  </script>
  