import layout from "@/layouts/internalLayout.vue";
import TemplateRegister from "./desktop/TemplateRegister.vue";

const routes = {
  component: layout,
  children: [
    {
      name: "templateRegister",
      path: "TemplateRegister",
      component: TemplateRegister,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default routes;
