var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-container"},[_c('v-row',{staticStyle:{"padding":"0","height":"100vh"}},[_c('v-app-bar',{staticStyle:{"z-index":"99"},attrs:{"fixed":"","color":"white"}},[_c('v-img',{attrs:{"max-width":"150","src":require("@/assets/belat.png")}}),_c('v-spacer')],1),_c('v-app-bar',{staticStyle:{"height":"10px","margin-top":"0px","transform":"translateY(0px)","top":"auto !important","left":"0px","right":"0px","z-index":"99","bottom":"0px"},attrs:{"fixed":"","color":"#032A5A"}}),_c('v-col',{staticClass:"mt-12",style:({
        overflowY: 'auto',
        backgroundImage: 'url(' + require('@/assets/belat_app_06.png') + ')',
        backgroundSize: 'contain',
        backgroundSize: '110%',
        backgroundPositionX: '100%',
        padding: '0'
      }),attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-5"},[_c('timeline',{staticClass:"ml-lg-10",attrs:{"items":_vm.currentTimeline,"activeIndex":_vm.currentPosition,"completedIndexes":_vm.completedIndexes},on:{"update:activeIndex":_vm.updateActiveIndex}})],1),_c('div',{staticClass:"ml-lg-10",staticStyle:{"max-width":"50%"}},[_c('h1',{staticClass:"secondary--text text-lg-h5 font-weight-bold"},[_vm._v("DECLARAÇÕES")]),_c('p',{staticClass:"mb-1 mt-5 text-lg-body-1 primary--text"},[_vm._v("Última etapa para poder investir")]),_c('h1',{staticClass:"secondary--text text-lg-h5 font-weight-bold"},[_vm._v("Você está a um passo de poder investir em "),_c('br'),_vm._v(" empresas de impacto positivo! ")]),_c('p',{staticClass:"mb-10 mt-5 primary--text text-lg-body-1 font-weight-light"},[_vm._v("As declarações a seguir são "),_c('br'),_vm._v(" importantes para o adequação do "),_c('br'),_vm._v(" seu perfil à regulamentação vigente. ")]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mr-5 py-5 px-14 py-6",attrs:{"color":"secondary","dark":"","rounded":""},on:{"click":_vm.startQuestions}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Continuar ")])]),_c('v-btn',{staticClass:"py-6",staticStyle:{"border":"1px solid #00000033"},attrs:{"elevation":"0","rounded":""},on:{"click":_vm.redirectHome}},[_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(" Ver investimentos ")])])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }