var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticStyle:{"background":"rgb(249 250 251)","height":"100vh","padding":"0"}},[_c('v-container',{staticClass:"custom-container mt-8",staticStyle:{"border":"0px"},style:({
        display: _vm.currentScreenIndex === 0 ? 'grid' : 'block',
        justifyItems: _vm.currentScreenIndex === 0 ? 'center' : 'initial',
        alignItems: _vm.currentScreenIndex === 0 ? 'center' : 'initial'
    }),attrs:{"fluid":""}},[_c('v-app-bar',{staticStyle:{"z-index":"99"},attrs:{"fixed":"","color":"white"}},[_c('v-img',{attrs:{"max-width":"150","src":require("@/assets/belat.png")}}),_c('v-spacer')],1),_c('v-app-bar',{staticStyle:{"height":"10px","margin-top":"0px","transform":"translateY(0px)","top":"auto !important","left":"0px","right":"0px","z-index":"99","bottom":"0px"},attrs:{"fixed":"","color":"#D64D7A"}}),_c('div',{staticClass:"d-flex flex-column items-center primary",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"px-6 py-10 d-flex align-center",staticStyle:{"border-bottom":"1px solid #14aa95","justify-content":"center"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-h5 font-weight-medium text-center white--text",staticStyle:{"display":"flex","align-items":"center","font-family":"'Raleway'"}},[_vm._v(" Perfil de Impacto ")])])])]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('div',{},[_c('QuestionTimeLine',{attrs:{"items":_vm.currentTimeline,"activeIndex":_vm.currentScreenIndex,"completedIndexes":_vm.completedIndexes}})],1)]),_c('div',{staticClass:"mt-5 mt-xl-12 mt-lg-5"},_vm._l((_vm.stages[_vm.currentStage].questions[_vm.currentScreenIndex]),function(question,questionId){return _c('v-col',{key:questionId,style:({
                    padding: _vm.currentScreenIndex === 0 ? '4px' : 'auto',
                    width: _vm.currentScreenIndex === 0 ? '700px' : 'auto',
                    display: _vm.currentScreenIndex === 0 ? 'grid' : 'block',
                    justifyItems: _vm.currentScreenIndex === 0 ? 'center' : 'initial',
                    alignItems: _vm.currentScreenIndex === 0 ? 'center' : 'initial'
                })},[(question.extra_title)?_c('h3',{staticClass:"mt-xl-10 mt-lg-0"},[_vm._v(_vm._s(question.extra_title))]):_vm._e(),(question.type == 'select')?[_c('QuestionSelect',{attrs:{"id":question.id,"question":question,"options":question.options},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}})]:_vm._e(),(question.type == 'conditional')?[_c('QuestionConditional',{attrs:{"id":question.id,"question":question,"showOptions":_vm.showOptions,"options":question.options},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}})]:_vm._e(),(question.type == 'radio')?[_c('QuestionRadio',{attrs:{"id":question.id,"question":question,"showOptions2":_vm.showOptions2}})]:_vm._e()],2)}),1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center"},[(_vm.currentQuestionIndex == 0)?_c('v-btn',{staticClass:"mr-10 px-16 primary--text",staticStyle:{"border":"1px solid #00265b"},attrs:{"rounded":""},on:{"click":_vm.backPage}},[_vm._v("Voltar")]):_vm._e(),(_vm.currentScreenIndex > 0)?_c('v-btn',{staticClass:"mr-10 px-16 primary--text",staticStyle:{"border":"1px solid #00265b"},attrs:{"rounded":""},on:{"click":function($event){_vm.currentScreenIndex--}}},[_vm._v("Voltar")]):_vm._e(),_c('v-btn',{staticClass:"px-10",attrs:{"dark":"","rounded":"","color":"primary","disabled":_vm.pass},on:{"click":_vm.handleButtonClick}},[_vm._v(" "+_vm._s(_vm.currentScreenIndex < _vm.stages[_vm.currentScreenIndex].questions.length - 1 ? 'Avançar' : 'Finalizar')+" ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }