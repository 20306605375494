<template>
   <v-row class="px-3">
      <v-col cols="6" style="padding: 0px;">
        <div class="d-flex" height="150" style="padding: 5px;">
          <v-img width="80" :src="areaImg"></v-img>
        </div>
      </v-col>
      <v-col cols="6" style="padding: 0px;">
        <div class="d-flex" height="150" style="padding: 5px;">
          <v-img width="90" :src="setorImg"></v-img>
        </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    props: {
      areaImg: String,
      setorImg: String
    }
  };
  </script>
  