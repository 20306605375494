<template>
    <v-card flat >
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="6">
            <span class="mt-xl-2 primary--text text-overline">
              {{ $t('who') }} {{ $t('is') }}
            </span>
            <p class="mt-2 primary--text" style="font-size: 14px !important;"> {{ negocio.quem_e }}</p>
          </v-col>
          <v-col cols="6">
            <span class="mt-xl-2 primary--text text-overline">
              Quem faz
            </span>
            <p class="mt-2 primary--text" style="font-size: 14px !important;">{{ negocio.quem_faz }}
            </p>
            <p v-if="negocio.foto_destaque">
              <v-img :src="negocio.foto_destaque.content" min-width="300"  max-width="384" max-height="226" contain></v-img>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="primary--text" style="font-size: 14px !important;">
            <b>Razão Social:</b> {{ negocio.razao_social }}
            <br><b>CNPJ:</b> {{ negocio?.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')  }}
            <br><b>Endereço:</b> {{ negocio.endereco }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  export default {
    props: {
      negocio: Object
    }
  };
  </script>
  