<template>
   <div class="mt-3 px-0 bx-0">
    <span class="porcentagem primary--text" style="font-size: 10px !important;">
      IMPACTO SOCIOAMBIENTAL DECLARADO:
    </span>
    <p class="porcentagem primary--text" style="font-size: 10px !important;">
      {{ negocio.impacto_socioambiental?.length > 80 ? negocio.impacto_socioambiental.substring(0, 80) + '...' : negocio.impacto_socioambiental }}
    </p>
  </div>
</template>
  
  <script>
  export default {
    props: {
      negocio: Object
    }
  };
  </script>
  