<template>
    <v-expansion-panel class="pb-1">
      <v-expansion-panel-header class="text-left font-weight-bold">
        <span class="text-left font-weight-medium primary--text">Dados da empresa</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="mx-0 pb-2">
          <v-col cols="12" class="no-horizontal-align">
            <div>
              <div class="my-1 text-left">
                <span class="primary--text font-weight-medium caption">CNPJ: {{ negocio?.cnpj }}</span>
              </div>
              <div class="my-1 text-left">
                <span class="primary--text font-weight-medium caption">Setor: {{ negocio?.setor?.nome }}</span>
              </div>
              <div class="my-1 text-left">
                <span class="primary--text font-weight-medium caption">Área: {{ negocio?.area?.nome }}</span>
              </div>
              <div class="my-1 text-left">
                <span class="primary--text font-weight-medium caption">Cidade: {{ negocio?.cidade }}</span>
              </div>
              <div class="my-1 text-left">
                <span class="primary--text font-weight-medium caption">Estado: {{ negocio?.uf }}</span>
              </div>
              <div class="my-1 text-left">
                <span class="primary--text font-weight-medium caption">Data de fundação: {{ formatDate(negocio?.data_fundacao) }}</span>
              </div>
              <div class="my-1 text-left">
                <span class="primary--text font-weight-medium caption">Rating Interno: {{ negocio?.rating_interno }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </template>
  
  <script>
  
  export default {
    props: {
      negocio: Object,
    },
  };
  </script>
  