<template>
    <v-card flat>
    <v-card-text>
      <v-row class="mt-5">
        <v-col cols="12">
          <v-data-table  hide-default-header
            hide-default-footer :headers="headers" :items="desserts" item-key="name"
            class="elevation-1">
          </v-data-table>
          <div class="mt-4">
            <span>*O período de reserva de investimento é o momento onde os investidores efetuam a solicitação do valor que querem investir. Ao chegar em 100% do valor total solicitado pela empresa, o período de reserva de investimento é encerrado imediatamente.</span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
  
  <script>
  export default {
    props: {
      headers: Array,
      desserts: Array
    }
  };
  </script>
  