import Vue from "vue";
import VueRouter from "vue-router";
import businessRoutes from "../views/Business/routes";
import registerRoutes from "../views/User/routes";
import investimentRoutes from "../views/Investiment/routes";
import registerUser from "../views/Register/routes";
import myInfoRoutes from "../views/MyInfo/routes";
import myInvestimentsRoutes from "../views/MyInvestiments/routes";
import homeRoutes from "../views/HomePage/routes";
import transparenciaRoutes from "../views/Transparencia/routes";
import configRoutes from "../views/ConfigBelat/routes";
import contactUsRoutes from "../views/ContactUs/routes";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: {
      render: (h) => h("router-view"),
    },
    children: [
      ...configRoutes.children,
      ...transparenciaRoutes.children,
      ...homeRoutes.children,
      ...registerRoutes.children,
      ...businessRoutes.children,
      ...investimentRoutes.children,
      ...registerUser.children,
      ...myInfoRoutes.children,
      ...myInvestimentsRoutes.children,
      ...contactUsRoutes.children
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      return redirectToLogin();
    }
    return Promise.reject(error);
  }
);

function redirectToLogin() {
  if (router.currentRoute.path !== "/") {
    Vue.$toast.warning("Sessao expirada"); 
    router.push({
      name: 'Login'
    });
  }
}

router.beforeEach((to, from, next) => {
  const user = localStorage.getItem("token");
  if(to.name == 'Login' && user) next('/home');
  if (to.meta.requiresAuth && !user ) {
    if (to.name !== 'Login') {
      localStorage.setItem('redirectRoute', to.fullPath);
      next('/');
    }
  }
  next();
});
export default router;
