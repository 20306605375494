<template>
    <v-card class="rounded-xl pa-2">
        <v-btn icon class="close-button" @click="closeScreen">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card-text class="pa-10 primary--text" style="font-weight: 500">
             <p>
                 Entraremos em contato com você o mais breve possível para te explicar todos os detalhes dessa nossa nova oportunidade de transformar a economia com impacto positivo. Enquanto isso acesse as nossas redes sociais e acompanhe a gente.
             </p> 
            <span>Até breve!</span>
        </v-card-text>
        <div style="display:flex; justify-content: center">
            <v-hover v-slot="{ hover }">
                  <v-btn
                    :class="{ 'on-hover': hover }"
                    class="pa-1 px-16 rounded-xl transition-fast-in-fast-out"
                    :color="hover ? 'primary' : 'secondary'"
                    @click="closeScreen"
                  >
                    Acessar
                  </v-btn>
              </v-hover>
        </div>
    </v-card>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        closeScreen() {
        this.$emit("close");
      },
    },
}
</script>

<style scoped>
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .text-h6 {
    color: black !important;
    font-weight: 400 !important;
  }
  
  .v-btn.grey.dark {
    background-color: grey !important;
    color: white !important;
  }
  
  .v-dialog>.v-card>.v-card__text.v-card__text {
    padding-bottom: 13px
  }
  
  hr {
    display: block;
    border: 0;
    border-top: 1px solid #ccc;
  }
  </style>