<template>
    <div>
      <layout>
        <template v-slot:content>
          <CardInfo />
          <div class="mt-lg-4 mx-4 mt-lg-8 px-lg-16 overflow-y-auto" style="height: 55vh;">
            <v-row>
              <FAQsSection :faqs="faqs" />
              <ContactForm :texto.sync="texto" @send-email="sendEmailContact" />
            </v-row>
          </div>
        </template>
      </layout>
    </div>
  </template>
  
  <script>
  import layout from "@/layouts/internalLayout.vue";
  import CardInfo from "./Components/CardInfo.vue";
  import FAQsSection from "./Components/FaqList.vue";
  import ContactForm from "./Components/ContactForm.vue";
  import userMixin from "@/mixins/userMixin";
  import contactMixin from "@/mixins/contactMixin";
  
  export default {
    metaInfo() {
      return {
        title: 'BELat - Fale Conosco'
      };
    },
    components: {
      layout,
      CardInfo,
      FAQsSection,
      ContactForm
    },
    mixins: [userMixin, contactMixin],
    data() {
      return {
        faqs: [],
        texto: ''
      };
    },
    async mounted() {
      await this.getUserData();
      this.loadFAQs();
    }
  };
  </script>
  