<template>
    <v-btn
      :class="{ 'on-hover': hover }"
      class="pa-6 px-16 transition-fast-in-fast-out"
      :color="color"
      @click="onClick"
    >
      {{ text }}
    </v-btn>
  </template>
  
  <script>
  export default {
    props: {
      text: String,
      color: String,
      onClick: Function,
    },
  };
  </script>
  