<template>
  <v-expansion-panel class="pb-1">
    <v-expansion-panel-header class="text-left font-weight-bold">
      <span class="text-left font-weight-medium primary--text">Dados de operação</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mx-0 pb-2">
        <v-col cols="12" class="no-horizontal-align">
          <div>
            <div class="my-1 text-left">
              <span class="primary--text font-weight-medium caption">Valor a ser captado: {{ formatCurrency(negocio?.valor_a_ser_captado) }}</span>
            </div>
            <div class="my-1 text-left">
              <span class="primary--text font-weight-medium caption">Valor captado até agora: {{ formatCurrency(negocio?.valor_captado_ate_agora) }}</span>
            </div>
            <div class="my-1 text-left">
              <span class="primary--text font-weight-medium caption">Ticket mínimo: {{ formatCurrency(negocio?.ticket_minimo) }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>

export default {
  props: {
    negocio: Object,
  },
};
</script>
