<template>
  <v-main style="background: rgb(249 250 251); height: 100vh; padding: 0;">
    <v-container
      class="custom-container"
      style="border: 0px"
      :style="{
        display: currentScreenIndex === 0 ? 'grid' : 'block',
        justifyItems: currentScreenIndex === 0 ? 'center' : 'initial',
        alignItems: currentScreenIndex === 0 ? 'center' : 'initial',
      }"
      fluid
    >
      <v-app-bar fixed color="white" style="z-index: 99;">
        <v-img max-width="150" src="@/assets/belat.png"></v-img>
        <v-spacer></v-spacer>
      </v-app-bar>

      <v-app-bar fixed color="#D64D7A" style="height: 10px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;"></v-app-bar>

      <div class="d-flex flex-column items-center primary mt-8" style="width: 100%">
        <div class="px-6 py-10 d-flex align-center" style="border-bottom: 1px solid #14aa95; justify-content: center">
          <div class="d-flex">
            <div class="text-h5 font-weight-medium text-center white--text" style="display: flex; align-items: center; font-family: 'Raleway'">
              {{ $t("investment_profile") }}
            </div>
          </div>
        </div>
      </div>

      <v-row class="d-flex justify-center">
        <div class="">
          <QuestionTimeLine :items="currentTimeline" :activeIndex="currentPosition" :completedIndexes="completedIndexes" />
        </div>
      </v-row>

      <div class="mt-8 mt-xl-12 mt-lg-8">
        <v-col
          v-for="(question, questionId) in stages[currentStage].questions[currentQuestionIndex]"
          :key="questionId"
          :style="{
            padding: currentScreenIndex === 0 ? '2px' : '4px',
            width: currentScreenIndex === 0 ? '' : 'auto',
            display: currentScreenIndex === 0 ? 'grid' : 'block',
            justifyItems: currentScreenIndex === 0 ? 'center' : 'initial',
            alignItems: currentScreenIndex === 0 ? 'center' : 'initial',
          }"
        >
          <h3 v-if="question.extra_title">{{ question.extra_title }}</h3>

          <QuestionCard
            v-if="question.type === 'radio'"
            :question="question"
            :currentQuestionIndex="currentQuestionIndex"
            :totalQuestions="stages[currentStage].questions.length"
            @button-clicked="handleButtonClick"
            @update-answer="updateQuestionAnswer"
          />
        </v-col>
      </div>

      <v-col cols="12" class="pt-5">
        <NavigationButtons
          :currentQuestionIndex="currentQuestionIndex"
          :totalQuestions="stages[currentScreenIndex].questions.length"
          :isDisabled="pass"
          @back-page="backPage"
          @go-back="currentQuestionIndex--"
          @advance="handleButtonClick"
        />
      </v-col>
    </v-container>
  </v-main>
</template>

<script>
import QuestionTimeLine from "../Components/QuestionTimeLine.vue";
import QuestionCard from "../Components/QuestionCard.vue";
import NavigationButtons from "../Components/NavigationButtons.vue";
import axios from "axios";

export default {
  props: {
    currentTimeline: Array,
    currentPosition: Number,
  },
  components: {
    QuestionTimeLine,
    QuestionCard,
    NavigationButtons,
  },
  data() {
    return {
      pass: false,
      activeIndex: 0,
      completedIndexes: [0],
      currentQuestionIndex: 0,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      answers: {},
      currentScreenIndex: 0,
      currentStage: 0,
      answering: false,
      showOptions: false,
      stages: [
        {
          position: 1,
          title: 'Perfil de investimento',
          text: 'Saiba que tipo de investidor você é.',
          questions: [
            [
              {
                required: true,
                id: 'finalidadeInvestimento',
                type: 'radio',
                text: this.$t('question1Invest'),
                options: [
                  {
                    text: this.$t('option1question1Invest'),
                    value: 'option1question1Invest',
                    points: 0
                  },
                  {
                    text: this.$t('option2question1Invest'),
                    value: 'option2question1Invest',
                    points: 5

                  },
                  {
                    text: this.$t('option3question1Invest'),
                    value: 'option3question1Invest',
                    points: 10
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'tempoAplicado',
                type: 'radio',
                text: this.$t('question2Invest'),
                options: [
                  {
                    text: this.$t('option1question2Invest'),
                    value: 'option1question2Invest',
                    points: 0
                  },
                  {
                    text: this.$t('option2question2Invest'),
                    value: 'option2question2Invest',
                    points: 5
                  },
                  {
                    text: this.$t('option3question2Invest'),
                    value: 'option3question2Invest',
                    points: 10
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'investimentoConhecimento',
                type: 'radio',
                text: this.$t('question3Invest'),
                options: [
                  {
                    text: this.$t('option1question3Invest'),
                    value: 'option1question3Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option2question3Invest'),
                    value: 'option2question3Invest',
                    points: 5
                  },
                  {
                    text: this.$t('option3question3Invest'),
                    value: 'option3question3Invest',
                    points: 9
                  },
                  {
                    text: this.$t('option4question3Invest'),
                    value: 'option4question3Invest',
                    points: 10
                  },
                  {
                    text: this.$t('option5question3Invest'),
                    value: 'option5question3Invest',
                    points: 0
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'valoresMobiliarios',
                type: 'radio',
                text: this.$t('question4Invest'),
                options: [
                  {
                    text: this.$t('option1question4Invest'),
                    value: 'option1question4Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option2question4Invest'),
                    value: 'option2question4Invest',
                    points: 5
                  },
                  {
                    text: this.$t('option3question4Invest'),
                    value: 'option3question4Invest',
                    points: 9
                  },
                  {
                    text: this.$t('option4question4Invest'),
                    value: 'option4question4Invest',
                    points: 10
                  },
                  {
                    text: this.$t('option5question4Invest'),
                    value: 'option5question4Invest',
                    points: 0
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'frequencia',
                type: 'radio',
                text: this.$t('question5Invest'),
                options: [
                  {
                    text: this.$t('option1question5Invest'),
                    value: 'option1question5Invest',
                    points: 10
                  },
                  {
                    text: this.$t('option2question5Invest'),
                    value: 'option2question5Invest',
                    points: 5
                  },
                  {
                    text: this.$t('option3question5Invest'),
                    value: 'option3question5Invest',
                    points: 3
                  },
                  {
                    text: this.$t('option4question5Invest'),
                    value: 'option4question5Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option5question5Invest'),
                    value: 'option5question5Invest',
                    points: 0
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'mercadoFinanceiro',
                type: 'radio',
                text: this.$t('question6Invest'),
                options: [
                  {
                    text: this.$t('option1question6Invest'),
                    value: 'option1question6Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option2question6Invest'),
                    value: 'option2question6Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option3question6Invest'),
                    value: 'option3question6Invest',
                    points: 4
                  },
                  {
                    text: this.$t('option4question6Invest'),
                    value: 'option4question6Invest',
                    points: 5
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'mensalDeclarada',
                type: 'radio',
                text: this.$t('question7Invest'),
                options: [
                  {
                    text: this.$t('option1question7Invest'),
                    value: 'option1question7Invest',
                    points: 0
                  },
                  {
                    text: this.$t('option2question7Invest'),
                    value: 'option2question7Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option3question7Invest'),
                    value: 'option3question7Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option4question7Invest'),
                    value: 'option4question7Invest',
                    points: 4
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'bensPatrimonio',
                type: 'radio',
                text: 'Qual o valor dos ativos (bens materiais, imóveis e aplicações financeiras) que compõem seu patrimônio? ',
                options: [
                  {
                    text: this.$t('option1question8Invest'),
                    value: 'option1question8Invest',
                    points: 0
                  },
                  {
                    text: this.$t('option2question8Invest'),
                    value: 'option2question8Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option3question8Invest'),
                    value: 'option3question8Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option4question8Invest'),
                    value: 'option4question8Invest',
                    points: 4
                  },
                  {
                    text: this.$t('option5question8Invest'),
                    value: 'option5question8Invest',
                    points: 5
                  },
                ]
              },
            ],
            [
              {
                required: true,
                id: 'relacaoInvestimento',
                type: 'radio',
                text: this.$t('question9Invest'),
                options: [
                  {
                    text: this.$t('option1question9Invest'),
                    value: 'option1question9Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option2question9Invest'),
                    value: 'option2question9Invest',
                    points: 3
                  },
                  {
                    text: this.$t('option3question9Invest'),
                    value: 'option3question9Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option4question9Invest'),
                    value: 'option4question9Invest',
                    points: 0
                  },
                  {
                    text: this.$t('option5question9Invest'),
                    value: 'option5question9Invest',
                    points: 5
                  },
                ]
              },
            ],
            [
              {
                id: 'volumeInvestido',
                type: 'radio',
                required: true,
                text: this.$t('question10Invest'),
                options: [
                  {
                    text: this.$t('option1question10Invest'),
                    value: 'option1question10Invest',
                    points: 1
                  },
                  {
                    text: this.$t('option2question10Invest'),
                    value: 'option2question10Invest',
                    points: 2
                  },
                  {
                    text: this.$t('option3question10Invest'),
                    value: 'option3question10Invest',
                    points: 3
                  },
                  {
                    text: this.$t('option4question10Invest'),
                    value: 'option4question10Invest',
                    points: 5
                  },
                  {
                    text: this.$t('option5question10Invest'),
                    value: 'option5question10Invest',
                    points: 0
                  },
                ]
              },
            ],
          ],
        },
      ]
    };
  },
  mounted() {
    this.getMeusDados();
  },
  methods: {
    updateQuestionAnswer(new_answer, question_id) {
      if(this.stages[this.currentStage].questions[this.currentQuestionIndex].find(question => question.id == question_id)){
        this.stages[this.currentStage].questions[this.currentQuestionIndex].find(question => question.id == question_id).answer = new_answer;
      }
    },
    backPage() {
      this.$parent.goToPreviousScreen();
    },
    checkAnswers() {
      const questions = this.stages[this.currentStage].questions[this.currentQuestionIndex];
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        if (question.required && !question.answer) {
          return false;
        }
      }
      return true;
    },
    async getMeusDados() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        const response = await axios.get(`${this.urlEnv}getQuestions`, config);
        this.pass = true;

        if (response.data.investmentProfile) {
          // Armazena as respostas em this.answers
          this.answers = response.data.investmentProfile;
          this.completedIndexes = Object.keys(this.answers).map((item) => parseInt(item));
          this.$emit('update:currentPosition', this.currentPosition);
          this.currentQuestionIndex = this.currentPosition - 1;
          this.currentStage = Math.floor(this.currentPosition / 6);
          
          // Atualiza as respostas nas perguntas
          this.stages[this.currentStage].questions.forEach(questionGroup => {
            questionGroup.forEach(question => {
              const answer = this.answers[question.id]?.answer;
              if (answer) {
                question.answer = answer;
              }
            });
          });
        }
      } catch (error) {
        this.pass = false;
        console.error(error);
      }
    },
    async handleButtonClick() {
      await this.$nextTick();
      if (!this.checkAnswers()) {
        this.$toast.error('Por favor, preencha todos os campos para continuar.');
        return;
      } else {
        if (this.currentQuestionIndex < this.stages[this.currentScreenIndex].questions.length - 1) {
          setTimeout(() => {
            this.currentQuestionIndex++;
          }, 350);
        } else {
          this.finishStage();
        }
      }
    },
    finishStage() {
      this.pass = true
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      const data = {
        questions: this.stages[this.currentStage].questions,
        investComplete: true,
      };
      try {
        axios
          .post(this.urlEnv + "saveQuestions", data, config)
          .then((response) => {
            console.log(response.data);
            // this.$emit('incrementPosition')
            this.$parent.goToNextScreen();
          })
          .catch((error) => {
            this.pass = false
            this.$toast.error(error);
          });
      } catch (error) {
        this.pass = false
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.custom-container {
  background: white;
  border-radius: 2%;
  padding: 20px;
}
</style>
