<template>
    <v-icon :class="customClass" @click="handleClick">{{ icon }}</v-icon>
  </template>
  
  <script>
  export default {
    props: {
      icon: {
        type: String,
        default: 'mdi-chevron-left'
      },
      customClass: {
        type: String,
        default: 'custom-icon'
      },
      size: {
        type: String,
        default: '24px'
      },
      color: {
        type: String,
        default: '#032A5A'
      }
    },
    methods: {
      handleClick() {
        this.$emit('click');
      }
    }
  };
  </script>
  
  <style scoped>
  .custom-icon {
    font-size: var(--icon-size, 24px);
    color: var(--icon-color, #032A5A);
    position: absolute;
    left: 16px;
    cursor: pointer;
  }
  </style>
  