<template>
  <div>
    <layout style="z-index: 100; position: relative; display: flex; flex-direction: column; min-height: 100vh;">
      <template v-slot:content>
        <div class="mt-lg-6 mt-sm-1 mx-4 py-auto px-sm-10 px-xs-2">
          <v-row>
            <PromoSection 
              :imageUrl="require('@/assets/belat_app_02.png')" 
              title="CROWDLENDING" 
              description="Investimento de impacto coletivo direto em uma empresa"
              buttonText="Invista AGORA EM IMPACTO POSITIVO"
              buttonRoute="/business"
            />
            <FundoBelaSection />
          </v-row>
        </div>
        <ContactSection />
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/layouts/internalLayout.vue";
import PromoSection from "./Components/PromoSection.vue";
import ContactSection from "./Components/ContactSection.vue";
import FundoBelaSection from "./Components/FundoBelaSection.vue";
import userMixin from "@/mixins/userMixin";

export default {
  metaInfo() {
    return {
      title: 'BELat - Home'
    }
  },
  components: {
    layout,
    PromoSection,
    ContactSection,
    FundoBelaSection
  },
  mixins: [userMixin],
  data() {
    return {
      userId: localStorage.getItem("userId"),
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
    };
  },
  async created() {
    await this.getUserData();
  }
};
</script>

<style scoped>
.v-navigation-drawer__border {
  display: none;
}
</style>
