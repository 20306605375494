import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VMasker from "v-mask";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import i18n from "./i18n";
import VueHorizontalTimeline from "vue-horizontal-timeline";
import ToggleButton from 'vue-js-toggle-button'
import VStepper from 'vue-stepper-component'
import VueCarousel from 'vue-carousel';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import VueMeta from 'vue-meta';
import'./styles/export.css';

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  newestOnTop: true,
  timeout: 5000,
  position: "bottom-center",
  maxToasts: 3,
  closeOnClick: true,
  pauseOnHover: false,
});

Vue.use(ToggleButton)
Vue.use(VStepper)
Vue.use(VueHorizontalTimeline);
Vue.use(VMasker)
Vue.use(VueMeta)
Vue.use(VueCarousel)
Vue.use(Donut);


Vue.config.productionTip = false;

Vue.$globalEvent = new Vue();

new Vue({
  i18n,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");


