<template>
    <v-col class="col-xs-12 col-sm-6">
      <v-img :src="imageUrl" width="100%" max-width="500px" min-width="350px" style="object-fit: contain;" class="mx-auto">
        <v-row class="align-end d-flex justify-center pt-4 px-6" style="height:90%;">
          <div class="px-16 px-xs-5">
            <span class="text-h5 white--text font-weight-bold">{{ title }}</span>
            <p class="mb-1 white--text textHome2">{{ description }}</p>
            <v-row class="d-flex justify-center align-end textHome">
              <v-btn @click="$router.push(buttonRoute)" rounded class="d-flex justify-end py-6 mt-10 textHome" color="primary" dark>
                <span>{{ buttonText }}</span>
                <v-list-item-avatar size="30" class="opacityButton d-flex align-end ">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-avatar>
              </v-btn>
            </v-row>
            <p v-if="additionalText" class="mb-5 white--text" style="font-size:12px">{{ additionalText }}</p>
          </div>
        </v-row>
      </v-img>
    </v-col>
  </template>
  
  <script>
  export default {
    props: {
      imageUrl: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      buttonText: {
        type: String,
        required: true
      },
      buttonRoute: {
        type: String,
        required: true
      },
      additionalText: {
        type: String,
        required: false
      }
    }
  };
  </script>
  
  <style scoped>
  .opacityButton {
    background: #14aa95;
  }
  
  .textHome,
  .textHome2,
  .white--text {
    font-family: 'Raleway', cursive !important;
  }
  
  @media (min-width: 2560px) {
    .textHome {
      font-size: 24px; 
    }
    .textHome2 {
      font-size: 21px; 
    }
  }
  @media (min-width: 1920px) and (max-width: 2559px) {
    .textHome {
      font-size: 17px !important; 
    }
    .textHome2 {
      font-size: 21px; 
    }
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .textHome {
      font-size: 14px; 
    }
    .textHome2 {
      font-size: 21px; 
    }
  }
  @media (max-width: 1279px) {
    .textHome {
      font-size: 12px; 
    }
    .textHome2 {
      font-size: 16px; 
    }
  }
  </style>
  