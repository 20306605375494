import layout from "@/layouts/internalLayout.vue";
import HomePage from "./desktop/HomePage.vue";
import HomeInvest from "./desktop/HomeInvestment.vue"
import MobileHome from './mobile/MobileHome.vue';
import apiService from "@/services/apiService";

async function changeHomeComponent() {
  const token = localStorage.getItem("token");
  const urlEnv = process.env.VUE_APP_SERVICE_URL;

  if (!token || !urlEnv) {
    console.error("Missing token, or service URL.");
    return HomePage;
  }

  const investorStatus = await apiService.getInvestidor(token, urlEnv);
  if (!investorStatus) { 
    console.error("Failed to retrieve investor status.");
    return HomePage;
  }

  if (investorStatus.data.data.investiu) {
    return HomeInvest;
  }

  if (investorStatus?.data?.data?.investidor?.questions?.finished) {
    return HomePage;
  }

  return HomePage;
}

const routes = {
  component: layout,
  children: [
    {
      name: "HomePage",
      path: "home",
      component: changeHomeComponent,
      beforeEnter: (to, from, next) => {
        const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        if (isMobile) {
          next(`/mobile/HomeProfile`);
        } else {
          next();
        }
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: 'MobileHome',
      path: '/mobile/home',
      component: MobileHome,
    },
  ],
};

export default routes;
