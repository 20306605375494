<template>
  <v-app>
    <v-main>
      <router-view />
      <CookieConsent />
    </v-main>
  </v-app>
</template>

<script>
import CookieConsent from './components/CookieConsent.vue';
export default {
  name: "App",
  components: {
    CookieConsent
  }
};
</script>

<style>
*, body, html {
  font-family: 'Raleway' !important;
}
</style>
