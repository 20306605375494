import axios from "axios";

const apiService = {
    getBusinesses(token, urlEnv) {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        return axios.get(`${urlEnv}listBusiness`, config);
    },
    getAddress(cep) {
        return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    },
    getBusinessDetail(businessId) {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };
        return axios.get(`${process.env.VUE_APP_SERVICE_URL}businessDetail/${businessId}`, config);
    },
    checkCadastro(token, urlEnv) {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        return axios.get(`${urlEnv}checkCadastro`, config);
    },
    getUserData() {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };
        return axios.get(`${process.env.VUE_APP_SERVICE_URL}user`, config);
    },
    getFAQs(locale) {
        return axios.get(`/locales/${locale}_faqs.json`);
    },
    sendContactEmail(token, userContact) {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-Frontend-URL': window.location.origin,
            }
        };
        return axios.post(`${process.env.VUE_APP_SERVICE_URL}sendContactEmail`, userContact, config);
    },
    getInvestidor(token, urlEnv) {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        return axios.get(`${urlEnv ?? process.env.VUE_APP_SERVICE_URL}getInvestidor`, config);
    },
    updateInvestidor(token, data) {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        return axios.post(`${process.env.VUE_APP_SERVICE_URL}updateInvestidor`, data, config);
    },
    getInvestiments(token, urlEnv) {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        return axios.get(`${urlEnv}getInvestiments`, config);
    },
    checkUser(){
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };

        return axios.get(`${process.env.VUE_APP_SERVICE_URL}checkCadastro`, config);
    },
    createLogin(userData){
        return axios.post(`${process.env.VUE_APP_SERVICE_URL}createLogin`, userData);
    },
    getMeusDados(){
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };

        return axios.get(`${process.env.VUE_APP_SERVICE_URL}getMeusDados`, config);
    },
    getBancos(){
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };

        return axios.get(`${process.env.VUE_APP_SERVICE_URL}getBanco`, config);
    },
    getTiposConta(){
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };

        return axios.get(`${process.env.VUE_APP_SERVICE_URL}getTipoConta`, config);
    },
    getEstadosCivis(){
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };
        return axios.get(`${process.env.VUE_APP_SERVICE_URL}getEstadosCivis`, config);
    },
    getSexos(){
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };
        return axios.get(`${process.env.VUE_APP_SERVICE_URL}getSexos`, config);
    }
};

export default apiService;
