import { render, staticRenderFns } from "./BusinessTabs.vue?vue&type=template&id=ff658a4c"
import script from "./BusinessTabs.vue?vue&type=script&lang=js"
export * from "./BusinessTabs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports