<template>
    <layout>
        <template v-slot:content>
            <div class="mt-lg-4 mx-4 mt-xl-12 px-xl-10">
                <v-row>
                    <v-col class="px-lg-16 pt-lg-10" cols="12">
                        <h1 class="font-weight-regular primary--text">Configurações da conta</h1>
                        <PasswordSettings />
                        <DocumentSettings />
                    </v-col>
                </v-row>
            </div>
        </template>
    </layout>
</template>

<script>
import layout from "@/layouts/internalLayout.vue";
import PasswordSettings from "./Components/PasswordSettings.vue";
import DocumentSettings from "./Components/DocumentSettings.vue";
import userMixin from "@/mixins/userMixin";

export default {
    metaInfo() {
        return {
            title: 'BELat - Configurações'
        }
    },
    components: {
        layout,
        PasswordSettings,
        DocumentSettings
    },
    mixins: [userMixin],
    mounted() {
        this.getUserData();
    }
};
</script>
