<template>
  <layout :click-busi="false">
    <template v-slot:content>
      <v-container style="padding: 10px; border: 0px" class="mt-2 custom-container" fluid>
        <h3 class="pa-1 primary--text">CONHEÇA NOSSAS OPORTUNIDADES DE INVESTIMENTO</h3>
        <v-row class="d-flex justify-end" style="margin-bottom: 0.3rem">
          <v-col cols="1" class="pa-1">
            <vueSelect :businesses="businesses" :areasDeInvestimento="areasDeInvestimento" @filtrarBusinesses="filtrarBusinesses"/>
          </v-col>
        </v-row>
        <InvestmentCarousel
          :businesses="filteredBusinesses"
          :getPhoto="getPhoto"
          :getGradient="getGradient"
          :calcPercentInvestiment="calcPercentInvestiment"
          :getProgressBarColor="getProgressBarColor"
          :numberToReal="numberToReal"
          :numberToPercentage="numberToPercentage"
          :cantInvest="cantInvestMethod"
          @handleClick="handleClick"
        />
      </v-container>
    </template>
  </layout>
</template>

<script>
import vueSelect from "./Components/SelectVue.vue"
import InvestmentCarousel from './Components/InvestmentCarousel.vue';
import { numberToReal, getPhoto, numberToPercentage } from "@/js/belatUtils";
import layout from "@/layouts/internalLayout.vue";
import businessMixin from "@/mixins/businessMixin";
import userMixin from "@/mixins/userMixin";

export default {
  metaInfo() {
    return {
      title: 'BELat - Negócios'
    }
  },
  components: {
    layout,
    vueSelect,
    InvestmentCarousel
  },
  mixins: [businessMixin, userMixin],
  data() {
    return {
      businessOptions: [],
      selectedBusiness: null,
      drawer: false,
      group: null,
      businessId: localStorage.getItem("negocio"),
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      areasDeInvestimento: [],
      businesses: [],
      filteredBusinesses: [],
      userId: localStorage.getItem("userId"),
      items: [
        {
          title: "Meus Investimentos",
          icon: "mdi-home-city",
          route: "/user/MyInvestiments",
        },
        { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
      ],
    };
  },
  computed: {
    getPhoto() {
      return getPhoto;
    },
    numberToReal() {
      return numberToReal;
    },
    numberToPercentage() {
      return numberToPercentage;
    },
  },
  mounted() {
    this.getListagem();
  },
  methods: {
    handleClick(id) {
      this.showMore(id);
    }
  },
};
</script>
