<template>
    <v-card flat>
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="6">
            <h1 class="primary--text text-body-1"><b>Estruturação de crédito:</b>{{negocio?.estruturacao_credito ?? ''}}</h1>
            <v-card elevation="0" color="primary" tile class="mt-3 px-4 py-2">
              <span class="text-body-1 white--text font-weight-bold">GARANTIAS</span>
              <p class="text-caption font-weight-light white--text mt-5">{{ negocio.garantias }}</p>
            </v-card>
            <h1 class="ml-2 mt-3 text-body-1 primary--text text-uppercase font-weight-bold">Opinião de
              credito</h1>
            <p class="ml-2 text-caption primary--text mt-2 font-weight-light">{{ negocio.opiniao_credito }}
            </p>
          </v-col>
          <v-col cols="6">
            <div v-for="(image, index) in negocio.grafico?.slice(0,2)" :key="index" class="image-container" >
              <v-img 
                class="mt-3 image"
                min-width="300" contain max-width="350px"
                :src="image.content"
                @click="openImageModal(image.content)"
                style="cursor: zoom-in;"
              >   <v-icon
                class="lupa-icon"
                color="grey"
                @click="openImageModal(image.content)"
                style="position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 48px; cursor: zoom-in;"
                  
              >
                mdi-magnify
              </v-icon></v-img>
          
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  export default {
    props: {
      negocio: Object
    },
    methods: {
      openImageModal(imageSrc) {
        this.$emit('openImageModal', imageSrc);
      }
    }
  };
  </script>
  