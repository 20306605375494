<template>
    <v-container fluid class="customize-container">
      <v-dialog max-width="400" v-model="dialog">
        <PjNotification @close="close" />
      </v-dialog>
      <v-row>
        <v-col cols="6" class="px-xl-16 px-lg-10 px-10 left-col">
          <div class="mt-xl-6" style="display: flex; align-items: center;">
            <v-btn icon @click="returnSignUp">
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.97601 8L8.76012 14.6328C8.91371 14.783 9 14.9867 9 15.1991C9 15.4115 8.91371 15.6152 8.76012 15.7654C8.60653 15.9156 8.39822 16 8.18101 16C7.9638 16 7.75549 15.9156 7.6019 15.7654L0.240305 8.56633C0.164132 8.49202 0.103697 8.40375 0.0624612 8.30657C0.0212257 8.20939 0 8.10521 0 8C0 7.89479 0.0212257 7.79061 0.0624612 7.69343C0.103697 7.59625 0.164132 7.50798 0.240305 7.43367L7.6019 0.234581C7.75549 0.0843811 7.9638 0 8.18101 0C8.39822 0 8.60653 0.0843811 8.76012 0.234581C8.91371 0.38478 9 0.588495 9 0.800909C9 1.01332 8.91371 1.21704 8.76012 1.36724L1.97601 8Z"
                  fill="#032A5A" />
              </svg>
            </v-btn>
            <span class="pl-1 font-weight-bold text-caption" style="cursor: pointer;" @click="returnSignUp">VOLTAR</span>
          </div>
          <div class="d-flex justify-center align-center flex-column" style="height: 100%;">
            <div class="imageVcard" style="border-radius: 50%; width: 150px; height: 150px; background: white;">
              <v-img style="border-radius: 20%;" src="@/assets/viniciusBelat.png"></v-img>
            </div>
            <InfoCard />
            <p class="primary--text text-xl-h6 font-weight-bold pa-lg-2 pa-xl-5 mt-3">
              Vinicius Costa. Head de Relação com Investidores, da Banca Ética
            </p>
          </div>
        </v-col>
        <v-col cols="6" class="right-col" style="display: flex; justify-content: center; align-items: center">
          <div class="pa-xl-16 pa-lg-10 mx-lg-16 pa-lg-16 mx-xl-16">
            <p class="mt-xl-16 font-weight-regular white--text">
              Olá, {{ user.name }}, percebemos seu interesse em conhecer mais sobre o Bela Impacto Crédito Consciente FIDC.
            </p>
            <p class="mt-5 white--text">
              O Fundo tem como diferencial investir em empresas e organizações que geram impacto socioambiental positivo nas áreas de Educação e Cultura, Desenvolvimento Social e Meio Ambiente.
            </p>
            <p class="white--text">
              O público-alvo do Fundo são investidores qualificados de acordo com a CVM.
            </p>
            <p class="white--text">
              Entraremos em contato com você o mais breve possível para te explicar todos os detalhes dessa nossa nova oportunidade de transformar a economia com impacto positivo.
            </p>
            <p class="white--text">
              Enquanto isso, veja as Oportunidades disponíveis na Plataforma ou acesse as nossas redes sociais e acompanhe a gente.
            </p>
            <p class="white--text">Até breve!</p>
            <p class="mt-10 white--text text-center">Saiba mais sobre a Banca Ética em nossas redes sociais</p>
            <SocialMediaLinks />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </template>
<script>
import PjNotification from "../Modal/PjNotification.vue";
import SocialMediaLinks from "./Components/SocialMediaLinks.vue";
import InfoCard from "./Components/InfoCard.vue";
import axios from "axios";
import cryptoMixin from "@/mixins/cryptoMixin";
export default {
    components: {
        PjNotification,
        SocialMediaLinks,
        InfoCard
    },
    mixins: [cryptoMixin],
    data() {
        return {
            dialog: false,
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            user: {
                name: 'Sem nome'
            }
        };
    },
    mounted() {
        const encryptedUser = localStorage.getItem('encryptedUser');
        if(encryptedUser) this.user = JSON.parse(this.decrypt(encryptedUser));
        if (this.token) this.sendUserToLeads();
    },
    methods: {
        close() {
      this.dialog = false;
    },
        returnSignUp() {
            this.$router.push({
              name: "HomePage",
            });
        },
        async sendUserToLeads() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            const response = await axios.get(this.urlEnv + "user", config);
            this.user = response.data;
            axios.post(this.urlEnv + "createLead", {
                email: response.data.email,
                name: response.data.name,
                phone_number: response.data.phone_number
            }, config).then(() => {
            }).catch(error => {
                console.error(error);
            })
        }
    }
};
</script>

<style scoped>
.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

.left-col {
    height: 100vh;
    background-color: #14AA95;
}

.right-col {
    height: 100vh;
    background-color: #00265b;
}

.customize-container {
    padding: 0;
    max-width: 1440px;
    margin: 0 auto;
    border: 1px solid #fff;
}

@media (min-width: 2560px) {}

@media (min-width: 1920px) and (max-width: 2559px) {
    .imageVcard {
        z-index: 9;
        /* position: absolute;
        top: 6rem;
        right: 65rem; */
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .imageVcard {
        z-index: 9;
        /* position: absolute;
        top: 0;
        right: 43rem; */
    }
}

@media (max-width: 1279px) {}
</style>