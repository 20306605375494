<template>
    <v-expansion-panels accordion>
        <v-expansion-panel class="ma-1 pa-1" style="border-left: 2px solid #0489b1a9;" v-for="(faq, index) in faqs" :key="index">
            <v-expansion-panel-header class="font-weight-bold">{{ faq.question }}</v-expansion-panel-header>
            <v-expansion-panel-content>{{ faq.answer }}</v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    props: {
        faqs: {
            type: Array,
            required: true
        }
    }
};
</script>

<style scoped>
</style>
