import MyInvestiments from "./desktop/MyInvestiments.vue";
import MobileMyInvestiments from "./mobile/MobileMyInvestiments.vue";

const routes = {
  children: [
    {
      name: "MobileMyInvestiments",
      path: "mobile/MyInvestiments",
      component: MobileMyInvestiments,
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: "MyInvestiments",
      path: "user/MyInvestiments",
      component: MyInvestiments,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default routes;
