<template>
    <div class="primary--text">
      <p class="font-weight-medium mt-5 text-h4 text-decoration-underline primary--text">Contato</p>
      <p>Caso não tenha encontrado a resposta para sua dúvida no campo 'Perguntas frequentes' aqui ao lado, por favor deixe sua mensagem que lhe responderemos por e-mail.</p>
      <p class="primary--text mt-3 font-weight-bold">Título da sua mensagem</p>
      <v-textarea v-model="localTexto" outlined label="Escreva sua mensagem aqui!"></v-textarea>
      <div class="d-flex justify-center">
        <v-btn @click="sendEmailContact" color="secondary" class="rounded-xl px-16 mx-16">Enviar</v-btn>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      texto: String
    },
    data() {
      return {
        localTexto: this.texto
      };
    },
    watch: {
      // Quando o valor da prop 'texto' mudar no pai, atualize a cópia local
      texto(newVal) {
        this.localTexto = newVal;
      }
    },
    methods: {
      sendEmailContact() {
        this.$emit('send-email', this.localTex);
      }
    }
  };
  </script>
  