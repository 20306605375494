<template>
  <layout :click-busi="false">
    <template v-slot:content>
      <v-container style="border: 0px" class="px-5 mt-5 custom-container" fluid>
        <v-dialog max-width="500" v-model="dialog">
          <SimulationInvest @close="closeDialog" :negocio="negocio" :titularUser="titularUser" />
        </v-dialog>
        <v-row class="w-100" max-width="100%">
          <v-col cols="12" md="3" class="mr-0 mb-0 pb-0 pr-0">
            <BusinessCard 
              :negocio="negocio"
              :getPhoto="getPhotos"
              :calcPercentInvestiment="calcPercentInvestiment"
              :getProgressBarColor="getProgressBarColor"
              :numberToReal="numberToRs"
              :cantInvest="cantInvest"
              :numberToPercentage="numberToPerce"
              @openDialog="openDialog"
            />
          </v-col>
          <v-col cols="12" md="9" class="ml-0 bl-0">
            <BusinessTabs :negocio="negocio" :headers="headers" :desserts="desserts" @openImageModal="openImageModal"/>
          </v-col>
        </v-row>
        <FullscreenImageModal :dialog="isImageModalOpen" :imageSrc="selectedImage" @update:dialog="isImageModalOpen = $event" />
      </v-container>
    </template>
  </layout>
</template>

<script>
import layout from "@/layouts/internalLayout.vue";
import SimulationInvest from "./Modal/SimulationInvest.vue";
import FullscreenImageModal from './Components/FullscreenImageModal.vue';
import BusinessCard from './Components/BusinessCard.vue';
import BusinessTabs from './Components/BusinessTabs.vue';
import businessMixin from "@/mixins/businessMixin";
import formatMixin from "@/mixins/formatMixin";
import investorMixin from "@/mixins/investorMixin";
import { getPhoto, numberToReal, numberToPercentage } from "@/js/belatUtils";

const gradients = [["#222"]];

export default {
  metaInfo() {
    return {
      title: 'BELat - Negócio'
    }
  },
  components: {
    SimulationInvest,
    layout,
    FullscreenImageModal,
    BusinessCard,
    BusinessTabs
  },
  mixins: [businessMixin, formatMixin, investorMixin],
  data() {
    return {
      isImageModalOpen: false,
      selectedImage: '',
      tab: null,
      userId: localStorage.getItem("userId"),
      selectedSection: "impacto",
      dialog: false,
      width: 2,
      padding: 8,
      lineCap: "round",
      gradient: gradients[0],
      value: [0, 8, 1, 12],
      gradientDirection: "top",
      fill: false,
      type: "trend",
      negocio: {},
      capital: {},
      autoLineWidth: false,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      businesses: [],
      headers: [
        { text: 'Dessert (100g serving)', align: 'start', value: 'name' },
        { text: 'Category', value: 'category' },
      ],
      desserts: [
        { name: 'Valor Total', category: '' },
        { name: 'Número de parcelas', category: '' },
        { name: 'Taxa de rentabilidade', category: '' },
        { name: 'Aporte mínimo', category: '' },
        { name: 'Garantias', category: '' },
        { name: 'Mínimo de captação da oportunidade', category: '' },
        { name: 'Data limite para reserva', category: '' },
        { name: 'Data prevista para transferência dos recursos', category: '' },
      ],
      titularUser: {},
      items: [
        { title: "Meus Investimentos", icon: "mdi-home-city", route: "/user/MyInvestiments" },
        { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
      ],
    };
  },
  methods: {
    openImageModal(imageSrc) {
      this.selectedImage = imageSrc;
      this.isImageModalOpen = true;
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    getPhotos(photo) {
      return getPhoto(photo);
    },
    numberToRs(number){
      return numberToReal(number);
    },
    numberToPerce(number){
      return numberToPercentage(number);
    }
  },
  async mounted() {
    const businessId = this.$route.params.businessId;
    await this.getInvestidor();
    await this.getBusinessDetail(businessId);
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
.custom-container {
  max-width: 1800px;
  min-height: 600px;
  height: auto;
  border: 0;
}
</style>
