<template>
    <ContactInfo />
  </template>
  
  <script>
  import ContactInfo from './ContactInfo.vue';
  
  export default {
    components: {
      ContactInfo
    }
  };
  </script>
  