<template>
    <v-card color="primary">
      <div class="d-md-flex" :style="backgroundStyle">
        <h1 class="font-weight-regular white--text d-flex align-end pl-16 pb-5">
          Perguntas frequentes
        </h1>
      </div>
    </v-card>
  </template>
  
  <script>
  export default {
    computed: {
      backgroundStyle() {
        return {
          height: '30vh',
          backgroundImage: `url(${require('@/assets/belat_contact_us.png')})`,
          backgroundPositionX: '40%',
          backgroundSize: 'none'
        };
      }
    }
  };
  </script>
  