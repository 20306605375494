<template>
    <v-text-field
      v-model="position"
      class="rounded-lg"
      hide-details="auto"
      :label="$t('position')"
      outlined
      dense
      :disabled="!editing"
    />
  </template>
  
  <script>
  export default {
    props: {
      value: String,
      editing: Boolean
    },
    computed: {
      position: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    }
  };
  </script>
  