<template>
    <carousel :paginationEnabled="false" :navigationEnabled="true" :perPage="4" style="overflow-y: auto;">
      <slide
        v-for="business in businesses"
        :key="business.id"
      >
        <InvestmentCard
          :business="business"
          :getPhoto="getPhoto"
          :getGradient="getGradient"
          :calcPercentInvestiment="calcPercentInvestiment"
          :getProgressBarColor="getProgressBarColor"
          :numberToReal="numberToReal"
          :numberToPercentage="numberToPercentage"
          :cantInvest="cantInvest"
          @handleClick="handleClick"
        />
      </slide>
    </carousel>
  </template>
  
  <script>
  import InvestmentCard from './InvestmentCard.vue';
  
  export default {
    props: {
      businesses: Array,
      getPhoto: Function,
      getGradient: Function,
      calcPercentInvestiment: Function,
      getProgressBarColor: Function,
      numberToReal: Function,
      numberToPercentage: Function,
      cantInvest: Function,
    },
    components: {
      InvestmentCard
    },
    methods: {
      handleClick(id) {
        this.$emit('handleClick', id);
      }
    },
  };
  </script>
  