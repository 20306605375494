export default {
    data() {
        return {
            statusDoInvestimento: [
                { id: 1, text: 'Aberto', value: 2 },
                { id: 2, text: 'Em Andamento', value: 3 },
                { id: 3, text: 'Encerrado', value: 5 }
            ],
            classificacaoInvestimentos: [
                { id: 1, text: 'Do Mais Recente', value: 'recente' },
                { id: 2, text: 'Do Mais Antigo', value: 'antigo' },
            ],
            filtrosSelecionados: {
                status: [],
                areas: [],
                classificacoes: []
            },
            showDropdown: false
        };
    },
    methods: {
        limparFiltros() {
            this.filtrosSelecionados = { status: [], areas: [], classificacoes: [] };
        },
        aplicarFiltros() {
            let filteredBusinesses = [...this.businesses];

            const statusIds = this.filtrosSelecionados.status.map(status => {
                return status === 5 ? [4, 5] : [status];
            }).flat();

            if (statusIds.length > 0) {
                filteredBusinesses = filteredBusinesses.filter(business => {
                    return statusIds.includes(business.status_id);
                });
            }

            if (this.filtrosSelecionados.areas.length > 0) {
                filteredBusinesses = filteredBusinesses.filter(business => {
                    return this.filtrosSelecionados.areas.includes(business.neg_area_id);
                });
            }

            filteredBusinesses.sort((a, b) => {
                if (this.filtrosSelecionados.classificacoes.includes('recente')) {
                    return new Date(b.data_liberacao) - new Date(a.data_liberacao);
                }
                if (this.filtrosSelecionados.classificacoes.includes('antigo')) {
                    return new Date(a.data_liberacao) - new Date(b.data_liberacao);
                }
                return 0;
            });
            this.showDropdown = !this.showDropdown;
            this.$emit('filtrarBusinesses', filteredBusinesses);
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        keepOne(checkedValue) {
            this.filtrosSelecionados.classificacoes.forEach((value, index) => {
                if (value !== checkedValue) {
                    this.filtrosSelecionados.classificacoes.splice(index, 1);
                }
            });
        }
    }
};
