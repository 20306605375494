import ContactUsPage from "./desktop/ContactUs.vue"
import MobileContact from "./mobile/MobileContact.vue"

const routes = {
  children: [
    {
      name: 'ContactUs',
      path: 'contact-us', 
      component: ContactUsPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: 'MobileContact',
      path: '/mobile/MobileContact',
      component: MobileContact
    },
  ]
};

export default routes;
