<template>
    <v-expansion-panel class="pb-1">
      <v-expansion-panel-header class="text-left font-weight-bold">
        <span class="text-left font-weight-medium primary--text">Dados Financeiros</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="mx-0 pb-2">
          <v-col cols="12" class="no-horizontal-align">
            <div>
              <div class="my-1 text-left">
                <span class="primary--text font-weight-medium caption">Receita anual (R$): {{ formatCurrency(negocio?.faturamento_anual) }}</span>
              </div>
              <div class="my-1 text-left">
                <span class="primary--text font-weight-medium caption">Dívida líquida / EBITDA: {{ negocio?.divida_liquida }}</span>
              </div>
              <div class="my-1 text-left">
                <span class="primary--text font-weight-medium caption">Endividamento (R$): {{ formatCurrency(negocio?.endividamento) }}</span>
              </div>
              <div class="my-1 text-left">
                <span class="primary--text font-weight-medium caption">Histórico de Crédito: {{ negocio?.historico_de_credito }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </template>
  
  <script>
  
  export default {
    props: {
      negocio: Object,
    },
  };
  </script>
  