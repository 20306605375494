<template>
  <v-main style="background: rgb(249 250 251); height: 100%;">
    <v-container class="custom-container" style="height: 100%; padding: 0;">
      <v-row style="height:100%;">
        <v-app-bar class="custom-container2" fixed color="#DF6E8B" style="height: 15px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;">
        </v-app-bar>
        <v-col cols="12" md="7" class="primary d-flex flex-column justify-center position-relative">
          <div class="d-flex justify-end">
            <v-img class="gifLogo" src="@/assets/gif_banca.gif"></v-img>
          </div>
          <!-- Texto de background -->
          <div class="col-9">
            <div class="d-flex align-start h-100 flex-column pl-16 mt-16" style="position: relative;">
              <v-img class="mb-2" max-width="250" src="@/assets/banca_etica.png"></v-img>
              <h3 class="pt-5 text-h4 font-weight-medium white--text">Olá, <br /> que bom ver você aqui.</h3>
              <p style="font-size: 1.2rem;" class="mt-5 font-weight-light white--text mb-3">Aqui, na plataforma de investimento da BELAT, você pode conectar seus investimentos com seus valores, e gerar um impacto socioambiental positivo em qualquer operação de sua escolha.
                <br>Impacto é uma questão de escolha. Venha conosco e faça a sua</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="5" lx="5" class="white"
          style="z-index: 10; display: flex; justify-content: center; align-items: center;">
          <div style="width: 60%">
            <LoginForm
            :email="email"
            :password="password"
            :show-pass1="showPass1"
            :disabled="disabled"
            @submit="submit"
            @sign-up="signUp"
            @update:email="email = $event"
            @update:password="password = $event"
            @update:showPass1="showPass1 = $event"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import LoginForm from './Components/LoginForm.vue';
import { EventBus } from '@/js/eventBus.js';

export default {
  components: {
    LoginForm
  },
  metaInfo() {
    return {
      title: 'BELat - Login'
    }
  },
  data() {
    return {
      errorMessageMap: {
        email: "O campo email é obrigatorio",
        password: "O campo senha é obrigatorio",
      },
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      disabled: false,
      email: null,
      password: null,
      showPass1: false,
      confirmPass1: false,
      rulesEmail: [
        (v) => this.validateEmail(v) || "E-mail inválido",
      ],
      rules: [
        (v) => !!v || "Campo obrigatório",
        (v) =>
          (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
        (v) =>
          (v && /[A-Z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra maiúscula",
        (v) =>
          (v && /[a-z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra minúscula",
        (v) =>
          (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
      ],
    };
  },
  methods: {
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    submit() {
      this.disabled = true;
      const fields = ["email", "password"];
      for (const field of fields) {
        if (!this[field]) {
          this.$toast.error(this.errorMessageMap[field]);
          this.disabled = false;
          return;
        }
      }

      axios
        .post(this.urlEnv + 'login', {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          localStorage.setItem('token', response.data.data.token);
          const token = localStorage.getItem('token');
          const config = {
            headers: { Authorization: `Bearer ${token}` },
          };
          axios
            .get(this.urlEnv + `checkCadastro`, config)
            .then((response) => {
              const redirectRoute = localStorage.getItem('redirectRoute');
              if (redirectRoute) {
                this.$router.push(redirectRoute);
                localStorage.removeItem('redirectRoute');
              }else {
                if (response?.data.status == false) {
                  this.$router.replace('/templateRegister');
                } else {
                  this.$router.replace('/home');
                  this.$toast.success("Logado com sucesso")
                }
              }
              EventBus.$emit('userLoggedIn');
            })
            .catch((error) => {
              console.log(error);
              this.disabled = false;
              false;
            })
        })
        .catch((err) => {
            this.disabled = false;
            if(err.response.data && err.response.data?.data?.error && err.response.data?.data?.error?.email[0])
              this.$toast.error(err.response.data.data.error.email[0]);
            else
              this.$toast.error('Ocorreu um erro inesperado');
        });
    },
    signUp() {
      this.$router.push("/signup");
    },
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 1440px;
  margin: 0 auto;
  border: 1px solid #fff;
}

.custom-container2 {
  max-width: 1461px;
  margin: 0 auto;
  border: 1px solid #fff;
}
.gifLogo {
  position: absolute;
  top: -30%;
  left: 27%;
  bottom: 2%;
  max-width: 40%;
}

.v-main__wrap {
  background: rgb(249 250 251) !important;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
  font-family: 'Raleway', cursive !important;
}
</style>
