import layout from "@/layouts/internalLayout.vue";
import ListView from "./desktop/ListView.vue";
import ShowBusiness from "./desktop/ShowBusiness.vue";
import ShowBusinessMobile from "./mobile/ShowBusinessMobile.vue"
import MobileBusiness from "./mobile/MobileBusiness.vue"

const routes = {
  component: layout,
  children: [
    {
      name: "ListView",
      path: "business",
      component: ListView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: "ViewBusiness",
      path: "business/view/:businessId",
      component: ShowBusiness,
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: "ViewBusinessMobile",
      path: "mobile/business/view/:businessId",
      component: ShowBusinessMobile,
    },
    {
      name: 'MobileBusiness',
      path: 'mobile/MobileBusiness',
      component: MobileBusiness,
    },
  ],
};

export default routes;
