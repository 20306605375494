import Config from "./desktop/AccountSettings.vue"
import ConfigMobile from "./mobile/ConfigPageMobile.vue"

const routes = {
  children: [
    {
      name: 'config',
      path: 'config', 
      component: Config,
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: 'ConfigMobile',
      path: '/mobile/config',
      component: ConfigMobile,
    },
  ]
};

export default routes;
