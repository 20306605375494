<template>
    <v-row>
        <v-col v-for="business in businesses" :key="business.id" cols="12" sm="6" md="4" lg="3">
            <BusinessCard :business="business" :onDetailClick="() => getBusinessDetail(business.id)" />
        </v-col>
    </v-row>
</template>

<script>
import BusinessCard from './BusinessCard.vue';

export default {
    props: {
        businesses: Array,
        filterCriteria: Object
    },
    components: {
        BusinessCard
    },
    methods: {
        filtrarBusinesses() {
            this.$emit('filtrarBusinesses', this.filterCriteria);
        }
    }
};
</script>
