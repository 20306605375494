<template>
    <span class="mr-2" v-if="fileName">{{ truncatedName }}</span>
  </template>
  
  <script>
  export default {
    props: {
      fileName: String
    },
    computed: {
      truncatedName() {
        if (this.fileName && this.fileName.length > 51) {
          const extension = this.fileName.split('.').pop();
          const trimmedName = this.fileName.slice(0, 48);
          return `${trimmedName}...${extension}`;
        }
        return this.fileName;
      }
    }
  };
  </script>
  