import InvestimentPage from "./desktop/InvestimentPage.vue";


const routes = {
  children: [
    {
      name: "InvestimentPage",
      path: "business/view/:businessId/investiment",
      component: InvestimentPage,
      meta: {
        requiresAuth: true,
      },
    },

  ],
};

export default routes;
