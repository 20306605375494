<template>
    <v-main class="oito" style="height: 100vh; padding: 0;">
        <v-container class="custom-container mt-16"
            style="display: flex;border: 0px;justify-content: center;align-items: center; width: 100vw; height: 90vh;"
            fluid>
            <v-app-bar fixed color="white" style="z-index: 99;">
                <v-img max-width="150" src="@/assets/belat.png"></v-img>
                <v-spacer></v-spacer>

            </v-app-bar>
            <v-card elevation="5" class="white" style="z-index: 10; border-radius: 5%;"
                :class="{ 'lg-width-50': $vuetify.breakpoint.lgAndUp, 'xs-width-100': $vuetify.breakpoint.xs }">
                <div class="pa-16">

                    <v-row class="text-center">
                        <v-col cols="12">
                            <v-icon color="secondary" size="70">
                                mdi-email-fast
                            </v-icon>
                            <h2 :class="{ 'text-h5': $vuetify.breakpoint.xs }" style="font-size: 28px"
                                class="text-center primary--text font-weight-bold">
                                Agora vamos validar seu email.
                            </h2>
                            <div style="display: flex; justify-content: center; align-items: center;">
                                <div class="oi"></div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class=" text-center">
                            <p :class="{ 'text-body-1': $vuetify.breakpoint.xs }"
                                class="text-center primary--text text-h5">
                                Por favor acesse o seu email <br> {{ email ? email : '(email não encontrado)' }}
 <br> e valide
                                sua conta
                            </p>
                            <span class="primary--text text-center font-weight-bold text-h6"
                                :class="{ 'text-body-1': $vuetify.breakpoint.xs }">
                                Isso levará menos de 1 minuto!
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <span class="text-center blue-grey--text text--darken-1 primary--text">
                                Não recebeu o email?
                            </span>
                        </v-col>
                        <v-col cols="12" class="px-16 d-flex justify-center">
                            <v-hover v-slot="{ hover }">
                                <v-btn rounded block :class="{ 'on-hover': hover }"
                                    class="pa-6 px-16 transition-fast-in-fast-out"
                                    :color="hover ? 'primary' : 'secondary'" @click="submit" :disabled="disabled">
                                    <span>{{ disabled ? `Poderá reenviar em ${secondsToEnable ? secondsToEnable : 60}
                                        segundo(s)` : 'REENVIAR email' }}</span>
                                </v-btn>
                            </v-hover>
                        </v-col>

                        <!-- <v-col cols="12" class="d-flex justify-center">
                            <span class="text-center blue-grey--text text--darken-1">
                                <a class="text-decoration-none primary--text " href="/">
                                    Já validou sua conta? Clique aqui e volte para logar
                                </a>
                            </span>
                        </v-col> -->
                    </v-row>
                </div>
            </v-card>
        </v-container>
    </v-main>
</template>

<script>
import cryptoMixin from "@/mixins/cryptoMixin";
import axios from "axios";

export default {
    data() {
        return {
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            email: null,
            disabled: false,
            secondsToEnable: 0,
        };
    },
    mixins: [cryptoMixin],
    mounted() {
        if (!this.$route.query.email) this.$router.push('/');
        else this.email = this.decrypt(this.$route.query.email);
    },
    methods: {
        submit() {
            if (this.email) {
                this.disabled = true;
                axios
                    .post(this.urlEnv + 'email/verification-notification', {
                        email: this.email,
                    }, { headers: { 'X-Frontend-URL': window.location.origin } })
                    .then(() => {
                        this.$toast.success("Link de verificação enviado!");
                        const startTime = Date.now();
                        const interval = setInterval(() => {
                            const currentTime = Date.now();
                            const timeRemaining = Math.max(0, Math.round((startTime + 60000 - currentTime) / 1000));
                            this.secondsToEnable = timeRemaining;
                            if (timeRemaining === 0) {
                                clearInterval(interval);
                                this.disabled = false;
                            }
                        }, 1000);
                    })
                    .catch((err) => {
                        this.disabled = false;
                        this.$toast.error(err.response.data.message);
                    });
            } else {
                this.$toast.error('Email não informado');
            }
        },
    }
};
</script>

<style scoped>
.custom-container {
    max-width: 1440px;
    margin: 0 auto;
    border: 1px solid #fff;
    padding: 20px;
}

.gifLogo {
    position: absolute;
    top: -30%;
    left: 27%;
    bottom: 2%;
    max-width: 40%;
}

.v-main__wrap {
    background: rgb(249 250 251) !important;
}

.oi {
    width: 25rem;
    border: 1px solid #14aa95;
    height: 2px;
}

.oito {
    background: bottom;
    background-image: url(@/assets/sendEmail.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
}

.oito::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00265ba4;
    z-index: 1;
}

.oito>* {
    position: relative;
    z-index: 2;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

.lg-width-50 {
    width: 50%;
}

.xs-width-100 {
    width: 100%;
}

/* SCSS */
@include breakpoint(lg) {
    .lg-width-50 {
        width: 50%;
    }
}

@include breakpoint(xs) {
    .xs-width-100 {
        width: 100%;
    }
}
</style>