<template>
    <div class="mt-10 mt-xl-16" style="display: flex; justify-content: space-between;">
        <div>
            <h1 class="text-lg-h6 black--text">Senha</h1>
            <span class="primary--text">Alterar sua senha de acesso</span>
        </div>
        <div>
            <v-btn class="px-6" @click="redirectTo('ChangePassword')" color="primary" rounded>Alterar senha</v-btn>
        </div>
    </div>
</template>

<script>
import { redirectTo } from "@/js/belatUtils";

export default {
    methods: {
        redirectTo(route) {
            redirectTo(this.$router, route);
        }
    }
};
</script>
