<template>
    <v-card class="pa-2">
      <v-card-title class="text-center">
        <h4 class="grey--text font-weight-regular">Preenchimento de Cadastro</h4>
        <v-spacer></v-spacer>
        <v-card-actions class="justify-end">
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>
      <v-card-text>
        <p class="font-weight-medium">Para facilitar o preenchimento de seu cadastro, tenha em mãos:</p>
      </v-card-text>
      <v-card-text class="d-flex align-center">
        <v-avatar size="36" color="primary" class="mr-3">
          <v-icon dark class="white--text">mdi-card-account-details-outline</v-icon>
        </v-avatar>
        <div>
          <h1 class="text-h6 primary--text font-weight-bold">Documento de identificação (RG)</h1>
          <span>Emitido no prazo máximo de 10 anos</span>
        </div>
      </v-card-text>
      <v-card-text class="d-flex align-center mb-2" >
        <v-avatar size="36" color="primary" class="mr-3">
          <v-icon dark class="white--text">mdi-home</v-icon>
        </v-avatar>
        <div>
          <h1 class="text-h6 primary--text font-weight-bold">Comprovante de residência</h1>
          <span>Emitido no prazo máximo de 3 meses</span>
        </div>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  export default {
    methods: {
      closeForm() {
        this.$emit("close")
      },
    },
  };
  </script>
  