<template>
    <v-combobox
      :disabled="disabled"
      :label="label"
      color="secondary rounded-lg"
      v-model="selectedBank"
      :items="items"
      item-text="both"
      item-value="codigo"
      outlined
      dense
      @input="onInput"
    ></v-combobox>
  </template>
  
  <script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },
      value: {
        type: [String, Number],
        default: '',
      },
      label: {
        type: String,
        default: 'Número do banco',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedBank: this.value,
      };
    },
    watch: {
      value(newValue) {
        this.selectedBank = newValue;
      },
    },
    methods: {
      onInput(value) {
        this.$emit('input', value);
      },
    },
  };
  </script>
  