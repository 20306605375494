<template>
  <div style="width: 100%;">
    <v-container style="border: 0px;" fluid>
      <div class="d-flex">
        <v-row>
          <v-col cols="12">
            <h2 class="primary--text font-weight-medium" style="font-size: 20px;">{{ question.text }}</h2>
            <div class="d-flex mt-3">
              <toggle-button 
                :id="id" 
                v-model="localShowOptions" 
                color="#14AA95"
                :labels="{ checked: 'Sim', unchecked: 'Não' }" 
                :sync="true" 
              />
            </div>
            <div class="mt-5">
              <v-col style="padding: 2px" cols="12">
                <v-select 
                  multiple 
                  v-if="localShowOptions" 
                  class="ml-lg-5 changeColor"
                  color="secondary" 
                  dense 
                  placeholder="Opções" 
                  outlined 
                  v-model="localAnswer"
                  :items="options.map(option => ({ text: option.text, value: option.value }))"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="selectAllQuestions">
                      <v-list-item-content>
                        <v-list-item-title>
                          Selecionar todos
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index < 1">
                      <span>{{ item.text }} </span>
                    </span>
                    <span v-if="index === 1" class="text-grey text-caption align-self-center">
                      (+{{ localAnswer.length - 1 }} outros)
                    </span>
                  </template>
                </v-select>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    question: Object,
    showOptions: Boolean,
    options: Array,
    value: Array, 
  },
  data() {
    return {
      localShowOptions: this.showOptions ?? false,
      localAnswer: this.value ? [...this.value] : [],
    };
  },
  watch: {
    value(newVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(this.localAnswer)) {
        this.localAnswer = [...newVal];
      }
    },
    localShowOptions(newVal) {
      this.$emit('update:showOptions', newVal);
    },
    localAnswer(newVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(this.value)) {
        this.$emit('input', newVal);
      }
    },
  },
  methods: {
    selectAllQuestions() {
      const allOptions = this.options.length ? this.options.map(option => option.value) : [];
      this.localAnswer = this.localAnswer.length === allOptions.length ? [] : allOptions;
    },
  },
  mounted(){
    console.log(this.value);
  }
};
</script>
