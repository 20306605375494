<template>
    <div class="filter-component">
      <div class="dropdown">
        <button @click="toggleDropdown" class="dropdown-button">Filtro ▼</button>
        <div v-if="showDropdown" class="dropdown-content">
            <div class="headerText d-flex justify-space-between">
                <h3 class="text-body-1">Filtrar por:</h3>
                <button class="text-body-2" style="color: #00265b" @click="limparFiltros">Limpar filtros</button>
            </div>
          <div class="filter-category">
            <div class="mb-3">
                <h4 class="text-body-2 primary--text d-inline">Status Do Investimento</h4>
            </div>
            <div v-for="status in statusDoInvestimento" :key="status.id">
              <input type="checkbox" :id="'status_'+status.value" v-model="filtrosSelecionados.status" :value="status.value">
              <label :for="'status_'+status.value" class="ml-2 text-caption" style="color: #00265b">{{ status.text }}</label>
            </div>
          </div>
          <div class="filter-category">
            <div class="mb-3">
                <h4 class="text-body-2 primary--text d-inline">Áreas De Investimento</h4>
            </div>
            <div v-for="area in areasDeInvestimento" :key="area.id">
              <input type="checkbox" :id="'area_'+area.value" v-model="filtrosSelecionados.areas" :value="area.value">
              <label :for="'area_'+area.value" class="ml-2 text-caption" style="color: #00265b">{{ area.text }}</label>
            </div>
          </div>
          <div class="filter-category">
            <div class="mb-3">
                <h4 class="text-body-2 primary--text d-inline">Classificação dos Investimentos</h4>
            </div>
            <div v-for="classificacao in classificacaoInvestimentos" :key="classificacao.id">
              <input type="checkbox" :id="'classificacao_'+classificacao.value" v-model="filtrosSelecionados.classificacoes" :value="classificacao.value" @change="keepOne(classificacao.value)">
              <label :for="'classificacao_'+classificacao.value" class="ml-2 text-caption" style="color: #00265b">{{ classificacao.text }}</label>
            </div>
          </div>
          <div class="text-end">
              <button class="text-body-2" style="color: #00265b" @click="aplicarFiltros">Aplicar</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
  import selectMixin from '@/mixins/selectMixin';
  export default {
    props: {
      businesses: {
        type: Array,
        default: () => []
      },
      areasDeInvestimento: Array
    },
    mixins: [selectMixin],
  };
  </script>
  
  <style scoped>
  /* Aqui viriam os estilos para o seu componente de filtro, como no exemplo da sua imagem */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .headerText{
    margin-bottom: 1.2rem;
  }

  .text-body-2.primary--text {
  position: relative;
}

.text-body-2.primary--text::after {
  content: '';
  position: absolute;
  bottom: -2px; /* Ajuste conforme necessário */
  left: 0;
  width: 100%;
  height: 0.1rem; /* Espessura da linha */
  background-color: rgb(204, 63, 63); /* Cor da linha */
}

  .dropdown-button {
    background-color: #f8f9fa;
    border: 1px solid #00265b;
    border-radius: 4px;
    padding: 0.3rem 0.6rem;
    cursor: pointer;
    color: #00265b;
  }

  .dropdown-button:hover {
    background-color: #e9ecef;
  }
  
  .dropdown-content {
    border-radius: 2%;
    border: 1px solid rgba(0, 0, 0, 0.144);
    /* estilos para o conteúdo do dropdown */
    display: none;
    right: 0;
    position: absolute;
    background-color: white;
    padding: 1.5rem;
    width: 280px;
    min-width: 160px;
    z-index: 1;
  }
  
  /* Mostra o dropdown quando o botão é clicado */
  .dropdown .dropdown-content {
    display: block;
  }
  
  /* Estilos adicionais conforme necessário */
  </style>
  