<template>
    <v-main style="background: rgb(249 250 251); height: 100vh; padding: 0;">
        <v-container class="custom-container mt-8" style="border: 0px" :style="{
            display: currentScreenIndex === 0 ? 'grid' : 'block',
            justifyItems: currentScreenIndex === 0 ? 'center' : 'initial',
            alignItems: currentScreenIndex === 0 ? 'center' : 'initial'
        }" fluid>
            <v-app-bar fixed color="white" style="z-index: 99;">
                <v-img max-width="150" src="@/assets/belat.png"></v-img>
                <v-spacer></v-spacer>

            </v-app-bar>
            <v-app-bar fixed color="#D64D7A"
                style="height: 10px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;">
            </v-app-bar>
            <div class="d-flex flex-column items-center primary" style="width: 100%">
                <div class="px-6 py-10 d-flex align-center"
                    style="border-bottom: 1px solid #14aa95; justify-content: center">
                    <div class="d-flex">
                        <div class="text-h5 font-weight-medium text-center white--text "
                            style="display: flex; align-items: center; font-family: 'Raleway'">
                            Perfil de Impacto
                        </div>
                    </div>
                </div>
            </div>
            <v-row class="d-flex justify-center">
                <div class="">
                    <QuestionTimeLine :items="currentTimeline" :activeIndex="currentScreenIndex"
                        :completedIndexes="completedIndexes" />
                </div>
            </v-row>
            <div class="mt-5 mt-xl-12 mt-lg-5 ">
                <v-col v-for="question, questionId in stages[currentStage].questions[currentScreenIndex]" :key="questionId"
                    :style="{
                        padding: currentScreenIndex === 0 ? '4px' : 'auto',
                        width: currentScreenIndex === 0 ? '700px' : 'auto',
                        display: currentScreenIndex === 0 ? 'grid' : 'block',
                        justifyItems: currentScreenIndex === 0 ? 'center' : 'initial',
                        alignItems: currentScreenIndex === 0 ? 'center' : 'initial'
                    }">

                    <h3 class="mt-xl-10 mt-lg-0 " v-if="question.extra_title">{{ question.extra_title }}</h3>

                    <template v-if="question.type == 'select'">
                        <QuestionSelect :id="question.id" :question="question" :options="question.options"
                            v-model="question.answer" />
                    </template>

                    <template v-if="question.type == 'conditional'">
                        <QuestionConditional :id="question.id" :question="question" :showOptions="showOptions"
                            :options="question.options" v-model="question.answer" />
                    </template>

                    <template v-if="question.type == 'radio'">
                        <QuestionRadio :id="question.id" :question="question" :showOptions2="showOptions2" />
                    </template>

                </v-col>
            </div>
            <v-col cols="12">
                <div class="d-flex justify-center">
                    <v-btn class="mr-10 px-16 primary--text" style="border: 1px solid #00265b"
                        v-if="currentQuestionIndex == 0" rounded @click="backPage">Voltar</v-btn>
                    <v-btn class="mr-10 px-16 primary--text" style="border: 1px solid #00265b" v-if="currentScreenIndex > 0"
                        rounded @click="currentScreenIndex--">Voltar</v-btn>
                    <v-btn class="px-10" dark rounded color="primary" :disabled="pass" @click="handleButtonClick">
                        {{ currentScreenIndex < stages[currentScreenIndex].questions.length - 1 ? 'Avançar' : 'Finalizar' }}
                            </v-btn>
                </div>
            </v-col>

        </v-container>
    </v-main>
</template>

<script>
import QuestionTimeLine from "../Components/QuestionTimeLine.vue"
import QuestionSelect from "../Components/QuestionSelect2.vue";
import QuestionConditional from "../Components/QuestionConditional.vue";
import QuestionRadio from "../Components/QuestionRadio2.vue";
import axios from 'axios';
export default {
    props: {
        currentTimeline: Array,
    },
    components: {
        QuestionTimeLine,
        QuestionSelect,
        QuestionConditional,
        QuestionRadio,
    },
    data() {
        return {
            pass: false,
            activeIndex: 0,
            completedIndexes: [],
            currentQuestionIndex: 0,
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            answers: {},
            currentScreenIndex: 0,
            currentStage: 0,
            answering: false,
            showOptions: false,
            showOptions2: false,
            stages: [
                {
                    position: 1,
                    title: 'Perfil de impacto',
                    text: 'Saiba que tipo de investidor você é.',
                    questions: [
                        [
                            {
                                id: 'setorInvestimento',
                                type: 'select',
                                required: true,
                                text: this.$t('question1Impact'),
                                options: [
                                    {
                                        value: 'option1question1Impact',
                                        text: this.$t('option1question1Impact'),
                                    },
                                    {
                                        value: 'option2question1Impact',
                                        text: this.$t('option2question1Impact'),
                                    },
                                    {
                                        value: 'option3question1Impact',
                                        text: this.$t('option3question1Impact'),
                                    },
                                    {
                                        value: 'option4question1Impact',
                                        text: this.$t('option4question1Impact')
                                    },
                                    {
                                        value: 'option5question1Impact',
                                        text: this.$t('option5question1Impact')
                                    },
                                    {
                                        value: 'option6question1Impact',
                                        text: this.$t('option6question1Impact')
                                    },
                                    {
                                        value: 'option7question1Impact',
                                        text: this.$t('option7question1Impact')
                                    },
                                    {
                                        value: 'option8question1Impact',
                                        text: this.$t('option8question1Impact')
                                    }
                                ]
                            },
                            {
                                id: 'ods',
                                type: 'select',
                                required: true,
                                text: this.$t('question2Impact'),
                                options: [
                                    {
                                        value: 'option1question2Impact',
                                        text: this.$t('option1question2Impact'),
                                    },
                                    {
                                        value: 'option2question2Impact',
                                        text: this.$t('option2question2Impact'),
                                    },
                                    {
                                        value: 'option3question2Impact',
                                        text: this.$t('option3question2Impact'),
                                    },
                                    {
                                        value: 'option4question2Impact',
                                        text: this.$t('option4question2Impact'),
                                    },
                                    {
                                        value: 'option5question2Impact',
                                        text: this.$t('option5question2Impact'),
                                    },
                                    {
                                        value: 'option6question2Impact',
                                        text: this.$t('option6question2Impact'),
                                    },
                                    {
                                        value: 'option7question2Impact',
                                        text: this.$t('option7question2Impact'),
                                    },
                                    {
                                        value: 'option8question2Impact',
                                        text: this.$t('option8question2Impact')
                                    },
                                    {
                                        value: 'option9question2Impact',
                                        text: this.$t('option9question2Impact'),
                                    },
                                    {
                                        value: 'option10question2Impact',
                                        text: this.$t('option10question2Impact'),
                                    },
                                    {
                                        value: 'option11question2Impact',
                                        text: this.$t('option11question2Impact'),
                                    },
                                    {
                                        value: 'option12question2Impact',
                                        text: this.$t('option12question2Impact'),
                                    },
                                    {
                                        value: 'option13question2Impact',
                                        text: this.$t('option13question2Impact'),
                                    },
                                    {
                                        value: 'option14question2Impact',
                                        text: this.$t('option14question2Impact'),
                                    },
                                    {
                                        value: 'option15question2Impact',
                                        text: this.$t('option15question2Impact'),
                                    },
                                    {
                                        value: 'option16question2Impact',
                                        text: this.$t('option16question2Impact'),
                                    },
                                    {
                                        value: 'option17question2Impact',
                                        text: this.$t('option17question2Impact'),
                                    },
                                    {
                                        value: 'option18question2Impact',
                                        text: this.$t('option18question2Impact')
                                    }
                                ]
                            },
                            {
                                id: 'investidorImpacto',
                                type: 'conditional',
                                text: this.$t('question3Impact'),
                                options: [
                                    {
                                        value: 'option1question3Impact',
                                        text: this.$t('option1question3Impact'),
                                    },
                                    {
                                        value: 'option2question3Impact',
                                        text: this.$t('option2question3Impact'),
                                    },
                                    {
                                        value: 'option3question3Impact',
                                        text: this.$t('option3question3Impact'),
                                    },
                                    {
                                        value: 'option4question3Impact',
                                        text: this.$t('option4question3Impact'),
                                    },
                                    {
                                        value: 'option5question3Impact',
                                        text: this.$t('option5question3Impact'),
                                    },
                                    {
                                        value: 'option6question3Impact',
                                        text: this.$t('option6question3Impact'),
                                    },
                                    {
                                        value: 'option7question3Impact',
                                        text: this.$t('option7question3Impact'),
                                    },
                                    {
                                        value: 'option8question3Impact',
                                        text: this.$t('option8question3Impact'),
                                    },
                                ]
                            },
                            {
                                id: 'financiar',
                                type: 'radio',
                                text: this.$t('question4Impact'),
                                options: []
                            },
                        ],
                    ],
                },
            ]
        }
    },
    mounted() {
        this.getMeusDados()
    },
    methods: {
        backPage() {
            this.$parent.goToPreviousScreen();
        },
        checkAnswers() {
            const questions = this.stages[this.currentStage].questions[this.currentScreenIndex];
            for (let i = 0; i < questions.length; i++) {
                const question = questions[i];
                if (question.required && !question.answer) {
                    return false;
                }
            }
            return true;
        },
        async getMeusDados() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                axios
                    .get(this.urlEnv + `getQuestions`, config)
                    .then((response) => {

                        this.stages[this.currentStage].questions.forEach(questionGroup => {
                            questionGroup.forEach(question => {
                                const answer = response.data.data.questions[question.id]?.answer;
                                if (answer) {
                                    question.answer = answer;
                                    if (question.id == "investidorImpacto") {
                                        this.showOptions = true
                                    } else if (question.id == "financiar") {
                                        this.showOptions2 = true
                                    }
                                    this.$nextTick(() => {
                                        this.$forceUpdate();
                                    });
                                }
                            });
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } catch (err) {
                console.log(err);
            }
        },
        handleButtonClick() {
            this.pass = true;
            if (this.currentScreenIndex < this.stages[this.currentScreenIndex].questions.length - 1) {
                this.currentScreenIndex++;
            } else {
                this.finishStage();
            }
        },
        finishStage() {
            if (!this.checkAnswers()) {
                this.$toast.error('Por favor, preencha todos os campos para continuar.');
                this.pass = false;
                return;
            }
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };


            const data = {
                questions: this.stages[this.currentStage].questions,
                impactComplete: true,
            };


            try {
                axios
                    .post(this.urlEnv + "saveQuestions", data, config)
                    .then((response) => {
                        console.log(response.data);
                        this.$emit('incrementPosition')
                        this.$parent.goToNextScreen();
                    })
                    .catch((error) => {
                        this.$toast.error(error);
                    });
            } catch (error) {
                console.log(error);
                this.pass = false;
            }
        },
        getColor(position) {
            return position <= this.currentScreenIndex ? 'secondary' : 'gray';
        },
        selectAllQuestions(question) {
            const allOptions = question.options.length ? question.options.map(option => option.value) : [];
            question.answer = question.answer?.length === allOptions.length ? [] : allOptions;
            this.$nextTick(() => {
                this.$forceUpdate();
            });
            console.log(question.answer);
        }
    },

}
</script>

<style scoped>
.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.primary--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

.custom-container {
    /* max-width: 1440px; */
    margin: 0 auto;
    border: 1px solid #fff;
    padding: 20px 0;
}


.v-timeline::before {
    transform: rotate(90deg) !important;
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
    left: 0 !important
}
</style>