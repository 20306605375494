<template>
    <v-text-field
      v-model="salary"
      class="rounded-lg"
      :disabled="!editing"
      label="Salário"
      hide-details="auto"
      outlined
      dense
    />
  </template>
  
  <script>
  import formatMixin from '@/mixins/formatMixin.js';
  
  export default {
    mixins: [formatMixin],
    props: {
      value: {
        type: [String, Number],
      },
      editing: Boolean
    },
    computed: {
      salary: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
    watch: {
      "salary"(newValue) {
        let valor = newValue.replace(/\D/g, "");
        valor = (valor / 100).toFixed(2).replace(".", ",");
        valor = "R$ " + valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        this.salary = valor;
      },
    }
  };
  </script>
  