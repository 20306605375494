<template>
    <v-dialog
      v-model="localDialog"
      max-width="80%"
      persistent
      @click:outside="handleClose"
      @keydown.esc="handleClose"
    >
      <v-card>
        <v-card-actions class="d-flex justify-end">
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="p-0">
          <v-img :src="imageSrc" contain max-height="78vh" max-width="95%" class="d-block mx-auto"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'FullscreenImageModal',
    props: {
      dialog: {
        type: Boolean,
        required: true
      },
      imageSrc: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        localDialog: this.dialog
      };
    },
    watch: {
      dialog(val) {
        this.localDialog = val;
      },
      localDialog(val) {
        this.$emit('update:dialog', val);
      }
    },
    methods: {
      close() {
        this.localDialog = false;
      },
      handleClose() {
        this.localDialog = false;
        this.$emit('update:dialog', false);
      }
    }
  };
  </script>
  
  <style scoped>
  .v-img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
  </style>
  