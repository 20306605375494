var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticStyle:{"background":"#00265b","overflow-x":"hidden"}},[_c('div',{staticClass:"white",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-column items-center"},[_c('div',{staticClass:"px-6 py-3 d-flex align-center",staticStyle:{"justify-content":"center"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-h5 font-weight-medium text-center primary--text",staticStyle:{"display":"flex","align-items":"center","font-family":"'Raleway'"}},[_vm._v(" "+_vm._s(_vm.$t('investment_profile'))+" ")])])])]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('div',{},[_c('svg',{attrs:{"width":"230","height":"4","viewBox":"0 0 230 4","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"230","height":"4","rx":"2","fill":"#D9D9D9"}}),_c('path',{attrs:{"d":"M0 2.08696C0 0.934362 0.934362 0 2.08696 0H23V4H1.91304C0.8565 4 0 3.1435 0 2.08696Z","fill":"#14AA95"}}),_c('path',{attrs:{"d":"M46 0H69V4H46V0Z","fill":"#14AA95"}}),_c('path',{attrs:{"d":"M23 0H46V4H23V0Z","fill":"#14AA95"}}),_c('path',{attrs:{"d":"M69 0H92V4H69V0Z","fill":"#14AA95"}}),_c('path',{attrs:{"d":"M92 0H115V4H92V0Z","fill":"#14AA95"}}),_c('path',{attrs:{"d":"M115 0H138V4H115V0Z","fill":"#14AA95"}}),_c('path',{attrs:{"d":"M138 0H161V4H138V0Z","fill":"#14AA95"}}),_c('path',{attrs:{"d":"M161 0H184V4H161V0Z","fill":"#14AA95"}}),_c('path',{attrs:{"d":"M184 0H207V4H184V0Z","fill":"#14AA95"}})])])])],1),_c('div',{staticClass:"adjustScreen mt-8 mt-xl-12 mt-lg-8"},_vm._l((_vm.stages[_vm.currentStage].questions[_vm.currentQuestionIndex]),function(question,questionId){return _c('v-col',{key:questionId,style:({
            padding: _vm.currentScreenIndex === 0 ? '2px' : '4px',
            width: _vm.currentScreenIndex === 0 ? '' : 'auto',
            display: _vm.currentScreenIndex === 0 ? 'grid' : 'block',
            justifyItems: _vm.currentScreenIndex === 0 ? 'center' : 'initial',
            alignItems: _vm.currentScreenIndex === 0 ? 'center' : 'initial'
          })},[(question.extra_title)?_c('h3',{staticStyle:{"color":"#14AA95"}},[_vm._v(_vm._s(question.extra_title))]):_vm._e(),(question.type == 'radio')?[_c('div',{staticClass:"mx-5",staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticStyle:{"display":"grid","justify-content":"center","align-items":"center"}},[_c('div',[_c('h2',{staticClass:"pb-5 px-xl-20 px-lg-10 text-h6 font-weight-bold",staticStyle:{"color":"#7FD2C9","line-height":"24.65px"}},[_vm._v(_vm._s(question.text)+" ")]),_c('v-radio-group',{staticClass:"px-lg-16",staticStyle:{"margin-top":"0 !important"},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}},_vm._l((question.options),function(option){return _c('v-radio',{key:option.value,staticClass:"white-label",staticStyle:{"min-height":"60px"},attrs:{"dark":"","color":"secondary","id":question.id,"label":option.text,"value":option.value},on:{"click":_vm.handleButtonClick}})}),1)],1)])])]:_vm._e()],2)}),1),_c('div',{staticClass:"footer"},[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center"},[(_vm.currentQuestionIndex == 0)?_c('v-btn',{staticClass:"mr-5 px-10 white--text",staticStyle:{"border":"1px solid #14AA95 !important","background":"#00265b !important"},attrs:{"color":"primary","elevation":"0","rounded":""},on:{"click":_vm.backPage}},[_vm._v("Voltar")]):_vm._e(),(_vm.currentQuestionIndex > 0)?_c('v-btn',{staticClass:"mr-5 px-10 white--text",staticStyle:{"border":"1px solid #14AA95 !important"},attrs:{"color":"primary","rounded":""},on:{"click":function($event){_vm.currentQuestionIndex--}}},[_vm._v(_vm._s(_vm.$t('back')))]):_vm._e(),_c('v-btn',{staticClass:"px-12",attrs:{"dark":"","rounded":"","color":"secondary"},on:{"click":_vm.handleButtonClick}},[_vm._v(" "+_vm._s(_vm.currentQuestionIndex < _vm.stages[_vm.currentScreenIndex].questions.length - 1 ? _vm.$t('advance') : _vm.$t('finish'))+" ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }