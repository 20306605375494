<template>
    <v-card class="py-lg-7 px-lg-10 px-10 mx-16" style="border-radius: 10%;">
        <v-card-text class="primary--text">
            "O <b>Investimento em impacto positivo</b> vai muito além das práticas ambientais, sociais e
            de governança.
            Enquanto ESG foi um importante avanço, o conceito pode se aplicar tanto a empresas de
            impacto
            negativo como positivo. E ai pode surgir uma nuvem de confusão e imprecisões.
        </v-card-text>
        <v-card-text class="primary--text font-weight-bold">
            Eu te afirmo: nossa carteira de investimentos na Banca Etica Latinoamericana é 100% de
            impacto positivo, buscamos retorno financeiro acima do CDI e com nossa transparência total
            você sabe onde o seu dinheiro é aplicado - sem letrinha miúda no fim da página. E mais, com
            a gente, você diversifica seus investimentos, porque atuamos nas três áreas essenciais e
            estruturantes da economia: meio ambiente, desenvolvimento social e educação e cultura."
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "InfoCard",
};
</script>
