<template>
    <v-text-field
      v-model="phone"
      :disabled="!editing"
      class="rounded-lg"
      :label="$t('phone_number')"
      hide-details="auto"
      outlined
      dense
      v-mask="'(##) # ####-####'"
    />
  </template>
  
  <script>
  export default {
    props: {
      value: String,
      editing: Boolean
    },
    computed: {
      phone: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      },

    }
  };
  </script>
  