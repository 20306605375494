<template>
  <v-card outlined style="border: 1px solid rgba(20, 170, 149, 0.5)" class="mx-auto pa-1" max-width="300">
    <div class="pb-2">
      <v-img height="120" class="align-end text-white" :src="getPhoto(negocio?.logo)"></v-img>
      <v-card-text>
        <ProgressBar :value="calcPercentInvestiment(negocio)" :color="getProgressBarColor(negocio.neg_area_id)" />
        <BusinessInfo 
          :negocio="negocio" 
          :total="numberToReal(Math.floor(parseFloat(negocio.valor_a_ser_captado)/ 1000) * 1000)"
          :nominal="numberToPercentage(parseFloat(negocio.taxa))"
          :ticket="numberToReal(parseFloat(negocio.ticket_minimo))"
        />
        <SimulationButton @click="openDialog" :disabled="cantInvest" />
        <SocialImpact :negocio="negocio" />
        <BusinessImages :areaImg="negocio.area?.imagem.content" :setorImg="negocio.setor?.imagem.content" />
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import ProgressBar from './ProgressBar.vue';
import BusinessInfo from './BusinessInfo.vue';
import SimulationButton from './BusinessSButton.vue';
import SocialImpact from './SocialImpact.vue';
import BusinessImages from './BusinessImages.vue';

export default {
  components: {
    ProgressBar,
    BusinessInfo,
    SimulationButton,
    SocialImpact,
    BusinessImages,
  },
  props: {
    negocio: Object,
    getPhoto: Function,
    calcPercentInvestiment: Function,
    getProgressBarColor: Function,
    numberToReal: Function,
    numberToPercentage: Function,
    cantInvest: Boolean,
  },
  methods: {
    openDialog() {
      this.$emit('openDialog');
    },
  }
};
</script>
