<template>
    <div class="contact-section" style="background: #032A5A; width: 100%;">
      <div class="pa-2 d-flex justify-center align-center">
        <h1 class="text-h6 white--text text-center">
          Quer participar do desenvolvimento da 
          Banca Ética Latinoamericana? 
          <span class="secondary--text">Fale aqui com a gente.</span>
        </h1>
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  .text-h6,
  .white--text,
  .secondary--text,
  .text-center {
    font-family: 'Raleway', cursive !important;
  }
  
  .contact-section {
    padding: 16px;
  }
  </style>
  