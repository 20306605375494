<template>
  <v-progress-linear
    background-color="grey lighten-3"
    :color="color"
    :value="value"
    height="15"
    rounded
    :style="{ fontSize: '10px', borderRadius: '8px', border: '1px solid #ccc' }"
  >
    <template v-slot:default="{ value }">
      <span :class="{ 'white--text': value > 50 }">
        {{ Math.ceil(value) }}%
      </span>
    </template>
  </v-progress-linear>
</template>

<script>
export default {
  props: {
    value: Number,
    color: String
  }
};
</script>
