<template>
  <div>
    <v-tabs color="secondary" v-model="tab">
      <v-tab class="text-start no-horizontal-align">Dados <br> da empresa</v-tab>
      <v-tab class="text-start no-horizontal-align">Impacto <br> Positivo</v-tab>
      <v-tab class="text-start no-horizontal-align">Dados <br> Financeiros</v-tab>
      <v-tab class="text-start no-horizontal-align">Dados de <br> operação</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="overflow-y: hidden; overflow-x: hidden;">
      <v-tab-item>
        <BusinessDetails :negocio="negocio" />
      </v-tab-item>
      <v-tab-item>
        <PositiveImpact :negocio="negocio" />
      </v-tab-item>
      <v-tab-item>
        <FinancialData :negocio="negocio" @openImageModal="openImageModal" />
      </v-tab-item>
      <v-tab-item>
        <OperationData :headers="headers" :desserts="desserts" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
  
  <script>
  import BusinessDetails from './BusinessDetails.vue';
  import PositiveImpact from './PositiveImpact.vue';
  import FinancialData from './FinancialData.vue';
  import OperationData from './OperationData.vue';
  
  export default {
    components: {
      BusinessDetails,
      PositiveImpact,
      FinancialData,
      OperationData,
    },
    props: {
      negocio: Object,
      headers: Array,
      desserts: Array,
    },
    data() {
      return {
        tab: null,
      };
    },
    methods: {
      openImageModal(imageSrc) {
        this.$emit('openImageModal', imageSrc);
      },
    }
  };
  </script>
  