var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticStyle:{"background":"rgb(249 250 251)","height":"100vh","padding":"0"}},[_c('v-container',{staticClass:"custom-container",staticStyle:{"border":"0px"},style:({
      display: _vm.currentScreenIndex === 0 ? 'grid' : 'block',
      justifyItems: _vm.currentScreenIndex === 0 ? 'center' : 'initial',
      alignItems: _vm.currentScreenIndex === 0 ? 'center' : 'initial',
    }),attrs:{"fluid":""}},[_c('v-app-bar',{staticStyle:{"z-index":"99"},attrs:{"fixed":"","color":"white"}},[_c('v-img',{attrs:{"max-width":"150","src":require("@/assets/belat.png")}}),_c('v-spacer')],1),_c('v-app-bar',{staticStyle:{"height":"10px","margin-top":"0px","transform":"translateY(0px)","top":"auto !important","left":"0px","right":"0px","z-index":"99","bottom":"0px"},attrs:{"fixed":"","color":"#D64D7A"}}),_c('div',{staticClass:"d-flex flex-column items-center primary mt-8",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"px-6 py-10 d-flex align-center",staticStyle:{"border-bottom":"1px solid #14aa95","justify-content":"center"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-h5 font-weight-medium text-center white--text",staticStyle:{"display":"flex","align-items":"center","font-family":"'Raleway'"}},[_vm._v(" "+_vm._s(_vm.$t("investment_profile"))+" ")])])])]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('div',{},[_c('QuestionTimeLine',{attrs:{"items":_vm.currentTimeline,"activeIndex":_vm.currentPosition,"completedIndexes":_vm.completedIndexes}})],1)]),_c('div',{staticClass:"mt-8 mt-xl-12 mt-lg-8"},_vm._l((_vm.stages[_vm.currentStage].questions[_vm.currentQuestionIndex]),function(question,questionId){return _c('v-col',{key:questionId,style:({
          padding: _vm.currentScreenIndex === 0 ? '2px' : '4px',
          width: _vm.currentScreenIndex === 0 ? '' : 'auto',
          display: _vm.currentScreenIndex === 0 ? 'grid' : 'block',
          justifyItems: _vm.currentScreenIndex === 0 ? 'center' : 'initial',
          alignItems: _vm.currentScreenIndex === 0 ? 'center' : 'initial',
        })},[(question.extra_title)?_c('h3',[_vm._v(_vm._s(question.extra_title))]):_vm._e(),(question.type === 'radio')?_c('QuestionCard',{attrs:{"question":question,"currentQuestionIndex":_vm.currentQuestionIndex,"totalQuestions":_vm.stages[_vm.currentStage].questions.length},on:{"button-clicked":_vm.handleButtonClick,"update-answer":_vm.updateQuestionAnswer}}):_vm._e()],1)}),1),_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12"}},[_c('NavigationButtons',{attrs:{"currentQuestionIndex":_vm.currentQuestionIndex,"totalQuestions":_vm.stages[_vm.currentScreenIndex].questions.length,"isDisabled":_vm.pass},on:{"back-page":_vm.backPage,"go-back":function($event){_vm.currentQuestionIndex--},"advance":_vm.handleButtonClick}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }