import apiService from "@/services/apiService";

export default {
    data() {
        return {
            faqs: [],
            texto: '',
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
        };
    },
    methods: {
        async loadFAQs() {
            try {
                const currentLocale = this.$i18n.locale;
                const response = await apiService.getFAQs(currentLocale);
                this.faqs = response.data;
            } catch (error) {
                console.error("Error loading FAQs:", error);
            }
        },
        async sendEmailContact(texto) {
            await this.getUserData();

            const userContact = {
                nome: this.user.name,
                telefone: this.removeNonNumeric(this.user.phone_number),
                email: this.user.email,
                texto: texto,
            };

            try {
                const response = await apiService.sendContactEmail(this.token, userContact);
                if (response.data.status) {
                    this.$toast.success('Mensagem enviada com sucesso!');
                } else {
                    this.$toast.error('Erro ao enviar a mensagem:');
                }
            } catch (error) {
                this.$toast.error('Erro ao enviar a mensagem:', error);
                this.$toast.error('Erro ao enviar a mensagem. Tente novamente mais tarde.');
            }
        },
        removeNonNumeric(value) {
            return value?.replace(/\D/g, "");
        },
    }
};
