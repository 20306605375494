<template>
    <v-text-field
      v-model="email"
      :disabled="!editing"
      class="rounded-lg"
      label="Email"
      hide-details="auto"
      outlined
      dense
      :rules="emailRules"
    />
  </template>
  
  <script>
  export default {
    props: {
      value: String,
      editing: Boolean
    },
    computed: {
      email: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      },
      emailRules() {
        return [
          v => !!v || 'Campo obrigatório',
          v => /.+@.+\..+/.test(v) || 'E-mail inválido'
        ];
      }
    }
  };
  </script>
  