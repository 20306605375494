<template>
    <div>
        <layout>
            <template v-slot:content>
                <v-card>
                    <div class="d-md-flex" :style="{ height: '30vh', backgroundImage: 'url('+ require('@/assets/belatTransparencia.png')+')', 
                        backgroundPositionX: '40%', 
                        backgroundSize: 'cover'  }">
                    </div>
                </v-card>

                <div class="mt-lg-4 mx-4 mt-xl-12 px-xl-10">
                    <v-row>
                        <v-col class="px-lg-16 pt-lg-10" cols="12">
                            <h1 class="font-weight-regular secondary--text">
                                Transparencia
                            </h1>
                            <v-divider color="#14AA95" class="mb-4 mt-5"></v-divider>
                            <div style="display: flex; justify-content: space-between;">
                                <span class="primary--text">Relatorio Impacto 2016-2022</span>
                                <div>
                                    <v-icon class="mx-3" style="cursor: pointer">
                                        mdi-eye
                                    </v-icon>
                                    <v-icon style="cursor: pointer">
                                        mdi-tray-arrow-down
                                    </v-icon>
                                </div>
                            </div>
                            <v-divider color="#14AA95" class="mt-lg-4"></v-divider>
                        </v-col>
                    </v-row>
                </div>

            </template>
        </layout>
    </div>
</template>

<script>
import layout from "@/layouts/internalLayout.vue";
import userMixin from "@/mixins/userMixin";

export default {
    components: {
        layout,
    },
    mixins: [userMixin],
    data() {
        return {
            tabs: null,
            tabNames: ['Dados pessoais', 'Informações adicionais', 'Declarações'],
            text: '',
            userId: localStorage.getItem("userId"),
            currentScreenIndex: 0,
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            items: [
                {
                    title: "Meus Investimentos",
                    icon: "mdi-home-city",
                    route: "/user/MyInvestiments",
                },
                { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
            ],
            right: null,
        };
    },
    methods: {
        setCurrentScreen(index) {
            this.currentScreenIndex = index;
        },
    },
    mounted() {
        this.getUserData();
    },
    computed: {
        currentScreen() {
            return this.screens[this.currentScreenIndex];
        },
        totalScreens() {
            return this.screens.length;
        },
        isMobile() {
            return this.$vuetify.breakpoint.xsOnly;
        },
    },
};
</script>

<style scoped>
.v-navigation-drawer__border {
    display: none;
}

.rounded-card {
    border-radius: 20px;
    border: 1px solid #032a5a
}

.rounded-card-2 {
    border-radius: 20px;
    border: 1px solid #DF6E8B
}

.rounded-card-3 {
    border-radius: 20px;
    border: 1px solid #14aa95;
}

.opacityButton {
    background: rgba(255, 255, 255, 0.226);
}

.oi {
    border-bottom: 10px solid black;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

@media (min-width: 2560px) {
    .textHome {
        font-size: 24px;
    }
}

@media (min-width: 1920px) and (max-width: 2559px) {
    .textHome {
        font-size: 17px !important;
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .textHome {
        font-size: 10.5px;
    }
}

@media (max-width: 1279px) {
    .textHome {
        font-size: 12px;
    }
}
</style>
