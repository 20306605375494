<template>
  <div class="timeline-container">
    <div class="timeline">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="timeline-item"
        :class="{ 'is-active': index === activeIndex, 'is-completed': completedIndexes.includes(index) }"
        :style="{ left: `${index * (100 / (items.length - 1))}%` }"
        @click="setActiveIndex(index)"
      >
        <div class="timeline-item-circle">
          <v-icon color="white" v-if="completedIndexes.includes(index)">mdi-check</v-icon>
        </div>
        <div v-if="index < items.length - 1" class="timeline-line" :style="{ backgroundColor: completedIndexes.includes(index) ? '#14AA95' : '#ccc' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    completedIndexes: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    setActiveIndex(index) {
      this.$emit('update:activeIndex', index);
    }
  }
};
</script>

<style scoped>
.timeline-container {
  position: relative;
  width: 500px;
  height: 100px;
}

.timeline {
  position: absolute;
  top: 50%;
  left: 0;
  width: 50%;
  height: 2px;
  background-color: #ccc;
  transform: translateY(-50%);
}

.timeline-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.timeline-item-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 0 0 6px #ccc;
  cursor: pointer;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-item-circle .v-icon {
  font-size: 16px; /* Tamanho do ícone de check */
  color: #14AA95; /* Cor do ícone de check */
}

.timeline-item-content {
  position: absolute;
  top: 30px;
  left: -50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100px;
}

.timeline-item.is-active .timeline-item-circle {
  background-color: #ffffff;
  box-shadow: 0 0 0 6px #14AA95;
}

.timeline-line {
  position: absolute;
  width: 4vw;
  height: 2px;
  background-color: #14AA95; /* Cor da linha entre os círculos */
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}

.timeline-line-last {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #007bff; /* Cor da linha entre os círculos */
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.timeline-item.is-completed .timeline-item-circle {
  background-color: #14AA95;
  box-shadow: 0 0 0 6px #14AA95;
}
</style>
