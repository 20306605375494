<template>
    <div class="mt-10 mx-16 px-16">
        <v-row>
            <v-col cols="4">
                <v-card class="rounded-card" dark max-width="344" :height="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '250' : '180') : 'none'">
                    <v-card-title class="text-xl-h4 text-h5 font-weight-bold">Total Investido</v-card-title>
                    <v-card-text class="white--text text-xl-h3 text-lg-h4 pb-xl-10">{{ formatarValorMonetario(totalInvestido) }}</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="rounded-card-2" dark max-width="344" :height="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '250' : '180') : 'none'">
                    <v-card-title class="text-xl-h4 text-h5 font-weight-bold">Total a receber</v-card-title>
                    <v-card-text class="white--text text-xl-h3 text-lg-h4 pb-xl-10">{{ formatarValorMonetario(totalReceber) }}</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="rounded-card-3" max-width="344" :height="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '250' : '180') : 'none'">
                    <v-card-title class="text-xl-h5 text-lg-subtitle-1 d-flex font-weight-bold" style="color: #14aa95">
                        Rodada de investimentos <br> em aberto!
                    </v-card-title>
                    <v-img style="cursor:pointer" @click="$router.push('/business')" class="ml-6 d-flex justify-center align-center" :max-width="$vuetify.breakpoint.mdAndUp ? ($vuetify.breakpoint.xl ? '170' : '120') : 'none'" src="@/assets/layer2.png">
                        <div>
                            <span class="ml-xl-4 ml-lg-2 white--text text-xl-subtitle-2 text-lg-caption">Clique e confira</span>
                        </div>
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        totalInvestido: {
            type: Number,
            required: true
        },
        totalReceber: {
            type: Number,
            required: true
        }
    },
    methods: {
        formatarValorMonetario(valor) {
            // Verifica se o valor é válido antes de formatar
            if (valor !== undefined && valor !== null && !isNaN(valor)) {
                const formatter = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL', // Defina a moeda como BRL para reais brasileiros
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
                return formatter.format(valor);
            } else {
                return 'R$ 0,00';
            }
        }
    }
};
</script>

<style scoped>
.rounded-card {
    border-radius: 20px;
    background: #032a5a;
}

.rounded-card-2 {
    border-radius: 20px;
    background: #DF6E8B;
}

.rounded-card-3 {
    border-radius: 20px;
    border: 2px solid #14aa95;
}

.text-xl-h4,
.text-xl-h5,
.text-xl-h3,
.text-xl-body-1,
.white--text {
    font-family: 'Raleway', cursive !important;
}
</style>
