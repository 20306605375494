<template>
 <v-main class="primary" style="height: 100vh; overflow:hidden">
          <!-- <div class="d-flex"> -->
            <v-img
              class="ajuste"
              src="@/assets/gif_banca.gif"
            ></v-img>
          <!-- </div> -->

          <div>
            <div class="d-flex teste pt-16 px-5 align-start flex-column" style="position: relative;">
                <div style="z-index: 999;">
                    <v-img
                      class="mt-5"
                      max-width="200"
                      src="@/assets/banca_etica.png"
                    ></v-img>
                </div>
              <span class="white--text text-h4" style="z-index: 999;">Olá, Que bom <br/> ver você aqui.</span>
              <h4 style="z-index: 999;" class="white--text custom-letter-spacing mt-3 font-weight-light  mb-3">Aqui, na plataforma de investimento 
                da BELAT, você pode conectar seus investimentos com seus valores, e 
                gerar um impacto socioambiental positivo em qualquer operação de 
                sua escolha.</h4>
              <h4 style="z-index: 999;" class="white--text custom-letter-spacing mt-1 font-weight-light mb-3"><b class="font-weight-bold">Impacto é uma questão de escolha. <span style="color: #14AA95">Venha conosco e faça a sua. </span></b></h4>
              <v-col style="z-index: 999;" cols="12" class="mt-auto d-flex justify-center">
                  <v-btn @click="signUp" class="px-11 py-5" color="secondary" rounded>
                    <span class="font-weight-regular">Criar conta</span>
                </v-btn>
              </v-col>
              <v-col style="z-index: 999;" cols="12" class="d-flex justify-center mb-5">
                  <v-btn @click="signIn" class="px-8 py-5" color="primary" rounded style="border:1px solid #14aa95 !important">
                    <span class="font-weight-regular ">
                        Já sou cliente
                    </span>
                  </v-btn>
              </v-col>
            </div>
          </div>
      <footer class="footer">
          </footer>
      </v-main>
</template>

<script>
export default {
    methods: {
        signIn() {
            this.$router.push("/mobile/login");
        },
        signUp() {
          this.$router.push("/mobile/signUp");
        }
    },
}
</script>

<style scoped>

.ajuste {
  position: absolute; 
  z-index: 1;
    top: -30%;
    right: -20%; 
    bottom: 35%;
    width: 80%;
    height: auto;
    object-fit: contain;
  }

.footer {
  background-color: #DF6E8B; /* Cor de fundo do footer */
  color: white; /* Cor do texto do footer */
  padding: 8px; /* Espaçamento interno do footer */
  width: 100%;
  position: fixed; /* Fixa o footer na parte inferior da página */
  bottom: 0; /* Posiciona o footer no final da página */
  left: 0; /* Alinha o footer à esquerda */
  z-index: 9999;
}

.v-application .teste {
    background-color: #00265b !important;
}

</style>