import { render, staticRenderFns } from "./ConfigPageMobile.vue?vue&type=template&id=a92d0bac&scoped=true"
import script from "./ConfigPageMobile.vue?vue&type=script&lang=js"
export * from "./ConfigPageMobile.vue?vue&type=script&lang=js"
import style0 from "./ConfigPageMobile.vue?vue&type=style&index=0&id=a92d0bac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a92d0bac",
  null
  
)

export default component.exports