<template>
  <v-app>
    <v-app-bar fixed color="white" v-if="!drawer" max-height="70" style="z-index: 99;">
      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
      <!-- <v-img max-width="150" src="../assets/belat.png" @click="finish" style="cursor: pointer"></v-img> -->
      <v-spacer></v-spacer>
      <v-menu offset-y v-if="!navbar">
        <template v-slot:activator="{ on }">
          <div v-on="on" style="cursor: pointer" @click="$router.push('/mobile/MyInfo')">
            <!-- <span class="mr-2" style="color:#032A5A;font-size: 15px; font-weight: 600;">{{ user.name }}</span> -->
            <v-avatar>
              <v-img max-width="40" max-height="40" style="object-fit: contain;" src="../assets/icon/user.png"></v-img>
            </v-avatar>
          </div>
        </template>
      </v-menu>

    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary app
      style="height: 100vh; width: 100vw; background: #FFFFFF !important">
      <div style="display: flex; justify-content: end; align-items: center;">
        <v-btn icon class="close-button mt-1 mr-1" @click="closeScreen">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-list class="pa-3">
        <v-list-item class="mt-5 mb-5">
          <v-avatar>
            <v-img max-width="40" max-height="40" style="object-fit: contain;" src="../assets/icon/user.png"></v-img>
          </v-avatar>
          <span class="ml-2" style="color:#032A5A;font-size: 15px; font-weight: 600;">{{ user.name }}</span>
        </v-list-item>
        <v-list-item link :to="{ name: 'MobileProfile' }">

          <v-list-item-icon>
            <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.2548 19.021H2.82678V5.99902H3.75361V18.0941H18.328V5.99902H19.2548V19.021Z" fill="#032A5A" />
              <path
                d="M21.5488 8.59465L11.0524 1.15685L0.532927 8.59465L0 7.85319L11.0524 0.0214844L22.0817 7.85319L21.5488 8.59465Z"
                fill="#032A5A" />
              <path d="M9.91765 18.4188H8.99082V11.9542H6.3957V18.4188H5.46887V11.0273H9.91765V18.4188Z"
                fill="#032A5A" />
              <path
                d="M16.3362 14.6883H11.8875V11.0273H16.3362V14.6883ZM12.8143 13.7615H15.4094V11.9542H12.8143V13.7615Z"
                fill="#032A5A" />
            </svg>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text font-weight-medium">Home</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.94768 8.27055C7.12218 8.07521 7.22021 7.8103 7.22021 7.53409C7.22021 7.25788 7.12218 6.99297 6.94768 6.79763L1.68202 0.904923C1.59615 0.805433 1.49344 0.726076 1.37988 0.671484C1.26631 0.616891 1.14417 0.588155 1.02058 0.586953C0.896984 0.585752 0.774414 0.612108 0.660019 0.664484C0.545624 0.71686 0.441696 0.794207 0.354298 0.892011C0.266901 0.989816 0.197784 1.10612 0.150982 1.23414C0.104179 1.36215 0.0806279 1.49932 0.0817018 1.63763C0.0827761 1.77594 0.108454 1.91263 0.157238 2.03972C0.206021 2.16681 0.276933 2.28175 0.365836 2.37784L4.97341 7.53409L0.365836 12.6903C0.196279 12.8868 0.102457 13.1499 0.104578 13.423C0.106699 13.6962 0.204592 13.9574 0.377174 14.1506C0.549757 14.3437 0.783218 14.4533 1.02728 14.4556C1.27134 14.458 1.50646 14.353 1.68202 14.1633L6.94768 8.27055Z"
                fill="#032A5A" />
            </svg>

          </v-list-item-icon>
        </v-list-item>
        <hr>
        <v-list-item link :to="{ name: 'MobileBusiness' }">
          <v-list-item-icon>
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.0236 10.9279C12.5504 10.6927 11.9998 10.5189 11.3261 10.396C10.5295 10.2677 9.11543 9.91748 8.61288 8.98989C8.32685 8.46328 8.36428 7.77094 8.72515 6.93157C10.043 4.91334 12.9915 6.16705 13.5716 6.43971L14.026 5.47203C13.2722 5.1165 12.4034 4.84919 11.5346 4.77167V3.44846H10.4654V4.78236C9.41482 4.89998 8.44714 5.36778 7.80024 6.39427L7.76282 6.46377C7.24423 7.63728 7.21482 8.65842 7.67461 9.50046C8.3616 10.7622 9.94678 11.2567 11.147 11.4492C11.7084 11.5508 12.1655 11.6924 12.5371 11.8796C14.1677 12.7376 13.7346 13.839 13.5876 14.2106C13.2722 15.0606 12.7188 15.6166 11.9436 15.8599C10.8209 16.2127 9.33998 15.8599 7.97934 14.9189L7.36987 15.7984C8.3723 16.4934 9.45225 16.9104 10.468 17.0254V18.3994H11.5373V17.0307C11.7886 17.0013 12.0318 16.9532 12.2644 16.881C13.355 16.5362 14.1597 15.7423 14.5847 14.5928C15.1755 13.0958 14.6087 11.7619 13.0236 10.9279Z"
                fill="#032A5A" />
              <path
                d="M11 22.0215C4.93463 22.0215 0 17.0869 0 11.0215C0 4.95611 4.93463 0.0214844 11 0.0214844C17.0654 0.0214844 22 4.95611 22 11.0215C22 17.0869 17.0654 22.0215 11 22.0215ZM11 1.09074C5.52272 1.09074 1.06926 5.54688 1.06926 11.0215C1.06926 16.4961 5.52272 20.9522 11 20.9522C16.4773 20.9522 20.9307 16.4988 20.9307 11.0215C20.9307 5.54421 16.4746 1.09074 11 1.09074Z"
                fill="#032A5A" />
            </svg>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text font-weight-medium">Investir</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.94768 8.27055C7.12218 8.07521 7.22021 7.8103 7.22021 7.53409C7.22021 7.25788 7.12218 6.99297 6.94768 6.79763L1.68202 0.904923C1.59615 0.805433 1.49344 0.726076 1.37988 0.671484C1.26631 0.616891 1.14417 0.588155 1.02058 0.586953C0.896984 0.585752 0.774414 0.612108 0.660019 0.664484C0.545624 0.71686 0.441696 0.794207 0.354298 0.892011C0.266901 0.989816 0.197784 1.10612 0.150982 1.23414C0.104179 1.36215 0.0806279 1.49932 0.0817018 1.63763C0.0827761 1.77594 0.108454 1.91263 0.157238 2.03972C0.206021 2.16681 0.276933 2.28175 0.365836 2.37784L4.97341 7.53409L0.365836 12.6903C0.196279 12.8868 0.102457 13.1499 0.104578 13.423C0.106699 13.6962 0.204592 13.9574 0.377174 14.1506C0.549757 14.3437 0.783218 14.4533 1.02728 14.4556C1.27134 14.458 1.50646 14.353 1.68202 14.1633L6.94768 8.27055Z"
                fill="#032A5A" />
            </svg>

          </v-list-item-icon>
        </v-list-item>
        <hr>
        <v-list-item link :to="{ name: 'MobileMyInvestiments' }">
          <v-list-item-icon>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.9864 9.36668C5.3297 9.36668 4.62248 9.1141 3.96578 8.65946L4.54671 7.82596C5.20341 8.2806 5.91063 8.4574 6.41579 8.28059C6.74414 8.17956 6.99671 7.92698 7.14826 7.54811C7.22404 7.37131 7.35032 6.96718 6.69362 6.61357C6.51682 6.51254 6.2895 6.43677 6.06218 6.41151C5.5065 6.33574 4.52145 6.08316 4.06681 5.27491C3.78897 4.76975 3.81423 4.16357 4.11732 3.48161L4.14258 3.40584C4.95083 2.11769 6.56733 2.31975 7.67868 2.85016L7.24929 3.75944C7.07249 3.68366 5.63279 3.00171 5.0266 3.91099C4.87506 4.2646 4.8498 4.56769 4.97609 4.76975C5.20341 5.19913 5.9864 5.35069 6.21372 5.37594C6.56733 5.42646 6.89568 5.52749 7.19878 5.70429C8.10806 6.20945 8.46167 6.99244 8.13332 7.87646C7.88074 8.55842 7.40084 9.01307 6.76939 9.21514C6.49156 9.34143 6.23898 9.36668 5.9864 9.36668Z"
                fill="#032A5A" />
              <path d="M6.49115 2.04199H5.48083V3.00179H6.49115V2.04199Z" fill="#032A5A" />
              <path d="M6.49115 8.91211H5.48083V9.8719H6.49115V8.91211Z" fill="#032A5A" />
              <path
                d="M5.9861 11.9937C2.67733 11.9937 0 9.31637 0 6.00759C0 2.69882 2.67733 0.0214844 5.9861 0.0214844C9.29488 0.0214844 11.9722 2.69882 11.9722 6.00759C11.9722 9.31637 9.26962 11.9937 5.9861 11.9937ZM5.9861 1.0318C3.25826 1.0318 1.01031 3.25449 1.01031 6.00759C1.01031 8.76069 3.233 10.9834 5.9861 10.9834C8.73921 10.9834 10.9619 8.76069 10.9619 6.00759C10.9619 3.25449 8.71395 1.0318 5.9861 1.0318Z"
                fill="#032A5A" />
              <path
                d="M8.38598 21.5911H3.48596V15.5039H8.41124V21.5911H8.38598ZM4.49627 20.5807H7.40092V16.5142H4.49627V20.5807Z"
                fill="#032A5A" />
              <path d="M14.0942 21.5914H9.16895V12.2207H14.0942V21.5914ZM10.1793 20.581H13.0839V13.231H10.1793V20.581Z"
                fill="#032A5A" />
              <path d="M20.8631 20.6064H2.42493V21.6168H20.8631V20.6064Z" fill="#032A5A" />
              <path d="M1.08657 20.6064H0.27832V21.6168H1.08657V20.6064Z" fill="#032A5A" />
              <path
                d="M19.8029 21.5911H14.8776V7.59823H12.7054L17.3529 2.31934L22.0003 7.59823H19.8281V21.5911H19.8029ZM15.8879 20.5808H18.7926V6.58791H19.7524L17.3276 3.86007L14.9281 6.61317H15.8879V20.5808Z"
                fill="#032A5A" />
            </svg>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text font-weight-medium">Meus Investimentos</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.94768 8.27055C7.12218 8.07521 7.22021 7.8103 7.22021 7.53409C7.22021 7.25788 7.12218 6.99297 6.94768 6.79763L1.68202 0.904923C1.59615 0.805433 1.49344 0.726076 1.37988 0.671484C1.26631 0.616891 1.14417 0.588155 1.02058 0.586953C0.896984 0.585752 0.774414 0.612108 0.660019 0.664484C0.545624 0.71686 0.441696 0.794207 0.354298 0.892011C0.266901 0.989816 0.197784 1.10612 0.150982 1.23414C0.104179 1.36215 0.0806279 1.49932 0.0817018 1.63763C0.0827761 1.77594 0.108454 1.91263 0.157238 2.03972C0.206021 2.16681 0.276933 2.28175 0.365836 2.37784L4.97341 7.53409L0.365836 12.6903C0.196279 12.8868 0.102457 13.1499 0.104578 13.423C0.106699 13.6962 0.204592 13.9574 0.377174 14.1506C0.549757 14.3437 0.783218 14.4533 1.02728 14.4556C1.27134 14.458 1.50646 14.353 1.68202 14.1633L6.94768 8.27055Z"
                fill="#032A5A" />
            </svg>

          </v-list-item-icon>
        </v-list-item>
        <hr>
        <v-list-item link :to="{ name: 'MobileContact' }">
          <v-list-item-icon>
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.9281 21.6711L9.67665 15.4196H0V0.617188H20V15.4196H15.9281V21.6711ZM0.958084 14.4615H10.0838L14.9701 19.3717V14.4854H19.0419V1.59922H0.958084V14.4615Z"
                fill="#1A2D58" />
              <path d="M14.5387 4.44922H5.46082V5.4073H14.5387V4.44922Z" fill="#1A2D58" />
              <path d="M14.5387 7.49121H5.46082V8.44929H14.5387V7.49121Z" fill="#1A2D58" />
              <path d="M14.5387 10.5332H5.46082V11.4913H14.5387V10.5332Z" fill="#1A2D58" />
            </svg>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text font-weight-medium">Fale conosco</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.94768 8.27055C7.12218 8.07521 7.22021 7.8103 7.22021 7.53409C7.22021 7.25788 7.12218 6.99297 6.94768 6.79763L1.68202 0.904923C1.59615 0.805433 1.49344 0.726076 1.37988 0.671484C1.26631 0.616891 1.14417 0.588155 1.02058 0.586953C0.896984 0.585752 0.774414 0.612108 0.660019 0.664484C0.545624 0.71686 0.441696 0.794207 0.354298 0.892011C0.266901 0.989816 0.197784 1.10612 0.150982 1.23414C0.104179 1.36215 0.0806279 1.49932 0.0817018 1.63763C0.0827761 1.77594 0.108454 1.91263 0.157238 2.03972C0.206021 2.16681 0.276933 2.28175 0.365836 2.37784L4.97341 7.53409L0.365836 12.6903C0.196279 12.8868 0.102457 13.1499 0.104578 13.423C0.106699 13.6962 0.204592 13.9574 0.377174 14.1506C0.549757 14.3437 0.783218 14.4533 1.02728 14.4556C1.27134 14.458 1.50646 14.353 1.68202 14.1633L6.94768 8.27055Z"
                fill="#032A5A" />
            </svg>

          </v-list-item-icon>
        </v-list-item>
        <hr>
        <v-list-item link :to="{ name: 'ConfigMobile' }">
          <v-list-item-icon>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.93241 16.5579C6.66619 16.5579 4.02209 13.9139 4.02209 10.6476C4.02209 7.38139 6.66619 4.7373 9.93241 4.7373C13.1986 4.7373 15.8427 7.38139 15.8427 10.6476C15.8427 13.9139 13.1986 16.5579 9.93241 16.5579ZM9.93241 5.62607C7.15501 5.62607 4.91086 7.87022 4.91086 10.6476C4.91086 13.425 7.15501 15.6692 9.93241 15.6692C12.7098 15.6692 14.954 13.425 14.954 10.6476C14.954 7.87022 12.7098 5.62607 9.93241 5.62607Z"
                fill="#1A2D58" />
              <path
                d="M12.4428 20.5793H7.44345L7.24347 18.9796C6.79909 18.8462 6.37692 18.6685 5.95476 18.4463L4.68826 19.4239L1.1554 15.9133L2.13305 14.6468C1.93307 14.2246 1.75532 13.8025 1.59979 13.3581L0 13.1359V8.13656L1.59979 7.93659C1.7331 7.49221 1.91086 7.07004 2.13305 6.64787L1.1554 5.35916L4.68826 1.8263L5.95476 2.80394C6.37692 2.60397 6.79909 2.42622 7.24347 2.27068L7.44345 0.670898H12.4428L12.665 2.27068C13.1094 2.404 13.5315 2.58175 13.9537 2.80394L15.2424 1.8263L18.7753 5.35916L17.7976 6.64787C17.9976 7.07004 18.1753 7.49221 18.3309 7.91437L19.9307 8.11435V13.1359L18.3309 13.3359C18.1976 13.7803 18.0198 14.2024 17.7976 14.6246L18.7753 15.9133L15.2424 19.4462L13.9537 18.4685C13.5315 18.6685 13.1094 18.8462 12.665 19.0018L12.4428 20.5793ZM8.22112 19.6906H11.6651L11.8429 18.2908L12.1317 18.2019C12.6872 18.0464 13.2427 17.8019 13.7759 17.5353L14.0203 17.402L15.1535 18.2685L17.5754 15.8466L16.7089 14.7135L16.8422 14.469C17.131 13.958 17.3532 13.4025 17.5088 12.847L17.5976 12.5582L18.9975 12.3804V8.93646L17.5976 8.7587L17.5088 8.46985C17.3532 7.91437 17.131 7.35889 16.8422 6.84785L16.7089 6.60344L17.5754 5.47025L15.1313 3.02614L14.0203 3.89269L13.7759 3.75937C13.2649 3.47052 12.7094 3.24833 12.1317 3.09279L11.8429 3.00392L11.6651 1.58189H8.22112L8.04337 2.9817L7.75452 3.07058C7.19904 3.22611 6.64355 3.47052 6.11029 3.73716L5.86588 3.87047L4.75492 3.00392L2.33302 5.44804L3.19957 6.559L3.06626 6.80341C2.77741 7.31445 2.55521 7.86993 2.39968 8.42541L2.3108 8.71426L0.910989 8.89202V12.3138L2.3108 12.4915L2.39968 12.7804C2.55521 13.3581 2.79963 13.8913 3.06626 14.4246L3.19957 14.669L2.33302 15.78L4.75492 18.2019L5.86588 17.3353L6.13251 17.4686C6.64355 17.7575 7.19904 17.9797 7.77674 18.1352L8.06559 18.2241L8.22112 19.6906Z"
                fill="#1A2D58" />
            </svg>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text font-weight-medium">Configurações</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.94768 8.27055C7.12218 8.07521 7.22021 7.8103 7.22021 7.53409C7.22021 7.25788 7.12218 6.99297 6.94768 6.79763L1.68202 0.904923C1.59615 0.805433 1.49344 0.726076 1.37988 0.671484C1.26631 0.616891 1.14417 0.588155 1.02058 0.586953C0.896984 0.585752 0.774414 0.612108 0.660019 0.664484C0.545624 0.71686 0.441696 0.794207 0.354298 0.892011C0.266901 0.989816 0.197784 1.10612 0.150982 1.23414C0.104179 1.36215 0.0806279 1.49932 0.0817018 1.63763C0.0827761 1.77594 0.108454 1.91263 0.157238 2.03972C0.206021 2.16681 0.276933 2.28175 0.365836 2.37784L4.97341 7.53409L0.365836 12.6903C0.196279 12.8868 0.102457 13.1499 0.104578 13.423C0.106699 13.6962 0.204592 13.9574 0.377174 14.1506C0.549757 14.3437 0.783218 14.4533 1.02728 14.4556C1.27134 14.458 1.50646 14.353 1.68202 14.1633L6.94768 8.27055Z"
                fill="#032A5A" />
            </svg>
          </v-list-item-icon>
        </v-list-item>
        <hr>
          <v-list-item @click="logout" link class="mt-5">
            <v-list-item-icon>
              <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.7776 10.7998H6.27515V11.7506H18.7776V10.7998Z" fill="#1A2D58" />
                <path
                  d="M16.0681 15.1731L15.3788 14.4838L18.6113 11.275L15.3788 8.04249L16.0681 7.37695L19.9662 11.275L16.0681 15.1731Z"
                  fill="#1A2D58" />
                <path
                  d="M9.95915 21.9711H2.82849C1.25975 21.9711 0 20.7113 0 19.1426V3.4076C0 1.83886 1.25975 0.579102 2.82849 0.579102H9.95915C11.5279 0.579102 12.7876 1.83886 12.7876 3.4076V6.40247H11.8369V3.4076C11.8369 2.36177 11.005 1.52986 9.95915 1.52986H2.82849C1.78266 1.52986 0.950754 2.36177 0.950754 3.4076V19.1426C0.950754 20.1884 1.78266 21.0203 2.82849 21.0203H9.95915C11.005 21.0203 11.8369 20.1884 11.8369 19.1426V16.219H12.7876V19.1426C12.7876 20.6876 11.5041 21.9711 9.95915 21.9711Z"
                  fill="#1A2D58" />
              </svg>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title class="primary--text font-weight-medium">Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-bottom-navigation app v-if="!navbar & !drawer">
      <v-btn :to="{ name: 'MobileProfile' }" exact>
        <span style="font-size: 10px;font-weight: 600;" class="primary--text mt-1">Home</span>
        <svg width="35" height="31" viewBox="0 0 35 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30.5193 30.1152H4.48047V9.4751H5.94951V28.6461H29.0502V9.4751H30.5193V30.1152Z" fill="#032A5A" />
          <path d="M34.1553 13.5887L17.5184 1.79958L0.844701 13.5887L0 12.4134L17.5184 0L35 12.4134L34.1553 13.5887Z"
            fill="#032A5A" />
          <path d="M15.7187 29.16H14.2496V18.9134H10.1363V29.16H8.66724V17.4443H15.7187V29.16Z" fill="#032A5A" />
          <path d="M25.892 23.2471H18.8406V17.4443H25.892V23.2471ZM20.3096 21.778H24.4229V18.9134H20.3096V21.778Z"
            fill="#032A5A" />
        </svg>
      </v-btn>
      <v-btn :to="{ name: 'MobileMyInvestiments' }">
        <span style="font-size: 10px;font-weight: 600;" class="primary--text mt-1">Investimentos</span>
        <svg width="31" height="33" viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_4244_2740)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.53483 17.9207C3.93818 17.9207 0.21875 14.2013 0.21875 9.60466C0.21875 5.00801 3.93818 1.28857 8.53483 1.28857C13.1315 1.28857 16.8509 5.00801 16.8509 9.60466C16.8509 14.2013 13.0964 17.9207 8.53483 17.9207ZM8.53483 2.69213C4.74522 2.69213 1.62231 5.77996 1.62231 9.60466C1.62231 13.4294 4.71013 16.5172 8.53483 16.5172C12.3595 16.5172 15.4474 13.4294 15.4474 9.60466C15.4474 5.77996 12.3244 2.69213 8.53483 2.69213ZM9.23651 4.09506H7.83295V4.71383C7.10588 4.85686 6.44175 5.24313 5.97317 5.98993L5.93808 6.09519C5.51702 7.04259 5.48193 7.88472 5.86791 8.5865C6.49951 9.70934 7.86797 10.0602 8.63993 10.1655C8.95573 10.2006 9.27153 10.3059 9.51715 10.4462C10.4295 10.9375 10.254 11.4989 10.1488 11.7445C9.93822 12.2708 9.58733 12.6217 9.13118 12.7621C8.4294 13.0077 7.44691 12.7621 6.5346 12.1305L5.72755 13.2884C6.40967 13.7607 7.13103 14.076 7.83295 14.205V14.973H9.23651V14.1956C9.36197 14.1642 9.49013 14.1205 9.62242 14.0604C10.4996 13.7797 11.1663 13.1481 11.5172 12.2007C11.9734 10.9725 11.4821 9.88479 10.2189 9.18301C9.79787 8.93739 9.34171 8.79704 8.85046 8.72686C8.53466 8.69177 7.44691 8.48123 7.13111 7.88472C6.95566 7.60401 6.99075 7.18295 7.20128 6.69171C8.01 5.47863 9.88659 6.30419 10.2518 6.46485L10.2891 6.48116L10.8856 5.21797C10.3884 4.98065 9.81837 4.79065 9.23651 4.70188V4.09506ZM5.06092 29.8855V22.7979H11.9033V29.8855H12.956V18.2371H19.7984V29.8855H20.8862V11.8155H17.8685L24.3249 4.48193L30.7812 11.8155H27.7636V29.8855H29.2022V31.2891H3.58724V29.8855H5.06092ZM10.4997 29.8508H6.46448V24.2014H10.4997V29.8508ZM14.3596 29.8516H18.3948V19.6407H14.3596V29.8516ZM1.72746 29.8855H0.604614V31.2891H1.72746V29.8855ZM26.3249 29.8513H22.2897V10.4471H20.9563L24.2898 6.62237L27.6583 10.412H26.3249V29.8513Z"
              fill="#032A5A" />
          </g>
          <defs>
            <clipPath id="clip0_4244_2740">
              <rect width="30.5625" height="32.0005" fill="white" transform="translate(0.21875 0.288574)" />
            </clipPath>
          </defs>
        </svg>
      </v-btn>
      <v-btn :to="{ name: 'MobileBusiness' }">
        <span style="font-size: 10px;font-weight: 600;" class="primary--text mt-1">Investir</span>
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.2594 15.0453C17.6142 14.7245 16.8633 14.4876 15.9447 14.3199C14.8584 14.1449 12.9301 13.6674 12.2448 12.4025C11.8548 11.6844 11.9058 10.7403 12.3979 9.5957C14.195 6.84357 18.2157 8.55317 19.0067 8.92498L19.6264 7.60542C18.5984 7.1206 17.4137 6.75608 16.229 6.65037V4.846H14.771V6.66495C13.3384 6.82534 12.0188 7.46325 11.1367 8.86301L11.0857 8.95778C10.3785 10.558 10.3384 11.9505 10.9654 13.0987C11.9022 14.8193 14.0638 15.4936 15.7005 15.7561C16.466 15.8946 17.0893 16.0878 17.596 16.343C19.8196 17.5131 19.229 19.0149 19.0286 19.5216C18.5984 20.6808 17.8439 21.439 16.7868 21.7707C15.2558 22.2518 13.2363 21.7707 11.3809 20.4876L10.5498 21.6868C11.9168 22.6346 13.3894 23.2032 14.7746 23.36V25.2336H16.2327V23.3673C16.5753 23.3272 16.907 23.2616 17.2242 23.1631C18.7114 22.6929 19.8086 21.6103 20.3882 20.0428C21.1938 18.0015 20.421 16.1826 18.2594 15.0453Z"
            fill="#032A5A" />
          <path
            d="M15.5 30.1729C7.22904 30.1729 0.5 23.4438 0.5 15.1729C0.5 6.90189 7.22904 0.172852 15.5 0.172852C23.771 0.172852 30.5 6.90189 30.5 15.1729C30.5 23.4438 23.771 30.1729 15.5 30.1729ZM15.5 1.63093C8.03098 1.63093 1.95808 7.70748 1.95808 15.1729C1.95808 22.6382 8.03098 28.7148 15.5 28.7148C22.969 28.7148 29.0419 22.6419 29.0419 15.1729C29.0419 7.70384 22.9654 1.63093 15.5 1.63093Z"
            fill="#032A5A" />
        </svg>
      </v-btn>
      <v-btn :to="{ name: 'MobileContact' }">
        <span style="font-size: 10px;font-weight: 600;" class="primary--text mt-1">Fale conosco</span>
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.5197 28.645L14.0626 20.188H0.971924V0.163086H28.0282V20.188H22.5197V28.645ZM2.26803 18.8918H14.6135L21.2236 25.5344V18.9242H26.7321V1.4916H2.26803V18.8918Z"
            fill="#1A2D58" />
          <path d="M20.6405 5.34766H8.35986V6.64376H20.6405V5.34766Z" fill="#1A2D58" />
          <path d="M20.6405 9.46289H8.35986V10.759H20.6405V9.46289Z" fill="#1A2D58" />
          <path d="M20.6405 13.5776H8.35986V14.8737H20.6405V13.5776Z" fill="#1A2D58" />
        </svg>
      </v-btn>
      <!-- <v-btn>
        <span style="font-size: 10px;font-weight: 600;" class="primary--text mt-1">Sair</span>
        <svg class="ml-2" width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M28.8322 15.5605H12.7222V16.7856H28.8322V15.5605Z" fill="#1A2D58" />
          <path
            d="M25.3406 21.1962L24.4524 20.308L28.6177 16.1733L24.4524 12.008L25.3406 11.1504L30.3635 16.1733L25.3406 21.1962Z"
            fill="#1A2D58" />
          <path
            d="M17.4694 29.9554H8.28114C6.25973 29.9554 4.63647 28.3321 4.63647 26.3107V6.0353C4.63647 4.01389 6.25973 2.39062 8.28114 2.39062H17.4694C19.4908 2.39062 21.1141 4.01389 21.1141 6.0353V9.89436H19.889V6.0353C19.889 4.68769 18.817 3.61572 17.4694 3.61572H8.28114C6.93354 3.61572 5.86157 4.68769 5.86157 6.0353V26.3107C5.86157 27.6583 6.93354 28.7303 8.28114 28.7303H17.4694C18.817 28.7303 19.889 27.6583 19.889 26.3107V22.5435H21.1141V26.3107C21.1141 28.3015 19.4602 29.9554 17.4694 29.9554Z"
            fill="#1A2D58" />
        </svg>
      </v-btn> -->
      <!-- <v-btn :to="{ name: 'ContactUs' }">
        <span>Fale Conosco</span>
        <v-icon>mdi-phone</v-icon>
      </v-btn> -->
      <!-- <v-btn :to="{ name: 'config' }">
        <span>Configurações</span>
        <v-icon>mdi-cog</v-icon>
      </v-btn> -->
    </v-bottom-navigation>
    <v-main class="white"
      style="margin-top: 58px; max-width: 1380px; padding: 0;max-height: 90vh;overflow-y: auto;overflow-x: hidden;">
      <slot name="content"></slot>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import userMixin from "@/mixins/userMixin";

export default {
  name: "internalLayout",
  props: {
    clickBusi: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [userMixin],
  data() {
    return {
      navbar: false,
      tabs: null,
      infoUserData: false,
      drawerData: false,
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      userId: localStorage.getItem("userId"),
      token: localStorage.getItem("token"),
      drawer: false,
      group: null,
      teste: false,
    };
  },
  methods: {
    closeUserData() {
      this.infoUserData = false;
    },
    showUserData() {
      this.$router.replace('user/MyInfo');
    },
    finish() {
      this.$router.push({
        name: "HomePage",
      });
    },
    closeScreen() {
      this.drawer = false
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    logout() {
      localStorage.clear();
      this.$router.replace("/mobile/home");
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>

<style scoped>
.v-input__slot {
  padding: 0;
}

.v-slide-group__content,
.v-tabs-bar__content {
  display: flex !important;
  justify-content: center !important;
}

/* .tile:hover {
    color: #14aa95
  } */

.v-list-item {
  margin-bottom: 0px;
}

.list-item {
  cursor: pointer;
}

.v-list-item--active svg path {
  fill: #14AA95
}

.adjustInternal:hover svg path {
  fill: #14AA95;
  /* Substitua 'yourNewColor' pela cor desejada */
}

.adjustInternal:hover .tile {
  color: #14AA95;
}
.footer-menu {
  padding: 20px; /* Espaçamento interno do footer */
  width: 100%;
  position: fixed; /* Fixa o footer na parte inferior da página */
  bottom: 0; /* Posiciona o footer no final da página */
  left: 0; /* Alinha o footer à esquerda */
  z-index: 9999;
}
</style>

<style></style>