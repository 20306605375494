<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-btn
        :class="{ 'on-hover': hover }"
        class="transition-fast-in-fast-out mr-2"
        :color=" editing ? 'white' : 'button'"
        @click="toggleEdit"
        :dark="!editing"
        rounded
      >
        {{ editing ? 'Cancelar' : 'Editar' }}
      </v-btn>
    </v-hover>
    <v-hover v-slot="{ hover }" v-if="editing">
      <v-btn
        :class="{ 'on-hover': hover }"
        class="transition-fast-in-fast-out"
        color="primary"
        @click="updateTitular"
        rounded
      >
        <span>{{$t('confirm')}}</span>
      </v-btn>
    </v-hover>
  </div>
</template>
  
  <script>
  export default {
    props: {
      editing: Boolean
    },
    methods: {
      toggleEdit() {
        this.$emit('toggleEdit');
      },
      updateTitular(){
        this.$emit('updateTitular');
      }
    }
  };
  </script>
  