<template>
  <v-row class="d-flex justify-center">
    <v-col cols="8" class="d-flex justify-center">
      <span class="text-left blue-grey--text text--darken-1">
        <p class="mb-2 primary--text">
          Digite sua nova senha. Lembre-se de que ela deve ter:
        </p>
        <ul>
          <li class="text-left primary--text">Um mínimo de 8 caracteres</li>
          <li class="text-left primary--text">Incluir pelo menos um caractere especial</li>
          <li class="text-left primary--text">Conter uma letra maiúscula</li>
        </ul>
      </span>
    </v-col>

    <v-col cols="7">
      <v-text-field
        v-on:keyup.enter="submit"
        :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="toggleShowPass('showPass1')"
        :type="showPass1 ? 'text' : 'password'"
        class="rounded-lg w-80"
        label="Digite sua senha atual"
        :rules="rulesCurrent"
        hide-details="auto"
        name="currentPassword"
        v-model="currentPassword"
        outlined
        dense
      />
    </v-col>

    <v-col cols="7">
      <v-text-field
        v-on:keyup.enter="submit"
        :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="toggleShowPass('showPass2')"
        :type="showPass2 ? 'text' : 'password'"
        class="rounded-lg"
        label="Digite sua nova senha"
        :rules="rules"
        hide-details="auto"
        name="password"
        v-model="password"
        outlined
        dense
      />
    </v-col>

    <v-col cols="7">
      <v-text-field
        v-on:keyup.enter="submit"
        :append-icon="showPass3 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="toggleShowPass('showPass3')"
        :type="showPass3 ? 'text' : 'password'"
        class="rounded-lg"
        label="Digite novamente sua senha"
        :rules="rulesConfirm"
        hide-details="auto"
        name="confirmPassword"
        v-model="confirmPassword"
        outlined
        dense
      />
    </v-col>

    <v-col cols="7" class="d-flex justify-center">
      <v-hover v-slot="{ hover }">
        <v-btn
          rounded
          block
          :class="{ 'on-hover': hover }"
          class="pa-6 px-16 transition-fast-in-fast-out"
          :color="hover ? 'secondary' : 'primary'"
          @click="submit"
        >
          <span>Atualização</span>
        </v-btn>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: Object, 
    rules: Array,
    rulesCurrent: Array,
    rulesConfirm: Array,
  },
  data() {
    return {
      showPass1: false,
      showPass2: false,
      showPass3: false,
    };
  },
  computed: {
    currentPassword: {
      get() {
        return this.value.currentPassword; 
      },
      set(value) {
        this.$emit('update:value', { ...this.value, currentPassword: value }); 
      }
    },
    password: {
      get() {
        return this.value.password;
      },
      set(value) {
        this.$emit('update:value', { ...this.value, password: value });
      }
    },
    confirmPassword: {
      get() {
        return this.value.confirmPassword;
      },
      set(value) {
        this.$emit('update:value', { ...this.value, confirmPassword: value });
      }
    }
  },
  methods: {
    toggleShowPass(field) {
      this[field] = !this[field];
    },
    submit() {
      this.$emit('submit');
    }
  }
};
</script>
