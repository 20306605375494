
import apiService from "@/services/apiService";

export default {
    methods: {
        async getInvestidor(){
            try {
                const investidor = await apiService.getInvestidor(this.token);
                this.titularUser = investidor.data.data.investidor;
            } catch (error) {
                console.error(error);
            }
        },
        async getInvestorInfo() {
            try {
                const response = await apiService.getInvestidor(this.token);
                this.total_investido = response.data.data.total_investido;
                this.total_receber = response.data.data.total_receber;
            } catch (error) {
                console.error(error);
            }
        }
    },
};