<template>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon small color="primary" v-on="on" class="ml-1 mt-0">mdi-information-outline</v-icon>
      </template>
      <span>{{ content }}</span>
    </v-tooltip>
  </template>
  
  <script>
  export default {
    props: {
      content: String,
    },
  };
  </script>
  