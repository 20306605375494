<template>
    <div>
      <layout>
        <template v-slot:content>
          <div class="mt-lg-4 mx-4 mt-xl-12 px-xl-10">
            <v-row class="d-flex justify-center items-center">
              <v-card class="pa-8 mt-16" style="width: 800px;">
                <v-col class="px-lg-16 pt-lg-10" cols="12">
                  <v-row class="text-center">
                    <v-col cols="12">     
                      <p class="text-start ml-16 pl-5 text-h4 primary--text">
                        Digite a nova senha
                      </p>
                    </v-col>
                  </v-row>
                  <PasswordUpdateForm
                    v-model="formData"
                    :rules="rules"
                    :rules-current="rulesCurrent"
                    :rules-confirm="rulesConfirm"
                    @submit="submit"
                  />
                </v-col>
              </v-card>
            </v-row>
          </div>
        </template>
      </layout>
    </div>
  </template>
  
  <script>
  import layout from "@/layouts/internalLayout.vue";
  import PasswordUpdateForm from "./Components/PasswordUpdateForm.vue";
  import axios from "axios";
  
  export default {
    components: {
      layout,
      PasswordUpdateForm,
    },
    data() {
      return {
        errorMessageMap: {
          password: "O campo senha é obrigatorio",
          confirmPassword: "O campo confirmação de senha é obrigatório"
        },
        token: localStorage.getItem("token"),
        urlEnv: process.env.VUE_APP_SERVICE_URL,
        formData: { 
          currentPassword: '',
          password: '',
          confirmPassword: ''
        },
        showPass1: false,
        showPass2: false,
        showPass3: false,
        rules: [
          (v) => !!v || "Campo obrigatório",
          (v) => (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
          (v) => (v && /[A-Z]/.test(v)) || "A senha deve conter pelo menos uma letra maiúscula",
          (v) => (v && /[a-z]/.test(v)) || "A senha deve conter pelo menos uma letra minúscula",
          (v) => (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
        ],
        rulesCurrent: [
          (v) => !!v || "Campo obrigatório",
        ],
        rulesConfirm: [
          (v) => !!v || "Campo obrigatório",
          (v) => v === this.formData.password || "As senhas não coincidem"
        ]
      };
    },
    methods: {
      submit() {
        const config = {
          headers: { Authorization: `Bearer ${this.token}` },
        };
        const fields = ["password", "confirmPassword"];
        let hasError = false;
        for (const field of fields) {
          if (!this.formData[field]) {
            this.$toast.error(this.errorMessageMap[field]);
            hasError = true;
            break;
          }
        }
        if(hasError) return;
  
        const passwordValid = this.rules.every(rule => rule(this.formData.password) === true);
        const confirmPasswordValid = this.rulesConfirm.every(rule => rule(this.formData.confirmPassword) === true);
        const currentPasswordValid = this.rulesCurrent.every(rule => rule(this.formData.currentPassword) === true);
  
        if (!passwordValid || !confirmPasswordValid || !currentPasswordValid) {
          this.$toast.error("Por favor, corrija os erros nos campos de senha.");
          return;
        }
  
        axios
          .post(this.urlEnv + 'updatePassword', {
            current_password: this.formData.currentPassword,
            password: this.formData.password,
            password_confirmation: this.formData.confirmPassword
          }, config)
          .then((response) => {
            this.$toast.success(response.data.mensagem);
          })
          .catch((err) => {
            if (err.response && err.response.data && err.response.data.mensagem) {
              this.$toast.error(err.response.data.mensagem);
            } else {
              console.error(err);
              this.$toast.error("Erro ao alterar senha");
            }
          });
      },
    },
  };
  </script>
  