<template>
  <v-col style="padding: 2px" cols="12">
    <h2 style="font-size: 20px;" class="primary--text font-weight-medium">{{ question.text }}</h2>
    <v-select 
      :id="id" 
      multiple 
      color="secondary" 
      v-model="localAnswer"
      :items="options.map(option => ({ text: option.text, value: option.value }))"
      outlined 
      dense 
      required
    >
      <template v-slot:prepend-item>
        <v-list-item ripple @click="selectAllQuestions">
          <v-list-item-content>
            <v-list-item-title>
              Selecionar todos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:selection="{ item, index }">
        <span v-if="index < 1">
          <span>{{ item.text }} </span>
        </span>
        <span v-if="index === 1" class="text-grey text-caption align-self-center">
          (+{{ localAnswer.length - 1 }} outros)
        </span>
      </template>
    </v-select>
  </v-col>
</template>

<script>
export default {
  props: {
    id: String,
    question: Object,
    options: Array,
    value: Array,
  },
  data() {
    return {
      localAnswer: this.value ? [...this.value] : [],
    };
  },
  watch: {
    value(newValue) {
      this.localAnswer = newValue;
    },
    localAnswer(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    selectAllQuestions() {
      const allOptions = this.options.length ? this.options.map(option => option.value) : [];
      this.localAnswer = this.localAnswer.length === allOptions.length ? [] : allOptions;
    },
  },
};
</script>
